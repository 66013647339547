import PropTypes from 'prop-types'

import { Icon } from '@aider/ui'
import { Tag } from './icons/Tag' // We might ditch the @aider/ui dependency
import { PaperClipIcon } from './icons/PaperClip'

const StatusCell = ({ verified, verifications, hasAttachment, ...props }) => {
  /**
   * Goes through all the given verifications
   * and checks if at least a tag is present.
   *
   * @returns {bool} true if the verifications contain a tag, false otherwise
   */
  function hasTag() {
    let foundTag = null
    verifications.forEach(verification => {
      // We only need to find one tag
      if (foundTag === null) {
        foundTag = verification.tag
      }
    })

    return foundTag
  }

  return (
    <span {...props} className="flex items-center gap-8">
      {verified ? (
        <div className="flex items-center space-x-2 text-sm font-medium text-green-500">
          <Icon className="w-4 h-4 fill-current" name="checkbox-circle" />
          <span>Bokförd</span>
        </div>
      ) : (
        <div className="flex items-center space-x-2 text-sm font-medium text-gray-600">
          <Icon className="w-4 h-4 fill-current" name="question-circle" />
          <span>Ej bokförd</span>
        </div>
      )}
      <div className="flex items-center gap-2 text-gray-600">
        {hasTag() ? (
          <Tag className="w-5 h-5 transform rotate-90" />
        ) : (
          // Keep the icons vertically aligned
          <span className="w-5 h-5" />
        )}
        {hasAttachment && <PaperClipIcon className="w-5 h-5" />}
      </div>
    </span>
  )
}

StatusCell.propTypes = {
  verified: PropTypes.bool.isRequired,
  hasAttachment: PropTypes.bool.isRequired,
}

export default StatusCell
