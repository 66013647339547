import { convertFromRaw } from 'draft-js'
import { convertToHTML } from 'draft-convert'

/**
 * Convert a JSON string to a ContentState record
 * @see https://draftjs.org/docs/api-reference-content-state
 *
 * @param  string str JSON string in the shape of a ContentState
 * @return object     A ContentState record
 */
const _stringToContentState = str => {
  try {
    const rawState = JSON.parse(str)
    const contentState = convertFromRaw(rawState)
    return contentState
  } catch {
    return null
  }
}

/**
 * Convert a JSON string to a plain text
 * @param  string str JSON string in the shape of a ContentState
 * @return string     The JSON string as plain text
 */
export const jsonToText = str => {
  const contentState = _stringToContentState(str)

  if (!contentState) return ''

  return contentState.getPlainText()
}

/**
 * Get the first block of a message as text
 * @param  string str JSON string in the shape of a ContentState
 * @return string     The first block as a string
 */
export const getFirstBlockAsText = str => {
  const contentState = _stringToContentState(str)

  if (!contentState) return ''

  return contentState.getFirstBlock(contentState).getText()
}

/**
 * Get a message as HTML
 * @param  string str JSON string in the shape of a ContentState
 * @return string     The entire message as HTML
 */
export const getMessageAsHtml = str => {
  const contentState = _stringToContentState(str)

  if (!contentState) return ''

  // TODO: Sanitize html
  return convertToHTML(contentState)
}

/**
 * Replaces all spaces with dashes, and all letters with lowercase
 * Ex: 'This is a test' => 'this-is-a-test'
 * @param {str} str The string to transform
 */
export function slugify (str) {
    return str
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
}
