import { useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { gql, useQuery, useMutation, useReactiveVar } from '@apollo/client'
import { useModal, Select } from '@aider/ui'
import { toast } from 'react-hot-toast'

import { LoadingContainer } from '@components/'
import { activePrincipalIdVar } from '@/cache'

import ImportWithTink from './import-with-tink'

const BANKS = gql`
  query banks {
    banks {
      id
      name
      tinkId
      csvId
      tinkCompatible
      csvCompatible
      test
    }
  }
`

const CREATE_IMPORT = gql`
  mutation createImport(
    $principalId: ID!
    $importMethod: ImportMethod!
    $csvId: String!
    $file: Upload!
  ) {
    createImport(
      input: {
        principalId: $principalId
        importMethod: $importMethod
        csvId: $csvId
        file: $file
      }
    ) {
      id
      status
      importMethod
    }
  }
`

const ImportTransactions = () => {
  const history = useHistory()
  const principalId = useReactiveVar(activePrincipalIdVar)
  const { openModal, closeModal, isOpen, Modal } = useModal()
  const [selectedBank, setSelectedBank] = useState()

  const { data: { banks = [] } = {}, loading: loadingBanks } = useQuery(BANKS, {
    onError: useCallback(() => {
      toast.error('Kunde inte hämta banker')
    }, []),
  })

  // Prepare select options
  const bankOptions = useMemo(
    () =>
      banks.map(
        ({ id, csvId, tinkId, name, tinkCompatible, csvCompatible, test }) => ({
          value: id,
          label: name,
          tinkId,
          csvId,
          test,
          tinkCompatible,
          csvCompatible,
        }),
      ),
    [banks],
  )

  const [createImport, { loading: creatingImport }] = useMutation(
    CREATE_IMPORT,
    {
      onCompleted: useCallback(
        ({ createImport: { id: createdImportId } = {} }) => {
          history.push(
            `/huvudman/redovisning/transaktioner/importera/${createdImportId}`,
          )
        },
        [history],
      ),
      onError: useCallback(() => {
        toast.error('Kunde inte importera transaktioner')
      }, []),
    },
  )

  const handleUploadCsv = async ({ target }) => {
    await createImport({
      variables: {
        principalId,
        importMethod: 'CSV',
        csvId: selectedBank?.csvId,
        file: target.files[0],
      },
    })

    // Reset file input after upload
    // eslint-disable-next-line no-param-reassign
    target.value = null
  }

  const handleImportSuccess = importId => {
    closeModal()
    history.push(`/huvudman/redovisning/transaktioner/importera/${importId}`)
  }

  // TODO: Add loading state  to LoadingContainer and handle errors
  return (
    <LoadingContainer loading={loadingBanks}>
      <div className="mx-auto pt-16 w-8/12">
        <div className="p-6 border border-gray-300 rounded-xl">
          <div className="mb-8">
            <Select
              id="bank"
              name="bank"
              label="Välj bank"
              placeholder="Välj bank"
              options={bankOptions}
              onChange={({ currentTarget }) => {
                setSelectedBank(currentTarget.value)
              }}
            />
          </div>
          <div>
            <span className="block mb-2 text-black text-sm font-medium">
              Importalternativ
            </span>
            {selectedBank ? (
              <div className="flex items-center py-3 h-36 border border-gray-300 rounded-lg shadow-sm">
                <div className="flex flex-1 flex-col px-6 py-2 border-r border-gray-300">
                  <span className="font-medium mb-1">
                    Ladda upp fil med transaktioner
                  </span>
                  <span className="mb-2 text-gray-700">
                    Ladda upp fil med transaktioner från banken.{' '}
                    <a
                      className="font-medium focus:outline-none hover:opacity-75 cursor-pointer transition-opacity text-blue-600"
                      href="https://intercom.help/aider/sv/articles/5633822-hur-far-jag-in-transaktioner-i-aider"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Läs mer
                    </a>
                  </span>
                  {selectedBank?.csvCompatible ? (
                    <label
                      className={`font-medium focus:outline-none hover:opacity-75 cursor-pointer transition-opacity ${
                        creatingImport ? 'text-gray-700' : 'text-blue-600'
                      }`}
                    >
                      {creatingImport ? 'Laddar upp fil..' : 'Välj fil'}
                      <input
                        type="file"
                        accept=".csv,.xls,.xlsx"
                        onChange={handleUploadCsv}
                        disabled={creatingImport}
                        className="hidden"
                      />
                    </label>
                  ) : (
                    <span className="text-gray-700 font-medium">
                      Ej tillgängligt
                    </span>
                  )}
                </div>
                <div className="flex flex-1 flex-col px-6 py-3">
                  <span className="font-medium mb-1">Använd Tink</span>
                  <span className="mb-2 text-gray-700">
                    Hämta transaktioner direkt från din bank med BankID.
                  </span>
                  {selectedBank?.tinkCompatible ? (
                    <button
                      className="inline-flex text-blue-600 font-medium focus:outline-none hover:opacity-75 cursor-pointer transition-opacity"
                      type="button"
                      onClick={openModal}
                    >
                      Öppna Tink
                    </button>
                  ) : (
                    <span className="text-gray-700 font-medium">
                      Ej tillgängligt
                    </span>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center py-3 h-36 text-center bg-gray-200 border border-dashed border-gray-400 rounded-lg">
                <span className="font-medium">Välj bank</span>
                <span className="text-gray-800">
                  Välj en bank i listan för att se importalternativ
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal visible={isOpen}>
        <ImportWithTink
          bankProvider={selectedBank?.tinkId}
          isTestBankProvider={selectedBank?.test}
          onSuccess={handleImportSuccess}
          onCancel={closeModal}
          onError={() => {
            closeModal()
            toast.error('Kunde inte importera transaktioner')
          }}
        />
      </Modal>
    </LoadingContainer>
  )
}

export default ImportTransactions
