const FormSection = ({ title, description, link, id, children }) => (
  <div className="mb-12 pb-8 border-b border-gray-300">
    <header className="flex flex-col mb-4">
      <span className="text-lg font-medium" id={id}>
        {title}
      </span>
      <span
        className="text-gray-700"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {link && (
        <span className="text-gray-700">
          Vill du ha mer information,{' '}
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 font-medium focus:outline-none hover:opacity-75 cursor-pointer transition-opacity duration-200 ease-in-out"
          >
            se här
          </a>
        </span>
      )}
    </header>
    <div>{children}</div>
  </div>
);

export default FormSection;
