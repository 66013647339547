import PropTypes from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import { Loader } from '@aider/ui'

const LoadingContainer = ({ loading, children }) => (
  <motion.div
    key="initial"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
    className="flex flex-1 w-full max-h-full"
  >
    <AnimatePresence exitBeforeEnter>
      {loading ? (
        <motion.div
          key="loading"
          className="absolute left-0 top-0 flex items-center justify-center w-full h-full pointer-events-none"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.2 }}
        >
          <Loader />
        </motion.div>
      ) : (
        <motion.div
          key="content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="flex flex-1 flex-col max-h-full w-full"
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
)

LoadingContainer.defaultProps = {
  loading: false,
}

LoadingContainer.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default LoadingContainer
