import { useQuery, gql, useApolloClient } from '@apollo/client'
import PropTypes from 'prop-types'
import { Button, Checkbox, Icon } from '@aider/ui'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-hot-toast'

import { goToStripeCustomerPortal } from '@utils/stripe'

const validationSchema = Yup.object().shape({
  confirmation: Yup.bool().oneOf([true], 'Godkänn för att fortsätta.'),
})

const GET_USER = gql`
  query getUser {
    me {
      id
      sub {
        cancelled
      }
    }
  }
`

const DELETE_ACCOUNT = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(id: $userId) {
      id
      name
    }
  }
`

const DeleteAccount = ({ onCancel }) => {
  const client = useApolloClient()

  const { data: { me: { id: userId, sub } = {} } = {} } = useQuery(GET_USER)

  const { values, handleChange, handleSubmit, isSubmitting, isValid } =
    useFormik({
      validateOnMount: true,
      validationSchema,
      validateOnChange: true,
      validateOnBlur: true,
      initialValues: { confirmation: false },
      onSubmit: async ({ confirmation }) => {
        if (!confirmation || (sub && !sub.cancelled)) return false

        try {
          await client.mutate({
            mutation: DELETE_ACCOUNT,
            variables: { userId },
          })
          // Reload page, otherwise the users get stuck at the
          window.location.reload()
        } catch (error) {
          toast.error('Vi kunde inte ta bort ditt konto')
        }

        return true
      },
    })

  return (
    <div className="p-6 bg-white rounded-lg" style={{ width: 480 }}>
      <header className="mb-4 text-black text-lg font-medium">
        Vill du radera ditt konto?
      </header>
      <form onSubmit={handleSubmit}>
        <div>
          <span className="block mb-4">
            Vi kommer inte att kunna återställa ditt konto. Det betyder att dina
            transaktioner, dagboksinlägg och kopplade huvudmän kommer att
            försvinna permanent.
          </span>
          {!sub || sub.cancelled ? (
            <div className="flex items-center space-x-2">
              <Checkbox
                id="confirmation"
                checked={values.confirmation}
                onCheckedChange={handleChange}
                name="confirmation"
              />
              <label className="text-base font-medium" htmlFor="confirmation">
                Jag förstår
              </label>
            </div>
          ) : (
            <div className="p-4 bg-gray-300 rounded-lg">
              <div className="mb-2">
                Innan du tar bort ditt konto behöver du avsluta din
                prenumeration.
              </div>
              <button
                type="button"
                onClick={() => goToStripeCustomerPortal()}
                className="flex items-center text-black font-medium focus:outline-none space-x-1"
              >
                <span>Hantera prenumeration</span>
                <Icon
                  name="arrow-top-right"
                  className="w-3 h-3 text-black fill-current"
                />
              </button>
            </div>
          )}
        </div>
        <div className="flex justify-between pt-6">
          <Button
            variant="secondary"
            type="button"
            title="Avbryt"
            onClick={onCancel}
          />
          <Button
            title="Radera konto"
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting || !isValid}
            variant="destructive"
          />
        </div>
      </form>
    </div>
  )
}

DeleteAccount.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

export default DeleteAccount
