import { useState, useCallback, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useMutation, useQuery, gql } from '@apollo/client'
import { toast } from 'react-hot-toast'
import { Button, useModal, Icon } from '@aider/ui'
import { Virtuoso } from 'react-virtuoso'
import { LoadingContainer } from '@components/'

import TicketMessage from './TicketMessage'
import CreateTicketMessage from './CreateTicketMessage'

const TICKET_MESSAGE_FRAGMENT = gql`
  fragment ticketMessage on TicketMessage {
    id
    message
    createdAt
    user {
      id
      name
    }
  }
`

const TICKET = gql`
  query ticket($ticketId: ID!, $after: String) {
    ticket(id: $ticketId) {
      id
      title
      open
      organization {
        id
        name
      }
      messages(first: 10, after: $after, orderBy: { column: ID, order: DESC }) {
        pageInfo {
          endCursor
          hasNextPage
          total
        }
        edges {
          node {
            ...ticketMessage
          }
        }
      }
    }
  }
  ${TICKET_MESSAGE_FRAGMENT}
`

const READ_TICKET = gql`
  mutation readTicket($ticketId: ID!) {
    readTicket(id: $ticketId) {
      id
      updatedAt
    }
  }
`

const Ticket = () => {
  const virtuosoRef = useRef(null)
  const { ticketId } = useParams()
  const { openModal, closeModal, isOpen, Modal } = useModal()
  const [initalItemCount, setInitialItemCount] = useState(10)
  const [prependedMessages, setPrependedMessages] = useState([])
  // This can be just a very high number, has to be higher than the total number of ticketMessages for this ticket
  const START_INDEX = 100000
  const [firstItemIndex, setFirstItemIndex] = useState(START_INDEX)

  const [readTicket] = useMutation(READ_TICKET, {
    refetchQueries: ['userDetails'],
    onError: useCallback(() => {
      toast.error('Kunde inte uppdatera tråden')
    }, []),
    update(cache, { data: { readTicket: updatedTicket } = {} }) {
      cache.modify({
        id: cache.identify(updatedTicket),
        fields: {
          updatedAt() {
            return updatedTicket.updatedAt
          },
        },
      })
    },
  })

  const {
    data: {
      ticket: {
        title,
        organization,
        open,
        messages: { pageInfo: { endCursor, hasNextPage, total } = {} } = {},
      } = {},
    } = {},
    fetchMore,
    loading: loadingTicket,
  } = useQuery(TICKET, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { ticketId },
    onCompleted: useCallback(
      ({ ticket: { messages: { edges: messages = [] } = {} } = {} }) => {
        if (prependedMessages.count > 0) {
          setInitialItemCount(prependedMessages.length)
        }
        const test = [...messages]
        test.reverse()
        setPrependedMessages([...test, ...prependedMessages])
        readTicket({ variables: { ticketId } })
      },
      [],
    ),
  })

  const prependItems = useCallback(async () => {
    if (hasNextPage) {
      // This should be set to the same as the first: 10 in the query ticket above.
      let messagesToPrepend = 10
      const itemsLeftToPrepend = total - prependedMessages.length
      if (itemsLeftToPrepend < 10) {
        // This has to be detemined on the total - prependedMessages.length. Otherwise we say that we fetch 10 more but maybe there is just 4 left in the database...
        // We will not get 10 from the db...
        messagesToPrepend = itemsLeftToPrepend
      }

      const nextFirstItemIndex = firstItemIndex - messagesToPrepend
      await fetchMore({ variables: { after: endCursor } })
      setFirstItemIndex(() => nextFirstItemIndex)
    }

    return false
  }, [firstItemIndex, prependedMessages, fetchMore])

  if (loadingTicket || prependedMessages.length < 1) {
    return null
  }

  return (
    <LoadingContainer loading={loadingTicket}>
      <div className="mx-auto pb-20 pt-16 w-full lg:w-10/12 xl:w-8/12 h-5/6 h-screen">
        <header className="sticky z-10 top-0 flex items-center justify-between mb-6 py-4 bg-white border-b border-gray-300 space-x-6">
          <div className="flex items-center space-x-3 truncate">
            <Link
              to="/meddelanden"
              className="text-blue-500 text-2xl font-medium hover:opacity-50 transition-opacity"
            >
              ...
            </Link>
            <Icon
              name="chevron-right"
              className="flex-shrink-0 w-2.5 h-2.5 text-black fill-current"
            />
            <div className="text-2xl font-medium truncate">{title}</div>
          </div>
          <Button title="Svara" onClick={openModal} disabled={!open} />
        </header>
        <Virtuoso
          initialTopMostItemIndex={initalItemCount - 1}
          firstItemIndex={firstItemIndex}
          startReached={prependItems}
          ref={virtuosoRef}
          style={{ height: '96%' }}
          data={prependedMessages}
          itemContent={(
            index,
            { node: { id, message, createdAt, user } = {} },
          ) => {
            const isSender = typeof user === 'object' && user !== null
            const createdBy = isSender ? user?.name : organization?.name
            return (
              <div className="pb-4 pr-4">
                <TicketMessage
                  message={message}
                  createdAt={createdAt}
                  createdBy={createdBy}
                  isSender={isSender}
                />
              </div>
            )
          }}
        />
      </div>
      {/* Modals */}
      <Modal visible={isOpen}>
        <CreateTicketMessage
          ticketId={ticketId}
          onCancel={closeModal}
          onSuccess={closeModal}
        />
      </Modal>
    </LoadingContainer>
  )
}

export default Ticket
