const ReportIdentityHeader = props => {
  function role() {
    return props.aiderRole === 'trustee'
      ? 'God man/Förvaltare/Förmyndare'
      : 'Huvudman'
  }

  return (
    <div className="flex flex-col flex-1">
      <span className="text-xs font-medium tracking-wide text-gray-500">
        {role()}
      </span>
      <span className="text-sm font-medium">{props.name}</span>
      <div className="flex flex-col text-xs font-medium text-gray-600">
        {props.pin && <span>{props.pin}</span>}
        <span className="inline-block">
          {props.address && <span>{props.address},</span>} {props.zipcode && <span>{props.zipcode}</span>} {props.city && <span>{props.city}</span>}
        </span>
        {props.email && <span>{props.email}</span>}     
      </div>
    </div>
  )
}

export default ReportIdentityHeader
