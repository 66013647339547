import { createContext, useContext, useState } from 'react'

export const events = {
  CONTENT_SCROLL_BOTTOM: 'CONTENT_SCROLL_BOTTOM',
}

const createEventEmitter = () => {
  const evts = {}

  return {
    emit(event, ...args) {
      ;(evts[event] || []).forEach(listener => listener(...args))
    },
    on: (event, listener) => {
      ;(evts[event] = evts[event] || []).push(listener)

      return () => {
        evts[event] = evts[event].filter(cb => cb !== listener)
      }
    },
  }
}

export const EventEmitterContext = createContext(createEventEmitter())
export const useEventEmitter = () => useContext(EventEmitterContext)
export const useEventEmitterInstance = () => useState(createEventEmitter())[0]
