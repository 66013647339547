import { sv as locale } from 'date-fns/locale'
import {
  format as fnsFormat,
  formatDistanceToNow as fnsFormatDistanceToNow,
  isValid,
  parseISO,
  endOfYear,
  endOfMonth,
  startOfYear,
  startOfMonth,
} from 'date-fns'

/**
 * Returns a date in distance from now format
 * @see https://date-fns.org/v2.22.1/docs/formatDistanceToNow
 * @param   {String} A date string
 * @returns {String} A relative date string
 */
export const formatDistanceToNow = str => {
  const date = parseISO(str)
  return isValid(date)
    ? fnsFormatDistanceToNow(date, { locale, addSuffix: true })
    : 'Ogiltigt datum'
}

/**
 * Converts a string to the supplied date format
 * @param  {String} str        A valid date string
 * @param  {String} dateFormat A date-format, see date-fns docs
 * @param  {Object} options    Options, see date-fns docs
 * @return {Date|String}       A date if a valid date string is supplied, else 'Ogiltigt datum'
 */
export const format = (str, dateFormat = 'yyyy-MM-dd', options = {}) => {
  const date = parseISO(str)
  return isValid(date)
    ? fnsFormat(date, dateFormat, { ...options, locale })
    : 'Ogiltigt datum'
}

/**
 * Converts a date to a valid date range of the entire year
 * @param  {Date}   date A date object
 * @return {Object}      A valid date range object, i.e { from: '2021-01-01', to: '2021-12-31' }
 */
export const dateToYearRange = (date = new Date()) => {
  if (!isValid(date)) return undefined

  const from = fnsFormat(startOfYear(date), 'yyyy-MM-dd')
  const to = fnsFormat(endOfYear(date), 'yyyy-MM-dd')

  return { from, to }
}

/**
 * Converts a date to a valid date range of the entire month
 * @param {Date} date A date object
 * @return {Object} A valid date range object, i.e { from: '2021-01-01', to: '2021-01-31' }
 */
export const dateToMonthRange = (date = new Date()) => {
  if (!isValid(date)) return undefined

  const from = fnsFormat(startOfMonth(date), 'yyyy-MM-dd')
  const to = fnsFormat(endOfMonth(date), 'yyyy-MM-dd')

  return { from, to }
}

/**
 * Converts a given number of minutes into a number of hours/minutes
 * and formats it in a String. The number of seconds for now is faked to 00.
 * @param {Int} minutes The number of minutes to convert
 * @returns A formated String HH:MM:00
 */
export const formatMinutes = minutes => {
  if (minutes < 0) return undefined

  let hours = Math.trunc(minutes / 60)
  hours = hours < 10 ? `0${hours}` : hours
  let _minutes = minutes % 60
  _minutes = _minutes < 10 ? `0${_minutes}` : _minutes
  return `${hours}:${_minutes}`
}
