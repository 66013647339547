import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { DateCell } from '@components/'

const TicketListItem = ({
  title,
  latestMessage,
  latestSender,
  organizationName,
  updatedAt,
  read,
  to,
}) => (
  <Link
    className="flex items-center justify-between py-5 w-full border-b border-gray-300 focus:outline-none hover:opacity-75 space-x-6 transition-opacity"
    to={to}
  >
    <div className="truncate">
      <div className="flex items-center space-x-2">
        {!read && (
          <div className="flex-shrink-0 w-1.5 h-1.5 bg-blue-500 rounded-full" />
        )}
        <div className="text-lg font-medium">{title}</div>
      </div>
      <div className="flex space-x-1 truncate">
        <div className="text-black font-medium">{latestSender}:</div>
        <div className="text-gray-800 truncate">{latestMessage}</div>
      </div>
    </div>
    <div className="flex flex-col flex-shrink-0 items-end">
      <div className="text-black text-sm font-medium">{organizationName}</div>
      <DateCell
        className="text-gray-800 text-sm"
        type="distance"
        date={updatedAt}
      />
    </div>
  </Link>
)

TicketListItem.propTypes = {
  title: PropTypes.string.isRequired,
  latestMessage: PropTypes.string.isRequired,
  latestSender: PropTypes.string.isRequired,
  organizationName: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  read: PropTypes.bool,
}

TicketListItem.defaultProps = {
  read: false,
}

export default TicketListItem
