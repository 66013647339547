import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery, gql } from '@apollo/client'

import { LoadingContainer } from '@components/'
import { track, events } from '@utils/analytics'

const BANK_ACCOUNTS = gql`
  query bankAccounts($importId: ID!) {
    import(id: $importId) {
      id
      status
      tinkAccounts {
        id
        balance
        accountNumber
        name
      }
    }
  }
`

const UPDATE_IMPORT = gql`
  mutation updateImport($importId: ID!, $tinkBankAccountId: String!) {
    updateImport(
      input: { id: $importId, tinkBankAccountId: $tinkBankAccountId }
    ) {
      id
    }
  }
`

const SelectBankAccount = ({ importId, onSuccess, onError }) => {
  const {
    data: { import: { tinkAccounts = [] } = {} } = {},
    loading: loadingBankAccounts,
  } = useQuery(BANK_ACCOUNTS, {
    variables: { importId },
    fetchPolicy: 'no-cache',
    onError: useCallback(
      error => {
        onError(error)
      },
      [onError],
    ),
  })

  const [updateImport, { loading: updatingImport }] = useMutation(
    UPDATE_IMPORT,
    {
      onCompleted: useCallback(() => {
        track(events.USER_SELECTED_BANK_PROVIDER)
        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      }, [onSuccess]),
      onError: useCallback(
        error => {
          onError(error)
        },
        [onError],
      ),
    },
  )

  return (
    <LoadingContainer loading={updatingImport || loadingBankAccounts}>
      <div className="flex flex-1 flex-col justify-between overflow-y-auto">
        <header className="sticky top-0 p-6 text-black text-lg font-medium bg-white">
          Välj konto
        </header>
        {Array.isArray(tinkAccounts) && tinkAccounts.length && (
          <ul className="flex flex-1 flex-col">
            {tinkAccounts.map(({ id, name, accountNumber, account }) => (
              <li key={id}>
                <BankAccountButton
                  title={name}
                  disabled={!!account || updatingImport}
                  onClick={() =>
                    updateImport({
                      variables: { importId, tinkBankAccountId: id },
                    })
                  }
                  description={
                    !account
                      ? accountNumber
                      : `Detta konto används redan av ${account.principal.name}.`
                  }
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </LoadingContainer>
  )
}

const BankAccountButton = ({ title, description, onClick, disabled }) => (
  <button
    type="button"
    className="px-6 w-full text-left hover:bg-gray-200 focus:outline-none transition-colors"
    onClick={onClick}
    disabled={disabled}
  >
    <div className="py-4 w-full border-b border-gray-300">
      <div className="text-black font-medium">{title}</div>
      <div className="text-gray-800 text-sm">{description}</div>
    </div>
  </button>
)

SelectBankAccount.propTypes = {
  importId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default SelectBankAccount
