import { useCallback } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'

import { LoadingContainer, EmptyState } from '@components'
import EmptyTransactions from '@assets/images/empty_transactions.png'

const EXPORT_FILE_URL = gql`
  query exportFileUrl($exportId: ID!) {
    export(id: $exportId) {
      id
      url
      report {
        name
      }
    }
  }
`

const DownloadReport = () => {
  const { exportId } = useParams()

  /**
   * Get export
   */
  const {
    data: { export: { url, report: { name } = {} } = {} } = {},
    loading: loadingExport,
  } = useQuery(EXPORT_FILE_URL, {
    variables: { exportId },
    skip: !exportId,

    onError: useCallback(() => {
      toast.error('Kunde inte hämta rapporten')
    }, []),
  })

  return (
    <LoadingContainer loading={loadingExport}>
      <div className="flex flex-1 items-center justify-center">
        <EmptyState
          icon={
            <img
              className="mb-2 w-16 h-auto"
              src={EmptyTransactions}
              alt="transaktioner"
            />
          }
          title={name}
          description="Klicka på länken nedan för att hämta din rapport. Rapporten öppnas i en ny flik."
          action={
            <a
              className="text-blue-600 font-medium"
              target="_blank"
              rel="noreferrer"
              href={url}
            >
              Ladda ned
            </a>
          }
        />
      </div>
    </LoadingContainer>
  )
}

export default DownloadReport
