import { useEffect, useState, useCallback } from 'react'
import { useLazyQuery, useReactiveVar, gql } from '@apollo/client'

import { activePrincipalIdVar } from '@/cache'

const PRINCIPAL_BY_ID = gql`
  query principalById($principalId: ID!) {
    principal(id: $principalId) {
      id
      name
    }
  }
`

const PRINCIPALS = gql`
  query principals {
    me {
      id
      principals {
        id
        name
      }
    }
  }
`

/**
 * Check if user as selected a principal and set the first one if not.
 * @param  {Function} options.callback Callback to run if hook can't set a principal automatically
 * @return {Object}
 */
const useActivePrincipal = ({ callback }) => {
  const activePrincipalId = useReactiveVar(activePrincipalIdVar)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const [getPrincipals] = useLazyQuery(PRINCIPALS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: useCallback(
      ({ me: { principals = [] } = {} }) => {
        if (Array.isArray(principals) && principals.length) {
          activePrincipalIdVar(principals[0].id)
        } else if (typeof callback === 'function') {
          setLoading(false)
          callback()
        }
      },
      [callback],
    ),
    onError: useCallback(e => {
      setError(e)
      setLoading(false)
    }, []),
  })

  const [
    getPrincipalById,
    { data: { principal: activePrincipal } = {} },
  ] = useLazyQuery(PRINCIPAL_BY_ID, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: useCallback(() => {
      setLoading(false)
    }, []),
    onError: useCallback(
      e => {
        getPrincipals()
        setError(e)
        setLoading(false)
      },
      [getPrincipals],
    ),
  })

  useEffect(() => {
    setLoading(true)
    if (!activePrincipalId) {
      getPrincipals()
    } else {
      localStorage.setItem('activePrincipalId', activePrincipalId)
      getPrincipalById({ variables: { principalId: activePrincipalId } })
    }
  }, [activePrincipalId, getPrincipalById, getPrincipals])

  return {
    activePrincipal,
    loading,
    error,
  }
}

export default useActivePrincipal
