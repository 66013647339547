import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import 'yup-personnummer'
import Personnummer from 'personnummer'
import { useQuery, useMutation, gql } from '@apollo/client'
import to from 'await-to-fetch'
import uniqWith from 'lodash.uniqwith'
import isEqual from 'lodash.isequal'
import { toast } from 'react-hot-toast'
import { Button, Input, Icon } from '@aider/ui'

import { Preview, useFindPerson, useOnPremiumOrTrial } from '@hooks/'
import { track, events } from '@utils/analytics'
import { activePrincipalIdVar } from '@/cache'

const validationSchema = Yup.object().shape({
  pin: Yup.string()
    .required('Obligatoriskt fält.')
    .personnummer('Fyll i ett giltigt personnummer'),
  skipRoaring: Yup.boolean(),
  name: Yup.string().when('skipRoaring', {
    is: true,
    then: Yup.string().required('För- och efternamn måste anges'),
  }),
  organization: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .nullable()
    .when('skipRoaring', {
      is: true,
      then: Yup.object()
        .shape({
          value: Yup.string().required('Välj en kommun i listan'),
          label: Yup.string().required(),
        })
        .required('För- och efternamn måste anges'),
    }),
})

const USER_ID = gql`
  query userId {
    me {
      id
    }
  }
`

const CREATE_PRINCIPAL = gql`
  mutation createPrincipal($pin: String!, $name: String, $organizationId: ID) {
    upsertPrincipal(
      input: { pin: $pin, name: $name, organizationId: $organizationId }
    ) {
      id
      name
      decryptedPin
    }
  }
`

const CreatePrincipal = ({ onSuccess, onCancel }) => {
  const { loading, error, data, findPerson, organizationOptions } =
    useFindPerson()
  const { onPremium } = useOnPremiumOrTrial()
  const [invalidPin, setInvalidPin] = useState(true)

  const { data: { me: user } = {} } = useQuery(USER_ID)

  const [createPrincipal] = useMutation(CREATE_PRINCIPAL, {
    update(cache, { data: { upsertPrincipal } = {} } = {}) {
      cache.modify({
        id: cache.identify(user),
        fields: {
          principals: (existing = []) => {
            const principalRef = cache.writeFragment({
              data: upsertPrincipal,
              fragment: gql`
                fragment NewPrincipal on Principal {
                  id
                  name
                  decryptedPin
                }
              `,
            })
            // Since we're upserting principals it's possible to upsert the same principal multiple times.
            // Make sure the cache only saves unique principals
            return uniqWith([...existing, principalRef], isEqual)
          },
        },
      })
    },
    onCompleted: useCallback(
      ({ upsertPrincipal: { id } = {} }) => {
        activePrincipalIdVar(id)
        if (typeof onSuccess === 'function') {
          onSuccess()
        }
        track(events.USER_CREATED_PRINCIPAL)
      },
      [onSuccess],
    ),
    onError: useCallback(() => {
      toast.error('Kunde inte lägga till huvudman.')
    }, []),
  })

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    isValid,
  } = useFormik({
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      pin: '',
      name: '',
      organization: null,
      skipRoaring: false,
    },
    onSubmit: async ({ pin, name, organization }) => {
      createPrincipal({
        variables: {
          pin: Personnummer.parse(pin).format('long'),
          ...(name !== '' && { name }),
          ...(organization !== null && { organizationId: organization.value }),
        },
      })
    },
  })

  // Call the findPerson function on PIN-input change
  useEffect(() => {
    ;(async () => {
      const [pinError] = await to(
        validationSchema.validate({ pin: values.pin }),
      )
      if (pinError) return setInvalidPin(true)
      setInvalidPin(false)
      return findPerson(values.pin)
    })()
  }, [findPerson, values.pin])

  return (
    <div className="p-6 bg-white rounded-lg" style={{ width: 480 }}>
      <header className="mb-6 text-black text-lg font-medium">
        Lägg till huvudman
      </header>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <Input
            name="pin"
            id="pin"
            label="Personnummer"
            placeholder="ÅÅMMDD-XXXX"
            type="text"
            maxLength={13}
            value={values.pin}
            error={touched.pin && errors.pin}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
        </div>

        <Preview
          invalidPin={invalidPin}
          error={error}
          loading={loading}
          data={data}
          organizationOptions={organizationOptions}
          values={values}
          handleChange={handleChange}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />

        {onPremium && (
          <div className="mt-4 p-4 bg-blue-500 rounded-lg">
            <div className="mb-1 text-white text-sm font-medium opacity-75">
              Information
            </div>
            <div className="mb-2 text-white text-base font-medium">
              Kostnaden för din prenumeration uppdateras automatiskt under året
              när du lägger till eller tar bort huvudmän.
            </div>
            <a
              className="flex items-center text-white text-base font-medium space-x-1"
              target="_blank"
              rel="noopener noreferrer"
              href="https://intercom.help/aider/sv/articles/4909873-hur-hanterar-jag-huvudman-i-aider#h_4dc1b9c717"
            >
              <span>Läs mer</span>
              <Icon name="arrow-top-right" className="w-3 h-3 fill-current" />
            </a>
          </div>
        )}
        <div className="flex justify-between pt-6">
          <Button
            variant="secondary"
            type="button"
            title="Avbryt"
            onClick={() => typeof onCancel === 'function' && onCancel()}
          />
          <Button
            title="Lägg till huvudman"
            type="submit"
            isLoading={isSubmitting}
            disabled={
              isSubmitting || !isValid || (!data && !values.skipRoaring)
            }
            variant="primary"
          />
        </div>
      </form>
    </div>
  )
}

CreatePrincipal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CreatePrincipal
