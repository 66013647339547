import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { Dropdown } from '@aider/ui'

import { FilterButton } from '@components'
import AmountFilter from './AmountFilter'
import VerifiedFilter from './VerifiedFilter'
import DateFilter from './DateFilter'
import VerificationAccountFilter from './VerificationAccountFilter'

const TransactionsFilter = ({ onFilterUpdate }) => {
  const [filters, setFilters] = useState({})
  const [amountDropdownOpen, setAmountDropdownOpen] = useState(false)
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false)
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false)
  const [verifiedDropdownOpen, setVerifiedDropdownOpen] = useState(false)

  useUpdateEffect(() => {
    const filter = {
      amountRange: filters.amountRange?.value,
      verified: filters.verified?.value,
      dateRange: filters.dateRange?.value,
      verificationAccountIds: filters.verificationAccountIds?.value,
    }

    onFilterUpdate(filter)

    // Store the selected filters in the local storage to
    // persist them between page reloads and navigation
    localStorage.setItem('transactionsFilter', JSON.stringify(filters))
  }, [filters])

  /**
   * Sets the initial filters from the local storage if they exist.
   */
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem('transactionsFilter'))
    if (storedFilters) {
      setFilters(storedFilters)
    }
  }, [])

  /**
   * Updates the active filters object.
   * If the new value is null, we remove the whole key from the object
   * instead of just setting it to null. That way we can count the number
   * of active filters and show/hide the "clear all filters" button.
   */
  const updateFilter = (filter, value) => {
    if (value == null) {
      delete filters[filter]
      setFilters({ ...filters })
    } else {
      setFilters({ ...filters, [filter]: value })
    }

    // Close all dropdowns
    setAmountDropdownOpen(false)
    setDateDropdownOpen(false)
    setAccountDropdownOpen(false)
    setVerifiedDropdownOpen(false)
  
    
  }

  /**
   * Clears the filters and resets the local storage.
   */
  function didClickClearFilters() {
    setFilters({})
  }

  return (
    <div className="flex flex-row pb-3 mb-3 space-x-3">
      {/* Amount */}
      <Dropdown.Root open={amountDropdownOpen} onOpenChange={setAmountDropdownOpen}>
        <Dropdown.Trigger
          placeholder="Belopp"
          label={filters.amountRange?.label}
          onClear={() => updateFilter('amountRange', undefined)}
          as={FilterButton}
        />
        <Dropdown.Content>
          <AmountFilter
            initialValues={filters.amountRange?.value}
            onFilterSubmit={values => updateFilter('amountRange', values)}
          />
        </Dropdown.Content>
      </Dropdown.Root>
      {/* Date */}
      <Dropdown.Root open={dateDropdownOpen} onOpenChange={setDateDropdownOpen}>
        <Dropdown.Trigger
          placeholder="Datum"
          label={filters.dateRange?.label}
          onClear={() => updateFilter('dateRange', undefined)}
          as={FilterButton}
        />
        <Dropdown.Content>
          <DateFilter
            initialValues={filters.dateRange?.value}
            onFilterSubmit={values => updateFilter('dateRange', values)}
          />
        </Dropdown.Content>
      </Dropdown.Root>
      {/* Verification Accounts */}
      <Dropdown.Root open={accountDropdownOpen} onOpenChange={setAccountDropdownOpen}>
        <Dropdown.Trigger
          placeholder="Kategorier"
          label={filters.verificationAccountIds?.label}
          onClear={() => updateFilter('verificationAccountIds', undefined)}
          as={FilterButton}
        />
        <Dropdown.Content>
          <VerificationAccountFilter
            initialValues={filters.verificationAccountIds?.value}
            onFilterSubmit={values =>
              updateFilter('verificationAccountIds', values)
            }
          />
        </Dropdown.Content>
      </Dropdown.Root>
      {/* Verified */}
      <Dropdown.Root open={verifiedDropdownOpen} onOpenChange={setVerifiedDropdownOpen}>
        <Dropdown.Trigger
          placeholder="Status"
          label={filters.verified?.label}
          onClear={() => updateFilter('verified', undefined)}
          as={FilterButton}
        />
        <Dropdown.Content>
          <VerifiedFilter
            initialValues={filters.verified?.value}
            onFilterSubmit={values => updateFilter('verified', values)}
          />
        </Dropdown.Content>
      </Dropdown.Root>

      {Object.keys(filters).length !== 0 && (
        <button
          type="button"
          onClick={didClickClearFilters}
          className="px-3 text-sm font-medium text-blue-600 transition-colors duration-150 ease-in-out border border-blue-600 rounded-full hover:bg-gray-200 focus:outline-none"
        >
          Rensa alla filter
        </button>
      )}
    </div>
  )
}

TransactionsFilter.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
}

export default TransactionsFilter
