import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery, gql, useReactiveVar } from '@apollo/client'
import { toast } from 'react-hot-toast'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Checkbox } from '@aider/ui'

import { track, events } from '@utils/analytics'
import { activePrincipalIdVar } from '@/cache'

const validationSchema = Yup.object().shape({
  confirmation: Yup.bool().oneOf([true], 'Du måste godkänna.'),
})

const DELETE_PRINCIPAL = gql`
  mutation deletePrincipal($principalId: ID!) {
    deletePrincipal(id: $principalId) {
      id
    }
  }
`

const PRINCIPAL_DETAILS = gql`
  query principalDetails($principalId: ID!) {
    principal(id: $principalId) {
      id
      name
    }
  }
`

const DeletePrincipal = ({ principalId, onSuccess, onCancel }) => {
  const activePrincipalId = useReactiveVar(activePrincipalIdVar)
  const { data: { principal: { name } = {} } = {} } = useQuery(
    PRINCIPAL_DETAILS,
    { variables: { principalId } },
  )

  const [deletePrincipal, { loading: deletingPrincipal }] = useMutation(
    DELETE_PRINCIPAL,
    {
      update(cache, { data: { deletePrincipal: p } = {} } = {}) {
        cache.evict({ id: cache.identify(p) })
      },
      onCompleted: useCallback(
        ({ deletePrincipal: { id: deletedPrincipalId } = {} }) => {
          track(events.USER_DELETED_PRINCIPAL)

          // Clear activePrincipalId if the deleted user is active
          if (deletedPrincipalId === activePrincipalId) {
            activePrincipalIdVar(undefined)
          }

          if (typeof onSuccess === 'function') {
            onSuccess()
          }
        },
        [onSuccess, activePrincipalId],
      ),
      onError: useCallback(() => {
        toast.error('Kunde inte ta bort huvudmannen')
      }, []),
    },
  )

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
  } = useFormik({
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: { principal: principalId, confirmation: false },
    onSubmit: async () => deletePrincipal({ variables: { principalId } }),
  })

  return (
    <div className="p-6 bg-white rounded-lg" style={{ width: 480 }}>
      <header className="mb-4 text-black text-lg font-medium">
        Radera huvudman
      </header>
      <form onSubmit={handleSubmit}>
        <div className="mb-4 text-gray-800">
          Du är på väg att radera
          <span className="mx-1 text-black font-semibold">{name}</span>från ditt
          konto. Det betyder att transaktioner, dagboksinlägg och rapporter
          kommer att försvinna permanent.
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="confirmation"
            checked={values.confirmation}
            onCheckedChange={handleChange}
            name="confirmation"
          />
          <label className="text-base font-medium" htmlFor="confirmation">
            Jag förstår
          </label>
        </div>
        <div className="flex justify-between pt-6">
          <Button
            variant="tertiary"
            type="button"
            title="Avbryt"
            onClick={() => typeof onCancel === 'function' && onCancel()}
          />
          <Button
            title="Radera huvudman"
            type="submit"
            isLoading={isSubmitting || deletingPrincipal}
            disabled={isSubmitting || !isValid || deletingPrincipal}
            variant="destructive"
          />
        </div>
      </form>
    </div>
  )
}

DeletePrincipal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default DeletePrincipal
