import React from 'react'
import tw from 'twin.macro'
import styled from "@emotion/styled/macro";
import { Link } from 'react-router-dom'
import { Checkbox } from '@aider/ui'
import {
  CurrencyCell,
  DateCell,
  StatusCell,
} from '@components/'
/*eslint-disable*/

const HeaderCell = (props) => (
  <Cell scope="col" role="columnheader" className="h-10 text-xs font-medium text-left text-gray-800 uppercase">
    {props.children}
  </Cell>
);

const TableHead = ({ columns, masterChecked, onMasterCheck }) => (
  <thead className="bg-gray-200 border-b border-gray-300">
    <Row role="row">
      <HeaderCell>
        <Checkbox
          id="mastercheck"
          checked={masterChecked}
          onCheckedChange={(e) => onMasterCheck(e)}
        />
      </HeaderCell>
      {columns.map(col => (<HeaderCell key={col.accessor}>{col.Header}</HeaderCell>))}
    </Row>
  </thead>
);

const RowCell = (props) => (
  <Cell>
    <div className="text-black font-medium truncate">{props.children}</div>
  </Cell>
);

class TransactionTable extends React.Component {
  constructor(props) {
    super(props);
    this.preFilteredList = props.data;
    this.state = {
      List: this.preFilteredList,
      MasterChecked: false,
      SelectedRows: [],
    };
    // This binding is necessary to make `this` work in the callback
    this.onMasterCheck = this.onMasterCheck.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.preFilteredList = this.props.data;
      this.setState({
        List: this.preFilteredList
      });
    }
    if (prevProps.data.length !== this.props.data.length) {
      // Todo: Improve change detection
      this.setState({
        MasterChecked: false,
        SelectedRows: [],
      });
    }
  }
  // Select/ UnSelect Table rows
  onMasterCheck(e) {
    const tempList = this.state.List;
    const checkedState = e.target.checked;
    // Check/ UnCheck All Items
    tempList.map((row) => (row.selected = checkedState));

    const selectedRows = this.state.List.filter((e) => e.selected);
    this.setState({
      MasterChecked: checkedState,
      List: selectedRows.length === 0 ? this.preFilteredList : tempList,
      SelectedRows: selectedRows,
    });
    if (typeof this.props.onSelectedRowsChange === 'function') {
      this.props.onSelectedRowsChange(selectedRows);
    }
  }

  // Update List Item's state and Master Checkbox State
  onItemCheck(e, item) {
    const tempList = this.state.List;
    let incomesVisible = true
    let expensesVisible = true
    if (e.target.checked && item.amount < 0) {
      incomesVisible = false
    } else if (e.target.checked && item.amount > 0) {
      expensesVisible = false;
    }
    tempList.map((row) => {
      if (row.id === item.id) {
        row.selected = e.target.checked;
      }
      return row;
    });

    // To Control Master Checkbox State
    const totalItems = this.state.List.length;
    const selectedRows = this.state.List.filter((row) => row.selected);
    const totalCheckedItems = selectedRows.length;

    // filtered list based on income/expense row selection
    const filteredList = this.preFilteredList.filter((row) => {
      if (!incomesVisible) { return row.amount < 0 }
      if (!expensesVisible) { return row.amount > 0 }
      return row
    });
    // Update State
    this.setState({
      MasterChecked: totalItems === totalCheckedItems,
      List: filteredList,
      SelectedRows: selectedRows,
    });
    if (typeof this.props.onSelectedRowsChange === 'function') {
      this.props.onSelectedRowsChange(selectedRows);
    }

  }

  render() {
    return (
      <div className="overflow-hidden border border-gray-300 rounded-lg">
        <table className="w-full" role="grid">
          <TableHead
            columns={this.props.columns}
            masterChecked={this.state.MasterChecked}
            onMasterCheck={this.onMasterCheck}
          />
          <tbody role="rowgroup">
            {this.state.List.map((transaction) => (
              <Row key={transaction.id}
                className={`cursor-pointer ${transaction.selected ? "selected" : ""}`}
                role="gridcell"
                selectable
              >
                <RowCell scope="row">
                  <Checkbox
                    id="rowcheck{transaction.id}"
                    checked={transaction.selected}
                    onCheckedChange={(e) => this.onItemCheck(e, transaction)}
                  />
                </RowCell>
                <RowCell onClick={() => this.props.onRowClick(transaction.id)}>
                  <Link to={`/huvudman/redovisning/transaktioner/${transaction.id}`}>
                    {transaction.description || 'Saknar beskrivning'}
                  </Link>
                </RowCell>
                <RowCell onClick={() => this.props.onRowClick(transaction.id)}>
                  <CurrencyCell amount={transaction.amount} />
                </RowCell>
                <RowCell onClick={() => this.props.onRowClick(transaction.id)}>
                  <DateCell date={transaction.date} />
                </RowCell>
                <RowCell onClick={() => this.props.onRowClick(transaction.id)}>
                  <StatusCell
                    verified={transaction.verified}
                    verifications={transaction.verifications}
                    hasAttachment={transaction.attachments?.length > 0}
                  />
                </RowCell>
              </Row>
            ))}
          </tbody>
          <tfoot className="table-row-group">
            <tr>
              <td
                className="p-3 font-medium"
                colSpan={5}>
                {this.state.List.length} resultat
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}


const Cell = styled.td`
  ${tw`py-3`}
  ${({ isCheckbox }) =>
    isCheckbox
      ? tw`absolute flex items-center w-12 h-full px-3 opacity-0`
      : tw`relative w-auto px-3 transform`}
`
const Row = styled.tr`
  ${tw`relative align-top border-b border-gray-300 hover:bg-gray-100`}

  ${Cell}:nth-child(1) {
      ${({ selected }) => selected && tw`opacity-100`}
    }
  
  ${Cell}:nth-child(2) {
    ${({ selected }) => selected && tw`translate-x-7`}
  }
  
  &:hover {
    ${Cell}:nth-child(1) {
      ${({ selectable }) => selectable && tw`opacity-100`}
    }
    ${Cell}:nth-child(2) {
      ${({ selectable }) => selectable && tw`translate-x-7`}
    }
  }
`

export default TransactionTable;