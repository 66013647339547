import { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useReactiveVar, useQuery, gql } from '@apollo/client'
import { format, startOfYear } from 'date-fns'
import { sv as locale } from 'date-fns/locale'
import { Table, Dropdown, FilterButton, Icon } from '@aider/ui'

import {
  LoadingContainer,
  CurrencyCell,
  DateCell,
  EmptyState,
  YearMonthDatePicker,
} from '@components/'
import EmptyTransactions from '@assets/images/empty_transactions.png'
import { useEventEmitter, events } from '@hooks/useEventEmitter'
import { dateToYearRange, dateToMonthRange } from '@utils/date'

import { activePrincipalIdVar } from '@/cache'

const VERIFIED_TRANSACTIONS = gql`
  query verifiedTransactions(
    $principalIds: [ID]
    $after: String
    $verified: Boolean
    $dateRange: DateRange
  ) {
    me {
      id
      transactions(
        date: $dateRange
        after: $after
        verified: $verified
        first: 50
        orderBy: [
          { column: DATE, order: DESC }
          { column: UPDATED_AT, order: DESC }
        ]
        principalIds: $principalIds
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            description
            amount
            expense
            date
            note
            deduction
            attachments {
              id
              url
              originalFilename
            }
            verifications {
              id
              amount
              taxAmount
              verificationAccount {
                name
                description
              }
              tag {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

const Verifications = () => {
  const eventEmitter = useEventEmitter()
  const principalId = useReactiveVar(activePrincipalIdVar)
  const [date, setDate] = useState(new Date())
  const [pickerMode, setPickerMode] = useState('year')

  /**
   * Creates a date range object that is either a year or a month
   * @param {Date} d A date object
   * @returns A date range object
   */
  function getSelectedDateRange(d) {
    if (pickerMode === 'year') {
      return dateToYearRange(d)
    }
    if (pickerMode === 'month') {
      return dateToMonthRange(d)
    }

    return undefined
  }
  const dateRange = useMemo(() => getSelectedDateRange(date), [date])

  const {
    data: {
      me: {
        transactions: {
          pageInfo: { endCursor, hasNextPage } = {},
          edges: transactions = [],
        } = {},
      } = {},
    } = {},
    loading: loadingTransactions,
    fetchMore,
  } = useQuery(VERIFIED_TRANSACTIONS, {
    skip: !principalId,
    // Updating paginated lists with multiple arguments in the cache is too complex,
    // so we'll just use 'network-only' for now.
    // @see https://github.com/apollographql/apollo-client/issues/2991
    // @see https://medium.com/@martinseanhunt/how-to-invalidate-cached-data-in-apollo-and-handle-updating-paginated-queries-379e4b9e4698
    fetchPolicy: 'network-only',
    variables: {
      verified: true,
      principalIds: [principalId],
      dateRange,
    },
  })

  useEffect(() => {
    const unsubscribe = eventEmitter.on(events.CONTENT_SCROLL_BOTTOM, () => {
      if (!hasNextPage || loadingTransactions) return
      fetchMore({ variables: { after: endCursor } })
    })

    return () => unsubscribe()
  }, [eventEmitter, fetchMore, loadingTransactions, hasNextPage, endCursor])

  const columns = useMemo(
    () => [
      {
        Header: 'Referens',
        accessor: 'node.id',
        Cell: ({ value }) => (
          <Link
            className="font-medium text-black truncate"
            to={`/huvudman/redovisning/bokforing/${value}`}
          >
            <div className="font-medium text-blue-500 truncate">#{value}</div>
          </Link>
        ),
      },
      {
        Header: 'Datum',
        accessor: 'node.date',
        Cell: ({ value }) => (
          <DateCell className="font-medium text-black truncate" date={value} />
        ),
      },
      {
        Header: 'Beskrivning',
        accessor: 'node',
        id: 'description',
        Cell: ({
          cell: {
            value: {
              amount,
              description,
              verifications,
              note,
              attachments,
              deduction,
            } = {},
          } = {},
        }) => (
          <div>
            <div className="flex justify-between mb-2">
              <div className="max-w-xs font-medium truncate">{description}</div>
              <CurrencyCell className="font-medium" amount={amount} />
            </div>
            <ul>
            {verifications.map(
                  ({
                    id,
                    verificationAccount,
                    amount: verificationAmount,
                    taxAmount,
                    tag = {},
                  }) => (
                    <li
                      key={id}
                      className="flex justify-between py-2 border-b border-gray-300 last:border-b-0"
                    >
                      <div className="flex flex-col max-w-xs text-sm font-medium text-gray-900">
                        <span>{verificationAccount.name}</span>
                        {taxAmount > 0 && <div>Avdragen skatt: <CurrencyCell className="text-sm font-medium text-gray-900" amount={taxAmount} /></div>}
                        <span className="ml-2">{tag?.name}</span>
                        <div className="text-sm">
                          <p className="text-gray-500">{note}</p>
                          {attachments && attachments.length > 0 && (
                            <div className="flex flex-col items-start mt-2 space-y-1">
                              {attachments.map(item => (
                                <a
                                  key={item.id}
                                  href={item.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="inline-flex items-center space-x-1 font-medium text-blue-600 transition-colors duration-100 ease-in-out border-b border-transparent hover:border-blue-600"
                                >
                                  <Icon
                                    name="clip"
                                    className="w-4 h-4 fill-current"
                                  />
                                  <span>{item.originalFilename}</span>
                                </a>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <CurrencyCell
                        className="text-sm font-medium text-gray-900"
                        amount={verificationAmount}
                      />
                    </li>
                  ),
                )}
              {deduction > 0 && (
                <li className="flex justify-between py-2 border-b border-gray-300 last:border-b-0">
                  <div className="flex flex-col max-w-xs text-sm font-medium text-gray-900">
                    <span>Utmätningar och avdrag</span>
                  </div>
                  <CurrencyCell
                    className="text-sm font-medium text-gray-900"
                    amount={-deduction}
                  />
                </li>
              )}
            </ul>
          </div>
        ),
      },
    ],
    [],
  )

  /**
   * Reacts when the user changes the mode of the date picker
   * @param {String} e The updated picker mode, 'year' or 'month'
   */
  const handleChangePickerMode = newMode => {
    const previous = pickerMode
    setPickerMode(newMode)

    // Triggers a re-render if the mode changes
    // so that going year to month pre-selects january,
    // and going month to year pre-selects the current year.
    if (previous !== newMode) {
      setDate(startOfYear(date))
    }
  }

  return (
    <LoadingContainer loading={loadingTransactions}>
      <header className="sticky top-0 z-10 flex items-center h-20 space-x-4 bg-white">
        <div className="text-2xl font-medium">Bokföring</div>
        <Dropdown.Root>
          <Dropdown.Trigger
            title={pickerMode === 'year' ? 'Period' : ''}
            value={
              pickerMode === 'year'
                ? format(date, 'yyyy', { locale })
                : format(date, 'MMM yyyy', { locale })
            }
            as={FilterButton}
          />
          <Dropdown.Content align="start" sideOffset={6}>
            <YearMonthDatePicker
              date={date}
              mode={pickerMode}
              onChangeDate={d => setDate(d)}
              onChangeMode={mode => handleChangePickerMode(mode)}
            />
          </Dropdown.Content>
        </Dropdown.Root>
      </header>
      <div className="pb-8 md:w-9/12">
        Här listas alla bokförda transaktioner för vald huvudman och period.
      </div>
      {Array.isArray(transactions) && transactions.length ? (
        <div className="mb-20">
          <Table columns={columns} data={transactions} />
        </div>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center">
          <EmptyState
            icon={
              <img
                className="w-16 h-auto mb-2"
                src={EmptyTransactions}
                alt="transactions"
              />
            }
            title="Du har inga bokförda transaktioner för den valda perioden"
            description="När du bokför transaktioner hamnar de automatiskt under fliken bokföring."
          />
        </div>
      )}
    </LoadingContainer>
  )
}

export default Verifications
