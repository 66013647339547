import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation, gql } from '@apollo/client'
import { Button, Select } from '@aider/ui'
import { toast } from 'react-hot-toast'
import useVerificationAccounts, { ACCOUNT_TYPES } from '@hooks/useVerificationAccounts'

const validationSchema = Yup.object().shape({
  transactionIds: Yup.array()
    .of(Yup.string())
    .required('Obligatoriskt fält.'),
})

const UPDATE_TRANSACTIONS = gql`
  mutation updateTransactions(
    $id: ID!
    $verifications: [CreateVerificationInput!]!
  ) {
    updateTransactions(
      input: {
        id: $id
        verifications: { create: $verifications }
      }
    ) {
      id
      description
      verifications {
        id
        verificationAccount {
          id
        }
        tag {
          id
          name
        }
      }
    }
  }
`

const UpdateTransactions = ({ transactionIds, transactionAmounts, onSuccess, onCancel, expensesVisible }) => {
  const [updateTransactions] = useMutation(UPDATE_TRANSACTIONS, {
    onCompleted: useCallback(() => {
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
      toast.success('Transaktionen bokfördes!')
    }, [onSuccess]),
    onError: useCallback(() => {
      toast.error('Kunde inte bokföra transaktionen')
    }, []),
    update: (cache, { data: { updateTransactions: updated } = {} } = {}) => {
      cache.modify({
        id: cache.identify(updated),
        fields: {
          updatedAt() {
            return updated.updatedAt
          },
        },
      })
    },
  })

  const {
    values,
    dirty,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
  } = useFormik({
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues: { transactionIds, verificationAccount: null, amounts: transactionAmounts },
    onSubmit: ({ transactionIds: ids, verificationAccount = {} }) =>
      // TODO: Modify to bulk mutation calls
      ids.forEach((id, index) => {
        updateTransactions({
          variables: {
            id,
            verifications: [{ verificationAccountId: verificationAccount.value, amount: transactionAmounts[index] || 0 }],
          }
        })
      })
  });

  /**
   * Get verification accounts
   */
  const {
    data: { verificationAccounts: incomeAccounts = [] } = {},
    loading: loadingIncomeAccounts,
  } = useVerificationAccounts({
    variables: { accountTypes: ACCOUNT_TYPES.incomes },
  })

  const {
    data: { verificationAccounts: expenseAccounts = [] } = {},
    loading: loadingExpenseAccounts,
  } = useVerificationAccounts({
    variables: { accountTypes: ACCOUNT_TYPES.expenses },
  })

  return (
    <div
      className="p-6 bg-white rounded-lg"
      style={{ width: 480 }}
    >
      <header className="mb-2 text-black text-lg font-medium">
        Bokför {transactionIds.length}{' '}
        {transactionIds.length > 1 ? 'transaktioner' : 'transaktion'}
      </header>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="mb-4 text-gray-800">
          Välj kategori för att bokföra markerade transaktioner
        </div>
        <div className="mb-4">
          <div className="flex items-center space-x-2">
            <label className="text-base font-medium" htmlFor="verificationAccount">
              Välj kategori
            </label>
            <Select
              id="verificationAccount"
              selected={values.verificationAccount}
              onChange={handleChange}
              name="verificationAccount"
              placeholder="Välj kategori"
              isLoading={expensesVisible ? loadingExpenseAccounts : loadingIncomeAccounts}
              options={(expensesVisible ? expenseAccounts : incomeAccounts).map(
                ({ name, id }) => ({
                  value: id,
                  label: name,
                }),
              )}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <Button
            title="Avbryt"
            type="button"
            variant="tertiary"
            onClick={() => typeof onCancel === 'function' && onCancel()}
          />
          <Button
            title="Bokför alla"
            type="submit"
            disabled={!isValid || !dirty || isSubmitting}
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </div>
  )
}

UpdateTransactions.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default UpdateTransactions
