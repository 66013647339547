import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery, gql } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Checkbox } from '@aider/ui'

const validationSchema = Yup.object().shape({
  confirmation: Yup.bool().oneOf([true], 'Du måste godkänna.'),
})

const DELETE_CONTACT = gql`
  mutation deleteContact($contactId: ID!) {
    deleteContact(id: $contactId) {
      id
      name
    }
  }
`

const CONTACT = gql`
  query contact($contactId: ID!) {
    contact(id: $contactId) {
      id
      name
    }
  }
`

const DeleteContact = ({ contactId, onSuccess, onCancel, onError }) => {
  const { data: { contact: { name } = {} } = {} } = useQuery(CONTACT, {
    variables: { contactId },
  })

  const [deleteContact, { loading: deletingContact }] = useMutation(
    DELETE_CONTACT,
    {
      awaitRefetchQueries: true,
      refetchQueries: ['principalById'],
      onCompleted: useCallback(() => {
        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      }, [onSuccess]),
      onError: useCallback(() => {
        if (typeof onError === 'function') {
          onError()
        }
      }, [onError]),
    },
  )

  const { values, handleChange, handleSubmit, isSubmitting, isValid } =
    useFormik({
      validateOnMount: true,
      validationSchema,
      validateOnChange: true,
      validateOnBlur: true,
      initialValues: { confirmation: false },
      onSubmit: async () => deleteContact({ variables: { contactId } }),
    })

  return (
    <div className="p-6 bg-white rounded-lg" style={{ width: 480 }}>
      <header className="mb-4 text-black text-lg font-medium">
        Radera kontakt
      </header>
      <form onSubmit={handleSubmit}>
        <div className="mb-4 text-gray-800">
          Du är på väg att radera
          <span className="ml-1 text-black font-semibold">{name}</span>. Det
          betyder att kontakten kommer att försvinna permanent och inte kunna
          att återskapas.
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="confirmation"
            checked={values.confirmation}
            onCheckedChange={handleChange}
            name="confirmation"
          />
          <label className="text-base font-medium" htmlFor="confirmation">
            Jag förstår
          </label>
        </div>
        <div className="flex justify-between pt-6">
          <Button
            variant="tertiary"
            type="button"
            title="Avbryt"
            onClick={() => typeof onCancel === 'function' && onCancel()}
          />
          <Button
            title="Radera kontakt"
            type="submit"
            isLoading={isSubmitting || deletingContact}
            disabled={isSubmitting || !isValid || deletingContact}
            variant="destructive"
          />
        </div>
      </form>
    </div>
  )
}

DeleteContact.defaultProps = {
  contactId: null,
}

DeleteContact.propTypes = {
  contactId: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default DeleteContact
