import { useState, useEffect, useCallback } from 'react'
import { gql, useLazyQuery } from '@apollo/client'

export const STATISTICS = gql`
  query statistics(
    $principalId: ID!
    $type: StatisticType!
    $date: DateRange!
    $includeTax: Boolean
  ) {
    principal(id: $principalId) {
      id
      statistics(type: $type, date: $date, includeTax: $includeTax) {
        totalValue
        result {
          id
          name
          value
          tax
        }
      }
    }
  }
`

const useAccountingStatistics = () => {
  const [data, setData] = useState({})
  const [_loading, _setLoading] = useState(false)
  const [_error, _setError] = useState(false)

  /**
   * Merge data into a data state
   * @param  {String} key     Object key to use
   * @param  {Object} newData Data to be merged
   * @return null
   */
  const mergeData = useCallback(
    (key, newData) => {
      setData({ ...data, [key]: { ...newData } })
    },
    [data],
  )

  const [
    getBalance,
    { loading: loadingBalance, error: balanceError },
  ] = useLazyQuery(STATISTICS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: useCallback(
      ({ principal: { statistics = {} } = {} }) =>
        mergeData('balance', statistics),
      [mergeData],
    ),
  })

  const [
    getIncomes,
    { loading: loadingIncomes, error: incomesError },
  ] = useLazyQuery(STATISTICS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: useCallback(
      ({ principal: { statistics = {} } = {} }) =>
        mergeData('incomes', statistics),
      [mergeData],
    ),
  })

  const [
    getExpenses,
    { loading: loadingExpenses, error: expensesError },
  ] = useLazyQuery(STATISTICS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: useCallback(
      ({ principal: { statistics = {} } = {} }) => {
        mergeData('expenses', statistics)
      },
      [mergeData],
    ),
  })

  /**
   * Update loading state based on all query states
   */
  useEffect(() => {
    if (!loadingIncomes && !loadingExpenses && !loadingBalance) {
      _setLoading(false)
    } else {
      _setLoading(true)
    }
  }, [loadingIncomes, loadingExpenses, loadingBalance])

  /**
   * Update error to the latest of errors
   */
  useEffect(() => {
    if (incomesError) _setError(incomesError)
    if (expensesError) _setError(expensesError)
    if (balanceError) _setError(balanceError)
  }, [incomesError, expensesError, balanceError])

  /**
   * Get statistics
   */
  const getStatistics = useCallback(
    ({ ...vars }) => {
      getIncomes({ variables: { type: 'INCOMES_BY_CATEGORY', ...vars } })
      getExpenses({ variables: { type: 'EXPENSES_BY_CATEGORY', ...vars } })
      getBalance({ variables: { type: 'TRANSACTION_BALANCE', ...vars } })
    },
    [getIncomes, getExpenses, getBalance],
  )

  return [getStatistics, { data, loading: _loading, error: _error }]
}

export default useAccountingStatistics
