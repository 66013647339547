import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import { useQuery, useMutation, gql } from '@apollo/client'
import { track, events } from '@utils/analytics'

const TINK_LINK = gql`
  query tinkLink(
    $bankProvider: String!
    $redirectUri: String!
    $iframe: Boolean!
    $test: Boolean!
  ) {
    tinkLink(
      bankProvider: $bankProvider
      redirectUri: $redirectUri
      iframe: $iframe
      test: $test
    ) {
      url
    }
  }
`

const UPDATE_IMPORT = gql`
  mutation updateImport($importId: ID!, $tinkAuthCode: String!) {
    updateImport(input: { id: $importId, tinkAuthCode: $tinkAuthCode }) {
      id
    }
  }
`

const USER_ID = gql`
  query userId {
    me {
      id
    }
  }
`

const TINK_ORIGIN = 'https://link.tink.com'

const TinkLink = ({
  onSuccess,
  onError,
  bankProvider,
  isTestBankProvider,
  importId,
}) => {
  const { data: { me: { id: userId } = {} } = {} } = useQuery(USER_ID)
  const { data: { tinkLink: { url } = {} } = {} } = useQuery(TINK_LINK, {
    skip: !bankProvider,
    fetchPolicy: 'no-cache',
    variables: {
      bankProvider,
      redirectUri: window.location.origin,
      iframe: true,
      test: isTestBankProvider,
    },
    onError: useCallback(
      error => {
        onError(error)
      },
      [onError],
    ),
  })

  const [updateImport] = useMutation(UPDATE_IMPORT, {
    onCompleted: useCallback(() => {
      track(events.USER_CONNECTED_BANK_ACCOUNT)
      onSuccess()
    }, [onSuccess]),
    onError: useCallback(
      error => {
        onError(error)
      },
      [onError],
    ),
  })

  // Listen for messages from Tink
  // https://docs.tink.com/api/#tink-link-response-iframe-mode
  useEffect(() => {
    const onMessage = event => {
      if (event.origin !== TINK_ORIGIN) return

      const { type, error, data } = JSON.parse(event.data)

      if (typeof error === 'object' && error !== null) {
        const err = new Error(error.message)
        Sentry.captureException(err, { extra: { bankProvider, userId } })
        console.log('Tink Error', error)
        onError(err)
      }

      if (type === 'code') {
        updateImport({ variables: { importId, tinkAuthCode: data } })
      }
    }

    window.addEventListener('message', onMessage, false)

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [onError, updateImport, userId, importId, bankProvider])

  return <iframe title="Tink Link" className="w-full h-full" src={url} />
}

TinkLink.propTypes = {
  bankProvider: PropTypes.string.isRequired,
  isTestBankProvider: PropTypes.bool.isRequired,
  importId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default TinkLink
