import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'

import CreateImport from './CreateImport'
import TinkLink from './TinkLink'
import SelectBankAccount from './SelectBankAccount'

const ImportWithTink = ({
  onSuccess,
  onCancel,
  onError,
  bankProvider,
  isTestBankProvider,
}) => {
  const [step, setStep] = useState(0)
  const [importId, setImportId] = useState()

  // Increment step
  const nextStep = useCallback(() => {
    setStep(step + 1)
  }, [step])

  return (
    <motion.div
      key="initial"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="flex flex-col bg-white rounded-lg overflow-hidden"
      style={{ width: 440, height: 580 }}
    >
      <AnimatePresence exitBeforeEnter>
        {(() => {
          switch (step) {
            case 0:
              return (
                <motion.div
                  key="create-import"
                  className="absolute left-0 top-0 flex w-full h-full"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.2 }}
                >
                  <CreateImport
                    onSuccess={createdImportId => {
                      setImportId(createdImportId)
                      nextStep()
                    }}
                    onCancel={onCancel}
                    onError={onError}
                  />
                </motion.div>
              )
            case 1:
              return (
                <motion.div
                  key="tink"
                  className="absolute left-0 top-0 w-full h-full"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.2 }}
                >
                  <TinkLink
                    importId={importId}
                    bankProvider={bankProvider}
                    isTestBankProvider={isTestBankProvider}
                    onSuccess={nextStep}
                    onError={onError}
                  />
                </motion.div>
              )
            case 2:
              return (
                <motion.div
                  key="select-account"
                  className="absolute left-0 top-0 w-full h-full rounded-lg overflow-hidden"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.2 }}
                >
                  <SelectBankAccount
                    importId={importId}
                    onSuccess={() => {
                      // Pass importId to the onSuccess callback
                      onSuccess(importId)
                    }}
                    onError={onError}
                  />
                </motion.div>
              )
            default:
              return null
          }
        })()}
      </AnimatePresence>
    </motion.div>
  )
}

ImportWithTink.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  isTestBankProvider: PropTypes.bool,
  bankProvider: PropTypes.string,
}

ImportWithTink.defaultProps = {
  bankProvider: null,
  isTestBankProvider: null,
}

export default ImportWithTink
