import { useState } from 'react'
import { formatMinutes } from '@utils/date'

const HorizontalChartItem = props => {
  /** Controls whether the caption on hover should be displayed or not, we use opacity so that it can be animated. */
  const [hoverCaptionStyle, setHoverCaptionStyle] = useState({
    opacity: 0,
  })

  return (
    <div
      style={{ width: `${(props.item.duration / props.totalDuration) * 100}%` }}
      onMouseEnter={() => setHoverCaptionStyle({ opacity: 1 })}
      onMouseLeave={() => setHoverCaptionStyle({ opacity: 0 })}
      className="relative flex flex-col items-start space-y-1 print:text-xs"
    >
      <div
        className={`font-medium ${props.textColor} whitespace-nowrap relative bg-white`}
      >
        {props.item.category} <span className="text-gray-300">•</span>{' '}
        <span className="text-gray-500">
          {formatMinutes(props.item.duration)}
        </span>
      </div>
      {/* Absolute positioned gradient, used to fade out the text if the entry is not wide enough */}
      <div className="absolute top-0 right-0 w-8 bg-gradient-to-r from-transparent to-white bottom-3" />
      {/* Horizontal bar */}
      <div className={`w-full h-1.5 print:h-1 rounded-full ${props.bgColor}`} />

      {/* Caption shown on hover */}
      <div
        style={hoverCaptionStyle}
        className="absolute left-0 w-auto whitespace-nowrap px-2 py-0.5 -ml-2 rounded-md text-gray-600 border border-gray-300 shadow-sm top-9 flex items-center space-x-1 font-medium transition-opacity duration-100 ease-in-out"
      >
        <span className="">{props.item.category}</span>
        <span className="text-gray-300">•</span>
        <span className="text-gray-500">
          {formatMinutes(props.item.duration)}
        </span>
        <span className="text-gray-300">•</span>
        <span className="text-gray-500">
          {((props.item.duration / props.totalDuration) * 100).toFixed(0)}%
        </span>
      </div>
    </div>
  )
}

export default HorizontalChartItem
