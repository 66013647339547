import { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery, gql } from '@apollo/client'
import { Button, Icon, Loader } from '@aider/ui'

const SUBMIT_REPORT = gql`
  query submitReport($id: ID!, $orderRef: String!) {
    submitReport(id: $id, orderRef: $orderRef) {
      status
      hintCode
    }
  }
`

const SIGN_REPORT = gql`
  query signReport($id: ID!) {
    signReport(id: $id) {
      autoStartToken
      orderRef
      status
    }
  }
`

const CANCEL_BANKID_ORDER = gql`
  query cancelOrder($orderRef: String!) {
    cancelOrder(orderRef: $orderRef) {
      status
    }
  }
`

const SubmitReportToMunicipality = ({
  reportId,
  onSuccess,
  onCancel,
  onError,
}) => {
  const [orderRef, setOrderRef] = useState(null)

  // Submits the report
  const [submitReport, { stopPolling }] = useLazyQuery(SUBMIT_REPORT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: 2000,
    onCompleted: ({ submitReport: { status } = {} }) => {
      // We don't use the useCallback hook here because we can't use stopPolling inside it
      if (status === 'success') {
        stopPolling()
        onSuccess()
      }
    },
    onError: () => {
      // No point in keep polling if we get an error
      stopPolling()
      onError()
    },
  })

  // Starts the signing process with BankID
  const [signReport] = useLazyQuery(SIGN_REPORT, {
    fetchPolicy: 'network-only',
    onCompleted: useCallback(
      ({ signReport: { status, orderRef: oRef = {} } = {} }) => {
        if (status === 'error') {
          // We can't continue, let's stop
          onError()
        }

        if (status === 'duplicate') {
          // We can't continue, let's stop
          onError()
        }

        submitReport({ variables: { id: reportId, orderRef: oRef } })
        // Store in state so we can use it on cancel
        setOrderRef(oRef)
      },
    ),
    onError: useCallback(() => {
      onError()
    }),
  })

  // Cancels the BankID order if requested by the user
  const [cancelOrder] = useLazyQuery(CANCEL_BANKID_ORDER, {
    fetchPolicy: 'network-only',
    onCompleted: ({ cancelOrder: { status } = {} }) => {
      if (status === 'canceled') {
        console.log('Status = canceled')
        onCancel()
      }
    },
    onError: () => {
      onError()
    },
  })

  const handleCancel = () => {
    // Stop polling of SUBMIT_REPORT, otherwise it will run onError when we cancel below.
    stopPolling()

    cancelOrder({
      variables: { orderRef },
    })
  }

  useEffect(() => {
    signReport({
      variables: { id: reportId },
    })
  }, [signReport, reportId])

  return (
    <div
      className="flex flex-1 flex-col justify-between p-6 bg-white rounded-lg overflow-hidden"
      style={{ width: 420 }}
    >
      <header className="mb-4 text-center text-black text-lg font-medium">
        Signera årsräkning
      </header>
      <div>
        <div
          key="loading"
          className="inset-x-0 flex flex-col items-center mx-auto p-4 w-full text-center bg-white rounded-xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.2 }}
        >
          <Icon name="bank-id" className="mb-4 w-10 h-10" />
          <span className="block mb-2 text-black font-semibold">
            Öppna BankID
          </span>
          <span className="mb-8 max-w-xs text-gray-800">
            För att signera och skicka in, starta din BankID applikation på din
            mobila enhet.
          </span>
          <div className="mb-4">
            <Loader />
          </div>
          <div className="flex justify-between pt-6">
            <Button
              variant="tertiary"
              type="button"
              title="Avbryt"
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

SubmitReportToMunicipality.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default SubmitReportToMunicipality
