import { Switch, Route, Redirect } from 'react-router-dom'

import PremiumLayout from '@features/PremiumLayout'

import Ledger from './Ledger'
import Verifications from './Verifications'
import Transactions from './Transactions'
import Transaction from './Transaction'
import ImportLayout from './ImportLayout'

const AccountingLayout = () => (
  <PremiumLayout>
    <Switch>
      <Route
        exact
        path="/huvudman/redovisning/transaktioner"
        component={Transactions}
      />
      <Route
        path="/huvudman/redovisning/transaktioner/importera"
        component={ImportLayout}
      />
      <Route
        exact
        path="/huvudman/redovisning/transaktioner/:transactionId"
        component={Transaction}
      />
      <Route
        exact
        path="/huvudman/redovisning/bokforing"
        component={Verifications}
      />
      <Route
        exact
        path="/huvudman/redovisning/bokforing/:transactionId"
        component={Transaction}
      />
      <Route path="/huvudman/redovisning/oversikt" component={Ledger} />
      <Route
        render={() => <Redirect to="/huvudman/redovisning/transaktioner" />}
      />
    </Switch>
  </PremiumLayout>
)

export default AccountingLayout
