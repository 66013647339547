import { Logo, Icon } from '@aider/ui'

const LoggedOutLayout = ({ children }) => (
  <div className="absolute flex h-full font-sans bg-gradient-to-br from-blue-400 to-blue-600 sm:relative sm:min-h-screen">
    <div className="flex w-full max-w-xl bg-white overflow-scroll sm:rounded-br-lg sm:rounded-tr-lg sm:overflow-hidden">
      <div className="flex flex-col justify-between px-6 py-10 w-full max-w-xl sm:px-10 lg:px-12">
        {/* Header */}
        <header>
          <Logo className="h-7" />
        </header>
        {/* Main */}
        <main className="py-16 w-full">{children}</main>
        {/* Footer */}
        <footer className="pb-6 pt-6 border-t border-gray-300 sm:pb-0">
          <p className="mb-2 text-gray-800 text-base font-light">
            Genom att fortsätta godkänner jag Aider Groups Användarvillkor och
            bekräftar att jag läst Aider Groups Dataskyddsinformation.
          </p>
          <a
            href="https://intercom.help/aider/sv/collections/2708591-anvandningsvillkor-och-integritet"
            className="flex items-center text-black text-base font-medium outline-none hover:opacity-50 focus:opacity-50 active:opacity-75 space-x-2 transition-opacity duration-300"
          >
            <span>Läs mer</span>
            <Icon
              name="arrow-top-right"
              className="w-3 h-3 text-black fill-current"
            />
          </a>
        </footer>
      </div>
    </div>
  </div>
)

export default LoggedOutLayout
