import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const Pressable = ({ to, href, children, ...props }) => {
  // Decide if we should render a Link, anchor or button element
  // eslint-disable-next-line no-nested-ternary
  const Component = to ? Link : href ? 'a' : 'button'

  return (
    <Component to={to} href={href} {...props}>
      {children}
    </Component>
  )
}

Pressable.defaultProps = {
  to: '',
  href: '',
}

Pressable.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Pressable
