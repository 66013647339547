/* eslint-disable no-use-before-define */
import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn() {
          return isLoggedInVar()
        },
        isCheckingJobStatuses() {
          return isCheckingJobStatusesVar()
        },
        activePrincipalId() {
          return activePrincipalIdVar()
        },
      },
    },
    User: {
      fields: {
        transactions: relayStylePagination([
          'principalIds',
          'verified',
          'dateRange',
          'amountRange',
          'verificationAccountIds',
        ]),
        tickets: relayStylePagination(['open']),
      },
    },
    Principal: {
      fields: {
        journalEntries: relayStylePagination(['principalId']),
        reports: relayStylePagination(['principalId']),
      },
    },
    Transaction: {
      fields: {
        attachments: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    Ticket: {
      fields: {
        messages: relayStylePagination(['id']),
      },
    },
  },
})

export const isLoggedInVar = cache.makeVar(false)
export const isCheckingJobStatusesVar = cache.makeVar(false)
export const activePrincipalIdVar = cache.makeVar(
  localStorage.getItem('activePrincipalId'),
)
