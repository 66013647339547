import { useEffect, useState, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  Dropdown,
  Button,
  FilterButton,
  PieChart,
  colorData,
  Tooltip,
  Icon,
} from '@aider/ui'
import { format, startOfYear } from 'date-fns'
import { sv as locale } from 'date-fns/locale'

import { useAccountingStatistics } from '@hooks/'
import {
  LoadingContainer,
  CurrencyCell,
  YearMonthDatePicker,
} from '@components/'
import { dateToYearRange, dateToMonthRange } from '@utils/date'

import { activePrincipalIdVar } from '@/cache'

const updateObjectKeys = data => {
  if (!data) return null
  const newArr = data.map(({ name: title, ...rest }) => ({
    ...rest,
    title,
  }))
  return newArr
}

const Ledger = () => {
  const principalId = useReactiveVar(activePrincipalIdVar)
  const [date, setDate] = useState(new Date())
  const [pickerMode, setPickerMode] = useState('year')

  /**
   * Creates a date range object that is either a year or a month
   * @param {Date} d A date object
   * @returns A date range object
   */
  function getSelectedDateRange(d) {
    if (pickerMode === 'year') {
      return dateToYearRange(d)
    }
    if (pickerMode === 'month') {
      return dateToMonthRange(d)
    }

    return undefined
  }
  const dateRange = useMemo(() => getSelectedDateRange(date), [date])

  const printReport = () => {
    if (typeof window !== 'undefined') window.print()
  }

  const [
    getStatistics,
    {
      loading,
      data: {
        incomes: {
          totalValue: incomesTotal = 0,
          result: incomesResult = [],
        } = {},
        expenses: {
          totalValue: expensesTotal = 0,
          result: expensesResult = [],
        } = {},
        balance: { totalValue: balance = 0 } = {},
      } = {},
    },
  ] = useAccountingStatistics()

  useEffect(() => {
    getStatistics({ principalId, date: dateRange })
  }, [principalId, getStatistics, dateRange])

  /**
   * Reacts when the user changes the mode of the date picker
   * @param {String} e The updated picker mode, 'year' or 'month'
   */
  const handleChangePickerMode = newMode => {
    const previous = pickerMode
    setPickerMode(newMode)

    // Triggers a re-render if the mode changes
    // so that going year to month pre-selects january,
    // and going month to year pre-selects the current year.
    if (previous !== newMode) {
      setDate(startOfYear(date))
    }
  }

  return (
    <LoadingContainer>
      <div className="w-full mx-auto xl:w-9/12">
        <header className="sticky top-0 z-10 flex items-center justify-between h-20 bg-white">
          <div className="flex items-center flex-1 space-x-4">
            <div className="text-2xl font-medium">Översikt</div>
            <Dropdown.Root>
              <Dropdown.Trigger
                title={pickerMode === 'year' ? 'Period' : ''}
                value={
                  pickerMode === 'year'
                    ? format(date, 'yyyy')
                    : format(date, 'MMM yyyy', { locale })
                }
                as={FilterButton}
              />
              <Dropdown.Content align="start" sideOffset={6}>
                <YearMonthDatePicker
                  date={date}
                  mode={pickerMode}
                  onChangeDate={d => setDate(d)}
                  onChangeMode={mode => handleChangePickerMode(mode)}
                />
              </Dropdown.Content>
            </Dropdown.Root>
          </div>
          <Button title="Skriv ut" variant="secondary" onClick={printReport} />
        </header>
        <div className="pb-6 md:w-9/12">
          Här visas en översiktlig sammanställning av bokföringen för vald
          huvudman och period.
        </div>
        <LoadingContainer loading={loading}>
          <div className="mb-20">
            <div className="flex mb-10 space-x-4">
              {/* Total Incomes */}
              <div className="w-4/12 p-4 border border-gray-300 rounded-lg">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center space-x-2">
                    <div className="w-1 h-4 bg-blue-400 rounded" />
                    <div className="text-sm font-medium text-gray-800">
                      Inkomster - netto
                    </div>
                  </div>
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        name="info"
                        className="w-4 h-4 text-gray-800 fill-current"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content sideOffset={6} side="top">
                      <Tooltip.Arrow />
                      Inkomster för vald huvudman och period
                    </Tooltip.Content>
                  </Tooltip.Root>
                </div>
                <CurrencyCell
                  amount={incomesTotal}
                  className="text-xl font-medium"
                />
              </div>
              {/* Total Expenses */}
              <div className="w-4/12 p-4 border border-gray-300 rounded-lg">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center space-x-2">
                    <div className="w-1 h-4 bg-blue-400 rounded" />
                    <div className="text-sm font-medium text-gray-800">
                      Utgifter
                    </div>
                  </div>
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        name="info"
                        className="w-4 h-4 text-gray-800 fill-current"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content sideOffset={6} side="top">
                      <Tooltip.Arrow />
                      Utgifter för vald huvudman och period
                    </Tooltip.Content>
                  </Tooltip.Root>
                </div>
                <CurrencyCell
                  amount={expensesTotal}
                  className="text-xl font-medium"
                />
              </div>
              {/* Balance */}
              <div className="w-4/12 p-4 border border-gray-300 rounded-lg">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center space-x-2">
                    <div className="w-1 h-4 bg-blue-400 rounded" />
                    <div className="text-sm font-medium text-gray-800">
                      Summa
                    </div>
                  </div>
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        name="info"
                        className="w-4 h-4 text-gray-800 fill-current"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content sideOffset={6} side="top">
                      <Tooltip.Arrow />
                      Summa inkomster minus utgifter för vald period
                    </Tooltip.Content>
                  </Tooltip.Root>
                </div>
                <CurrencyCell
                  amount={balance}
                  className="text-xl font-medium"
                />
              </div>
            </div>

            {/* Incomes Section */}
            <section className="flex mb-10 space-x-12">
              <div className="w-6/12">
                <PieChart
                  data={colorData(
                    updateObjectKeys(incomesResult),
                    '#70C683',
                    '#529E68',
                  )}
                  renderTooltipContent={({ title, value }) => (
                    <div className="space-x-2 text-center">
                      <div className="font-medium text-white opacity-80">
                        {title}
                      </div>
                      <CurrencyCell
                        className="font-semibold text-white"
                        amount={value}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="w-6/12">
                <div className="mb-2 text-lg font-medium">
                  Inkomster - netto
                </div>
                <ul>
                  {incomesResult.map(({ id, name, value, tax }) => (
                    <li
                      key={id}
                      className="border-b border-gray-300 last:border-0"
                    >
                      <div className="flex justify-between py-2 space-x-3">
                        <div className="font-medium text-gray-800 truncate">
                          {name}
                        </div>
                        <CurrencyCell
                          className="font-medium text-black"
                          amount={value}
                        />
                      </div>
                      {tax > 0 && (
                        <div className="flex justify-between pb-2 space-x-3">
                          <div className="italic text-gray-700 truncate">
                            Avdragen skatt
                          </div>
                          <CurrencyCell
                            className="italic font-medium text-gray-700"
                            amount={tax}
                          />
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </section>

            {/* Incomes Section */}
            <section className="flex mb-20 space-x-12">
              <div className="w-6/12">
                <div className="mb-2 text-lg font-medium">Utgifter</div>
                <ul>
                  {expensesResult.map(({ id, name, value }) => (
                    <li
                      key={id}
                      className="flex justify-between py-2 space-x-3 border-b border-gray-300 last:border-0"
                    >
                      <div className="font-medium text-gray-800 truncate">
                        {name}
                      </div>
                      <CurrencyCell
                        className="font-medium text-black"
                        amount={value}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="w-6/12">
                <PieChart
                  data={colorData(
                    updateObjectKeys(expensesResult),
                    '#009AC8',
                    '#093442',
                  )}
                  renderTooltipContent={({ title, value }) => (
                    <div className="space-x-2 text-center">
                      <div className="font-medium text-white opacity-80">
                        {title}
                      </div>
                      <CurrencyCell
                        className="font-semibold text-white"
                        amount={value}
                      />
                    </div>
                  )}
                />
              </div>
            </section>
          </div>
        </LoadingContainer>
      </div>
    </LoadingContainer>
  )
}
export default Ledger
