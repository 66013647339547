import { useRef, useState, useMemo, useCallback, Fragment } from 'react'
import { useLocation, useHistory, useParams, Link } from 'react-router-dom'
import { Formik, Field, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import { useReactiveVar, useQuery, useMutation, gql } from '@apollo/client'
import { toast } from 'react-hot-toast'
import find from 'lodash.find'

import {
  Button,
  Select,
  Icon,
  IconButton,
  Input,
  FINANCIAL_DEFAULT_PROPS,
  TextArea,
  Dropdown,
  useModal,
} from '@aider/ui'

import {
  LoadingContainer,
  CurrencyCell,
  BalanceCell,
  FileInput,
  Attachment,
} from '@components/'
import { useUploadAttachment } from '@hooks/'
import { openInNewTab } from '@utils/open-in-new-tab'
import { activePrincipalIdVar } from '@/cache'
import { track, events } from '@utils/analytics'

import useVerificationAccounts, {
  ACCOUNT_TYPES,
} from '@hooks/useVerificationAccounts'
import Tags from '../../components/Tags'
import DeleteTransactions from './DeleteTransactions'

const balanceValues = (amount, verifications, deductions) => {
  const verificationsAmount = verifications.reduce((a, { amount: b }) => {
    const numberToAdd = typeof b === 'number' ? b : 0
    return a + numberToAdd
  }, 0)
  const deduction = deductions >= 0 ? deductions : 0
  
  return Math.round((verificationsAmount - amount - deduction) * 100) / 100
}

const validationSchema = Yup.object().shape({
  note: Yup.string(),
  attachments: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      url: Yup.string().nullable(),
      originalFilename: Yup.string().nullable(),
    }),
  ),
  verifications: Yup.array()
    .of(
      Yup.object().shape({
        amount: Yup.number().required('Fyll i ett giltigt värde'),
        taxAmount: Yup.number().nullable(),
        verificationAccount: Yup.object()
          .shape({
            value: Yup.string().required('Välj en kategori i listan'),
            label: Yup.string().required(),
          })
          .required()
          .typeError('Välj en kategori i listan'),
        tags: Yup.object().shape({
          value: Yup.string().nullable(),
          label: Yup.string().nullable(),
        }),
      }),
    )
    .min(1, 'Lägg till minst ett verifikat'),
  balance: Yup.number()
    .test(
      (v, c) =>
        balanceValues(v, c.parent.verifications, c.parent.deductionAmount) ===
        0,
    )
    .required(),
})

const TRANSACTION = gql`
  query transaction($transactionId: ID!) {
    transaction(id: $transactionId) {
      id
      date
      amount
      description
      expense
      note
      deduction
      attachments {
        id
        originalFilename
        filename
        fileExtension
        url
      }
      verifications {
        id
        amount
        tax
        taxAmount
        verificationAccount {
          id
          name
        }
        tag {
          id
          name
        }
      }
    }
  }
`

const UPSERT_TRANSACTION = gql`
  mutation upsertTransaction(
    $transactionId: ID!
    $verifications: [CreateVerificationInput!]!
    $deduction: Float
    $attachments: [ID!]
    $note: String
  ) {
    upsertTransaction(
      input: {
        id: $transactionId
        note: $note
        attachments: { sync: $attachments }
        verifications: { create: $verifications }
        deduction: $deduction
      }
    ) {
      id
      description
      verifications {
        id
        verificationAccount {
          id
        }
        tag {
          id
          name
        }
      }
    }
  }
`

const EMPTY_VERIFICATION_ROW = {
  verificationAccount: null,
  amount: null,
  taxAmount: null,
  tag: null,
}

const Transaction = () => {
  const { pathname } = useLocation()
  const { transactionId } = useParams()
  const principalId = useReactiveVar(activePrincipalIdVar)
  const history = useHistory()
  const formRef = useRef()
  const [uploadAttachment, { loading: uploadingAttachment }] =
    useUploadAttachment()
  const {
    openModal: openDeleteTransactionsModal,
    closeModal: closeDeleteTransactionsModal,
    isOpen: deleteTransactionsOpen,
    Modal: DeleteTransactionsModal,
  } = useModal()

  // Get url for the "back" button, can be /transaktioner or /bokforing
  const goBackUrl = useMemo(
    () => pathname.substring(-1, pathname.lastIndexOf('/')),
    [pathname],
  )

  const [showNote, setShowNote] = useState(false)
  const [initialValues, setInitialValues] = useState({
    note: '',
    attachments: [],
    amount: 0,
    verifications: [],
  })

  /**
   * Get transaction and update initial form state
   */
  const {
    data: { transaction: { description, amount, date, expense } = {} } = {},
    loading: loadingTransaction,
  } = useQuery(TRANSACTION, {
    variables: { transactionId },
    skip: !transactionId,
    onCompleted: useCallback(({ transaction = {} }) => {
      // Get verifications from response. If there are no verifications,
      // add an empty row with the transaction amount prepopulated.
      const initialVerifications =
        Array.isArray(transaction.verifications) &&
        transaction.verifications.length
          ? transaction.verifications.map(v => ({
              amount: v.amount,
              taxAmount: v.taxAmount || '',
              verificationAccount: v?.verificationAccount
                ? {
                    value: v.verificationAccount.id,
                    label: v.verificationAccount.name,
                  }
                : null,
              tag: v?.tag
                ? {
                    value: v.tag.id,
                    label: v.tag.name,
                  }
                : null,
            }))
          : [{ ...EMPTY_VERIFICATION_ROW, amount: transaction.amount }]

      // Update initial form state
      setInitialValues({
        note: transaction.note || '',
        attachments: transaction.attachments,
        verifications: initialVerifications,
        // balance is needed in Formik context to validate balance
        balance: transaction.amount || 0,
        deductionAmount: transaction.deduction || 0,
      })

      // Show note field if note is set
      if (typeof transaction.note === 'string' && transaction.note.length > 0) {
        setShowNote(true)
      }
    }, []),
    onError: useCallback(() => {
      toast.error('Kunde inte hämta transaktionen')
    }, []),
  })

  /**
   * Get verification accounts based on transactions expense propert
   */
  const {
    data: { verificationAccounts = [] } = {},
    loading: loadingVerificationAccounts,
  } = useVerificationAccounts({
    skip: typeof expense !== 'boolean',
    variables: {
      accountTypes: expense ? ACCOUNT_TYPES.expenses : ACCOUNT_TYPES.incomes,
    },
  })

  /**
   * Upsert transaction mutation
   */
  const [upsertTransaction, { loading: upsertingTransaction }] = useMutation(
    UPSERT_TRANSACTION,
    {
      onCompleted: useCallback(() => {
        history.push(goBackUrl)
        toast.success('Transaktionen bokfördes!')
      }, [goBackUrl, history]),
      onError: useCallback(() => {
        toast.error('Kunde inte bokföra transaktionen')
      }, []),
    },
  )

  /**
   * Handle form submit
   */
  const handleSubmit = async values => {
    const attachments = values.attachments.map(({ id }) => id)

    const verifications = values.verifications.map(verification => ({
      amount: verification.amount,
      taxAmount: verification.taxAmount || undefined,
      verificationAccountId: verification.verificationAccount.value,
      tagId: verification?.tag?.value || undefined,
    }))

    const deduction = values.deductionAmount || 0

    await upsertTransaction({
      variables: {
        transactionId,
        verifications,
        attachments,
        deduction,
        note: values.note || undefined,
      },
    })

    track(events.USER_VERIFIED_TRANSACTION)
  }

  return (
    <LoadingContainer
      loading={loadingTransaction || loadingVerificationAccounts}
    >
      <div className="mx-auto w-full lg:w-11/12 xl:w-10/12">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnBlur
          onSubmit={handleSubmit}
        >
          {({
            values,
            setFieldValue,
            handleBlur,
            handleChange,
            isValid,
            errors,
            touched,
          }) => {
            const balance = balanceValues(
              values.balance,
              values.verifications,
              values.deductionAmount,
            )
            const firstVerificationAccount = null
            return (
              <Form>
                <header className="sticky z-10 top-0 flex items-center justify-between mb-4 py-4 bg-white space-x-6">
                  <div className="flex items-center space-x-3 truncate">
                    <Link
                      to={goBackUrl}
                      className="text-blue-500 text-2xl font-medium hover:opacity-50 transition-opacity"
                    >
                      ...
                    </Link>
                    <Icon
                      name="chevron-right"
                      className="flex-shrink-0 w-2.5 h-2.5 text-black fill-current"
                    />
                    <div className="text-2xl font-medium truncate">
                      {description}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Dropdown.Root>
                      <Dropdown.Trigger
                        as={IconButton}
                        icon={<Icon name="options" className="w-4 h-4" />}
                      />
                      <Dropdown.Content>
                        <Dropdown.Item onSelect={openDeleteTransactionsModal}>
                          <span className="text-red">Ta bort transaktion</span>
                        </Dropdown.Item>
                      </Dropdown.Content>
                    </Dropdown.Root>
                    <Button
                      title="Spara"
                      type="submit"
                      isLoading={upsertingTransaction}
                      disabled={
                        !isValid || uploadingAttachment || upsertingTransaction
                      }
                    />
                  </div>
                </header>
                <div className="flex mb-6 p-4 bg-blue-500 rounded-lg space-x-8">
                  <div className="flex flex-col w-4/12">
                    <span className="text-white text-sm font-medium opacity-70">
                      Beskrivning
                    </span>
                    <span className="text-white text-lg font-medium truncate">
                      {description}
                    </span>
                  </div>
                  <div className="flex flex-col w-4/12">
                    <span className="text-white text-sm font-medium opacity-70">
                      Nettobelopp
                    </span>
                    <CurrencyCell
                      amount={amount}
                      className="text-white text-lg font-medium"
                    />
                  </div>
                  <div className="flex flex-col w-4/12">
                    <span className="text-white text-sm font-medium opacity-70">
                      Datum
                    </span>
                    <span className="text-white text-lg font-medium">
                      {date}
                    </span>
                  </div>
                </div>
                {/* Verifications */}
                <FieldArray name="verifications">
                  {({ remove, push }) => (
                    <Fragment>
                      <header className="flex items-center justify-between mb-4 h-16 border-b border-gray-300">
                        <div className="text-lg font-medium">Kontering</div>
                        <button
                          type="button"
                          className="text-blue-600 font-medium focus:outline-none hover:opacity-75 transition-opacity"
                          onClick={() => push(EMPTY_VERIFICATION_ROW)}
                        >
                          Ny rad
                        </button>
                      </header>
                      <div className="grid gap-4 grid-flow-col mb-2 pr-12 w-full auto-cols-fr">
                        <div className="w-full text-black text-sm font-medium">
                          Kategori
                        </div>
                        <div className="w-full text-black text-sm font-medium">
                          Nettobelopp
                        </div>
                        {!expense && (
                          <div className="w-full text-black text-sm font-medium">
                            Avdragen skatt
                          </div>
                        )}
                        {values.verifications[0]?.verificationAccount && (
                          <div className="w-full text-black text-sm font-medium">
                            Tagg
                          </div>
                        )}
                      </div>
                      {values.verifications.map((value, index) => {
                        const field = `verifications.${index}`
                        return (
                          <div className="flex mb-4 space-x-4" key={field}>
                            <div className="grid gap-4 grid-flow-col w-full auto-cols-fr">
                              <Field
                                as={Select}
                                id={`${field}.verificationAccount`}
                                name={`${field}.verificationAccount`}
                                value={value.verificationAccount}
                                placeholder="Välj kategori"
                                options={verificationAccounts.map(
                                  ({ name, id }) => ({
                                    value: id,
                                    label: name,
                                  }),
                                )}
                                required
                              />
                              <Field
                                {...FINANCIAL_DEFAULT_PROPS}
                                as={Input}
                                id={`${field}.amount`}
                                name={`${field}.amount`}
                                placeholder="Nettobelopp"
                                onValueChange={({ floatValue }) => {
                                  setFieldValue(`${field}.amount`, floatValue)
                                }}
                                onBlur={handleBlur}
                                required
                              />
                              {!expense && (
                                <Field
                                  {...FINANCIAL_DEFAULT_PROPS}
                                  as={Input}
                                  id={`${field}.taxAmount`}
                                  name={`${field}.taxAmount`}
                                  placeholder="0 kr"
                                  onValueChange={({ floatValue }) => {
                                    setFieldValue(
                                      `${field}.taxAmount`,
                                      floatValue,
                                    )
                                  }}
                                  onBlur={handleBlur}
                                />
                              )}
                              {/* Tags */}
                              {value.verificationAccount?.value && (
                                <Tags
                                  field={field}
                                  value={value}
                                  setFieldValue={setFieldValue}
                                  principalId={principalId}
                                  verificationAccountId={
                                    value.verificationAccount.value
                                  }
                                />
                              )}
                            </div>
                            <div className="flex items-center w-auto">
                              <IconButton
                                onClick={() =>
                                  values.verifications.length > 1
                                    ? remove(index)
                                    : null
                                }
                                icon={<Icon name="trash" className="w-4 h-4" />}
                              />
                            </div>
                          </div>
                        )
                      })}
                      <div className="mt-8">
                        <div>
                          <div className="w-full max-w-lg text-black text-sm font-medium mb-2 flex flex-col space-y-1">
                            <span>Utmätningar och avdrag</span>
                            <span className="text-gray-500">
                              Här redovisar du{' '}
                              <span className="italic">
                                utmätningar och andra avdrag
                              </span>{' '}
                              som belastar inkomstposten. Beloppet redovisas som
                              en utgift; i bokföringen, översikter och
                              rapporter.
                            </span>
                          </div>
                          <Field
                            {...FINANCIAL_DEFAULT_PROPS}
                            as={Input}
                            id="deductionAmount"
                            name="deductionAmount"
                            className="max-w-xs"
                            placeholder="0 kr"
                            onValueChange={({ floatValue }) => {
                              setFieldValue(
                                `deductionAmount`,
                                Math.abs(floatValue),
                              )
                            }}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-8 pb-4 pt-4 mt-4 border-b border-gray-300 space-x-4">
                        <div className="flex items-center space-x-1">
                          <div className="text-black font-medium">Balans:</div>
                          <BalanceCell value={balance} />
                        </div>
                        {Array.isArray(errors.verifications) && (
                          <span className="text-red">
                            Fyll i kategori och belopp för varje rad
                          </span>
                        )}
                      </div>
                    </Fragment>
                  )}
                </FieldArray>
                {/* Attachments */}
                <div className="mb-8">
                  <header className="flex items-center justify-between h-16 border-b border-gray-300">
                    <div className="text-lg font-medium">Bilagor</div>
                    <FileInput
                      title="Ladda upp"
                      loading={uploadingAttachment}
                      onChange={async e => {
                        try {
                          const {
                            data: { createAttachment: attachment } = {},
                          } = await uploadAttachment({
                            variables: {
                              file: e.target.files[0],
                              principalId,
                            },
                          })
                          setFieldValue('attachments', [
                            ...values.attachments,
                            attachment,
                          ])
                        } catch (error) {
                          toast.error('Kunde inte ladda upp bilagan')
                        }
                      }}
                    />
                  </header>
                  <ul>
                    {values.attachments.map(
                      ({
                        id: attachmentId,
                        url,
                        filename,
                        originalFilename,
                        fileExtension,
                      }) => (
                        <li
                          key={attachmentId}
                          className="flex justify-between py-4 border-b border-gray-300"
                        >
                          <Attachment
                            fileType={fileExtension}
                            fileName={originalFilename || filename}
                          />
                          <Dropdown.Root>
                            <Dropdown.Trigger
                              as={IconButton}
                              icon={<Icon name="options" className="w-4 h-4" />}
                            />
                            <Dropdown.Content>
                              <Dropdown.Item onSelect={() => openInNewTab(url)}>
                                Visa bilaga
                              </Dropdown.Item>
                              <Dropdown.Item
                                onSelect={() => {
                                  setFieldValue(
                                    'attachments',
                                    values.attachments.filter(
                                      ({ id }) => id !== attachmentId,
                                    ),
                                  )
                                }}
                              >
                                <span className="text-red">Ta bort</span>
                              </Dropdown.Item>
                            </Dropdown.Content>
                          </Dropdown.Root>
                        </li>
                      ),
                    )}
                  </ul>
                </div>
                {/* Note */}
                <div className="mb-12">
                  <header className="flex items-center justify-between h-16">
                    <div className="text-lg font-medium">Notering</div>
                    <button
                      type="button"
                      className="text-blue-600 font-medium focus:outline-none hover:opacity-75 transition-opacity"
                      onClick={() => {
                        setShowNote(!showNote)
                        setFieldValue('note', '')
                      }}
                    >
                      {showNote ? 'Ta bort' : 'Lägg till'}
                    </button>
                  </header>
                  {showNote && (
                    <div>
                      <TextArea
                        id="note"
                        name="note"
                        rows={5}
                        placeholder="Beskriv transaktionen"
                        value={values.note}
                        error={touched.note && errors.note}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  )}
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      <DeleteTransactionsModal visible={deleteTransactionsOpen}>
        <DeleteTransactions
          onCancel={closeDeleteTransactionsModal}
          onSuccess={() => {
            toast.success('Transaktionen togs bort')
            closeDeleteTransactionsModal()
            history.goBack()
          }}
          transactionIds={[transactionId]}
        />
      </DeleteTransactionsModal>
    </LoadingContainer>
  )
}

export default Transaction
