import { useCallback, useState, useMemo, Fragment } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'
import { Button, Table, Icon } from '@aider/ui'
import { toast } from 'react-hot-toast'

import {
  LoadingContainer,
  CurrencyCell,
  DateCell,
  EmptyState,
} from '@components/'
import EmptyTransactions from '@assets/images/empty_transactions.png'

const IMPORT = gql`
  query import($importId: ID!) {
    import(id: $importId) {
      id
      status
      importTransactions(orderBy: { order: DESC, column: DATE }) {
        id
        amount
        description
        date
        duplicate
      }
    }
  }
`

const IMPORT_TRANSACTIONS = gql`
  mutation importTransactions($transactionIds: [ID!]!) {
    importTransactions(ids: $transactionIds) {
      id
    }
  }
`

const SelectTransactions = () => {
  const { importId } = useParams()
  const history = useHistory()
  const [transactions, setTransactions] = useState([])
  const [selectedTransactionIds, setSelectedTransactionIds] = useState([])

  const goToTransactions = useCallback(() => {
    history.push('/huvudman/redovisning/transaktioner')
  }, [history])

  const { data: { import: { status } = {} } = {}, loading: loadingImport } =
    useQuery(IMPORT, {
      skip: !importId,
      fetchPolicy: 'network-only',
      variables: { importId },
      onCompleted: useCallback(
        ({ import: { importTransactions = [] } = {} }) => {
          // Add 'defaultChecked' property to transactions
          // uncheck transactions that are duplicates
          const prepared = importTransactions.map(transaction => ({
            ...transaction,
            defaultChecked: !transaction.duplicate,
          }))
          setTransactions(prepared)
        },
        [],
      ),
      onError: useCallback(() => {
        toast.error('Kunde inte hämta importen')
      }, []),
    })

  const [importTransactions] = useMutation(IMPORT_TRANSACTIONS, {
    onCompleted: useCallback(() => {
      goToTransactions()
    }, [goToTransactions]),
  })

  const handleImportTransactions = () => {
    importTransactions({
      variables: { transactionIds: selectedTransactionIds },
    })
  }

  const handleSelectedRowsChange = useCallback(selected => {
    setSelectedTransactionIds(selected.map(({ original: { id } = {} }) => id))
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Transaktion',
        accessor: 'description',
        Cell: ({ value }) => <span className="font-medium">{value}</span>,
      },
      {
        Header: 'Belopp',
        accessor: 'amount',
        Cell: ({ value }) => (
          <div className="text-black font-medium truncate">
            <CurrencyCell amount={value} />
          </div>
        ),
      },
      {
        Header: 'Datum',
        accessor: 'date',
        Cell: ({ value }) => (
          <div className="text-black font-medium truncate">
            <DateCell date={value} />
          </div>
        ),
      },
      {
        Header: 'Information',
        accessor: 'duplicate',
        Cell: ({ value }) => (
          <span className="font-medium">
            {value && (
              <span className="flex items-center space-x-1">
                <Icon name="alert" className="w-4 h-4 text-red fill-current" />
                <span>Risk för dubblett</span>
              </span>
            )}
          </span>
        ),
      },
    ],
    [],
  )

  return (
    <LoadingContainer loading={loadingImport}>
      {status !== 'IMPORTED' ? (
        <Fragment>
          <div className="flex items-center justify-between mb-8 p-4 bg-blue-800 rounded-lg">
            <span className="text-white font-medium">
              {selectedTransactionIds.length} av {transactions.length}{' '}
              transaktioner markerade för import
            </span>
            <div className="space-x-2">
              <Button
                type="button"
                variant="secondary"
                title="Avbryt"
                onClick={goToTransactions}
              />
              <Button
                type="button"
                variant="primary"
                title="Bekräfta import"
                onClick={handleImportTransactions}
                disabled={!selectedTransactionIds.length}
              />
            </div>
          </div>
          <div className="mb-20">
            <Table
              columns={columns}
              data={transactions}
              onSelectedRowsChange={handleSelectedRowsChange}
              selectable
            />
          </div>
        </Fragment>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center">
          <EmptyState
            icon={
              <img
                className="mb-2 w-16 h-auto"
                src={EmptyTransactions}
                alt="transaktioner"
              />
            }
            title="Transaktionerna har redan importerats"
            description="Importerade transaktioner hittar du under Redovisning > Transaktioner."
            action={
              <Button
                title="Gå till transaktioner"
                variant="secondary"
                onClick={goToTransactions}
              />
            }
          />
        </div>
      )}
    </LoadingContainer>
  )
}

export default SelectTransactions
