import { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'

import { format, formatDistanceToNow } from '@/utils/date'

const USER_SUBSCRIPTION = gql`
  query userSubscription {
    me {
      id
      subscribed
      onTrial
      trialEndsAt
      sub {
        active
        cancelled
        ended
        incomplete
        endsAt
        nextPaymentDate
      }
    }
  }
`

/**
 * Returns a human friendly explaination of their subscription status
 * @return {String}
 */
const useSubscriptionStatus = () => {
  const [status, set] = useState()

  const {
    data: { me: { onTrial, trialEndsAt, sub } = {} } = {},
    loading,
    error,
  } = useQuery(USER_SUBSCRIPTION)

  useEffect(() => {
    ;(() => {
      if (typeof sub === 'object' && sub !== null) {
        if (sub.ended) {
          return set('Aider Standard')
        }
        if (sub.cancelled) {
          return set(
            `Aider premium • Avslutas ${format(sub.endsAt, 'yyyy-MM-dd')}`,
          )
        }
        if (sub.incomplete) {
          return set('Aider premium • Din betalning gick inte igenom')
        }
        if (sub.active) {
          return set(
            `Aider premium • Förnyas automatiskt ${format(
              sub.nextPaymentDate,
              'yyyy-MM-dd',
            )}`,
          )
        }
      }

      if (onTrial) {
        return set(
          `Aider Premium • Provperiod löper ut ${formatDistanceToNow(
            trialEndsAt,
          )}`,
        )
      }

      if (!onTrial && typeof trialEndsAt === 'string') {
        return set('Aider Standard')
      }

      return set('Aider Standard')
    })()
  }, [onTrial, trialEndsAt, sub])

  return { status, loading, error }
}

export default useSubscriptionStatus
