import { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, useModal } from '@aider/ui'
import { gql, useReactiveVar, useQuery } from '@apollo/client'
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion'
import { toast } from 'react-hot-toast'

import { EmptyState, LoadingContainer } from '@components/'
import { useEventEmitter, events } from '@hooks/useEventEmitter'
import EmptyTransactions from '@assets/images/empty_transactions.png'
import { activePrincipalIdVar } from '@/cache'

import CreateReport from './CreateReport'
import DeleteReport from './DeleteReport'

const REPORTS = gql`
  query reports($principalId: ID!, $after: String) {
    principal(id: $principalId) {
      id
      name
      reports(
        after: $after
        first: 50
        orderBy: { column: UPDATED_AT, order: DESC }
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            from
            to
            signed
            duplicate
            organization {
              id
              name
            }
          }
        }
      }
    }
  }
`

const Reports = () => {
  const history = useHistory()
  const eventEmitter = useEventEmitter()
  const principalId = useReactiveVar(activePrincipalIdVar)
  const [deleteReportId, setDeleteReportId] = useState(null)

  // Create report modal
  const {
    openModal: openCreateReportModal,
    closeModal: closeCreateReportModal,
    isOpen: createReportOpen,
    Modal: CreateReportModal,
  } = useModal()

  // Delete report modal
  const {
    openModal: openDeleteReportModal,
    closeModal: closeDeleteReportModal,
    isOpen: deleteReportOpen,
    Modal: DeleteReportModal,
  } = useModal({
    onClose: useCallback(() => {
      setDeleteReportId(null)
    }, []),
  })

  // Get reports by principalId
  const {
    data: {
      principal: {
        reports: {
          pageInfo: { endCursor, hasNextPage } = {},
          edges: reports = [],
        } = {},
      } = {},
    } = {},
    loading: loadingReports,
    fetchMore,
  } = useQuery(REPORTS, {
    variables: { principalId },
  })

  useEffect(() => {
    const unsubscribe = eventEmitter.on(events.CONTENT_SCROLL_BOTTOM, () => {
      if (!hasNextPage || loadingReports) return
      fetchMore({ variables: { after: endCursor } })
    })

    return () => unsubscribe()
  }, [eventEmitter, fetchMore, loadingReports, hasNextPage, endCursor])

  const handleOpenDeleteReportModal = (e, reportId) => {
    setDeleteReportId(reportId)
    openDeleteReportModal()
  }

  const goToReport = useCallback(
    reportId => {
      history.push(`/huvudman/rapporter/${reportId}`)
    },
    [history],
  )

  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <LoadingContainer loading={loadingReports}>
      <div className="mx-auto w-full xl:w-9/12">
        <header className="sticky z-10 top-0 flex items-center justify-between h-20 bg-white">
          <div className="text-2xl font-medium">Rapporter</div>
          <div className="space-x-3">
            <Button
              title="Ny rapport"
              variant="primary"
              onClick={openCreateReportModal}
            />
          </div>
        </header>
        <div className="md:w-8/12 pb-6">
          Här skapar du års- och sluträkningar som du har att redovisa till
          överförmyndaren. Du kan även skapa egna rapporter för valfri
          tidsperiod, t.ex. månad, kvartal eller halvår.
        </div>
        {Array.isArray(reports) && reports.length ? (
          <AnimateSharedLayout>
            <motion.ul layout>
              <AnimatePresence initial={false}>
                {reports.map(
                  ({
                    node: {
                      id,
                      name,
                      from,
                      to,
                      duplicate,
                      organization,
                      signed,
                    } = {},
                  }) => (
                    <motion.li
                      initial={{ opacity: 0, x: 50 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 50 }}
                      layout
                      key={id}
                    >
                      <div className="flex items-center justify-between py-6 border-b border-gray-300">
                        <div className="flex-1">
                          <div className="font-medium leading-tight flex items-center gap-1 relative w-full">
                            {name}
                            {signed && (
                              <div className="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  className="w-5 h-5 mt-0.5 text-blue-500"
                                  onMouseEnter={() => setShowTooltip(true)}
                                  onMouseLeave={() => setShowTooltip(false)}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                                  />
                                </svg>
                                {showTooltip && (
                                  <div className="absolute w-auto bg-gray-100 border border-gray-300 shadow-md left-8 -top-8 px-3 py-1 rounded-full">
                                    <p className="text-gray-800 font-medium text-sm">
                                      This report was signed and submitted
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="text-gray-800 leading-tight">
                            {from} - {to}
                            <br />
                            {duplicate && organization && (
                              <span>
                                Inskickad till {organization.name} kommun
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="space-x-4">
                          <button
                            type="button"
                            className="text-blue-600 font-medium focus:outline-none hover:opacity-75 transition-opacity"
                            onClick={() => goToReport(id)}
                          >
                            Redigera
                          </button>
                          <button
                            type="button"
                            className="text-red font-medium focus:outline-none hover:opacity-75 transition-opacity disabled:cursor-not-allowed  disabled:opacity-100 disabled:text-gray-500"
                            onClick={e => handleOpenDeleteReportModal(e, id)}
                            disabled={duplicate && organization}
                          >
                            Radera
                          </button>
                        </div>
                      </div>
                    </motion.li>
                  ),
                )}
              </AnimatePresence>
            </motion.ul>
          </AnimateSharedLayout>
        ) : (
          <div className="absolute inset-0 flex items-center justify-center">
            <EmptyState
              icon={
                <img
                  className="mb-2 w-16 h-auto"
                  src={EmptyTransactions}
                  alt="transaktioner"
                />
              }
              title="Vi hittade inga rapporter"
              description="Rapporter du skapar dyker upp här."
              action={
                <Button
                  title="Ny rapport"
                  variant="secondary"
                  onClick={openCreateReportModal}
                  disabled={!principalId}
                />
              }
            />
          </div>
        )}
      </div>
      <CreateReportModal visible={createReportOpen}>
        <CreateReport
          onSuccess={reportId => {
            closeCreateReportModal()
            goToReport(reportId)
          }}
          onError={() => toast.error('Kunde inte skapa rapporten')}
          onCancel={closeCreateReportModal}
        />
      </CreateReportModal>
      <DeleteReportModal visible={deleteReportOpen}>
        <DeleteReport
          reportId={deleteReportId}
          onSuccess={() => {
            closeDeleteReportModal()
          }}
          onError={() => toast.error('Kunde inte ta bort rapporten')}
          onCancel={closeDeleteReportModal}
        />
      </DeleteReportModal>
    </LoadingContainer>
  )
}

export default Reports
