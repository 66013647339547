import to from 'await-to-fetch'

const defaultOpts = {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

const call = async (path, opts) =>
  to(
    fetch(process.env.REACT_APP_AIDER_API_URL + path, {
      ...defaultOpts,
      body: JSON.stringify(opts.body),
    }),
  )

/**
 * Get the correspending RF* message by hintCode.
 * @param  {String} A hintCode from BankID
 * @return {String} A human friendly error message :-)
 * @see Recommended User Messages chapter in https://www.bankid.com/assets/bankid/rp/bankid-relying-party-guidelines-v3.4.pdf
 */
const getMessageFromHintCode = hintCode => {
  if (hintCode === 'expiredTransaction') {
    return 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din Bank. Försök sedan igen.'
  }
  if (hintCode === 'certificateErr') {
    return 'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.'
  }
  if (hintCode === 'userCancel') {
    return 'Åtgärden avbruten.'
  }
  if (hintCode === 'cancelled') {
    return 'Åtgärden avbruten. Försök igen.'
  }
  if (hintCode === 'startFailed') {
    return 'Misslyckades att läsa av QR koden. Starta BankID-appen och läs av QR koden. Kontrollera att BankID-appen är uppdaterad. Om du inte har BankID-appen måste du installera den och hämta ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.'
  }
  return 'Okänt fel. Försök igen.'
}

/**
 * Requests authentication with BankID, waits for a response and returns an auth token.
 * @param  {Object}  params   Parameters to pass to auth call
 * @param  {Boolean} register Pass true if to call the register path
 * @return {Promise}          A promise that rejects with an Error or resolves with a token object
 */
const authWithBankId = async (params, register = false) => {
  // Use different paths for registration and authentication
  const authPath = `/api/bankid/${register ? 'register' : 'auth/trustee'}`
  const collectPath = `/api/bankid/${register ? 'register/collect' : 'collect'}`

  const [authErr, authRes] = await call(authPath, { body: params })

  if (authErr) throw new Error('Kunde inte nå BankID. Försök igen.')

  const { orderRef } = await authRes.json()

  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const [collectErr, collectRes] = await call(collectPath, {
      body: { orderRef, email: register ? params?.email : undefined, inviteCode: register ? params?.inviteCode : undefined },
    })

    if (collectErr) {
      throw new Error('Kunde inte nå BankID. Försök igen.')
    }

    // eslint-disable-next-line no-await-in-loop
    const response = await collectRes.json()

    if (response.status === 'failed') {
      throw new Error(getMessageFromHintCode(response.hintCode))
    }

    if (response.status === 'complete') {
      return response
    }

    // eslint-disable-next-line no-await-in-loop
    await new Promise(resolve => setTimeout(resolve, 2500))
  }
}

export default authWithBankId
