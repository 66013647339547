const DrivingJournalItem = props => (
  <div className="flex items-start justify-between py-4 space-x-2 font-medium print:py-2">
    <div className="flex items-center space-x-1 print:text-xs">
      <span>{props.item.from}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-4 h-4 print:h-3 print:w-3"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
      </svg>
      <span>{props.item.to}</span>
    </div>
    <div className="flex items-start justify-end space-x-6 print:text-xs">
      <span className="flex-1 text-right text-gray-600 overview-line-detail" dangerouslySetInnerHTML={{__html: props.item.description}}/>
      {/* TODO - Format the date properly */}
      <span className="flex-shrink-0 text-gray-600">{props.item.date}</span>
      <span className="w-14 print:w-12">
        {props.item.distance}&nbsp;km
      </span>
    </div>
  </div>
)

export default DrivingJournalItem
