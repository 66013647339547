import amplitude from 'amplitude-js'

// Available tracking options to disable:
// https://docs.expo.io/versions/latest/sdk/amplitude/#amplitudesettrackingoptionsasynctrackingoptions
const TRACKING_OPTIONS = {
  ip_address: false,
}

export const events = {
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  USER_SENT_TICKET_MESSAGE: 'USER_SENT_TICKET_MESSAGE',
  USER_CREATED_TICKET: 'USER_CREATED_TICKET',
  USER_VERIFIED_TRANSACTION: 'USER_VERIFIED_TRANSACTION',
  USER_CREATED_JOURNAL_ENTRY: 'USER_CREATED_JOURNAL_ENTRY',
  USER_CREATED_DRIVING_LOG_ENTRY: 'USER_CREATED_DRIVING_LOG_ENTRY',
  USER_CREATED_PRINCIPAL: 'USER_CREATED_PRINCIPAL',
  USER_DELETED_PRINCIPAL: 'USER_DELETED_PRINCIPAL',
  USER_CONNECTED_BANK_ACCOUNT: 'USER_CONNECTED_BANK_ACCOUNT',
  USER_SELECTED_BANK_PROVIDER: 'USER_SELECTED_BANK_PROVIDER',
}

const canUseAmplitude = process.env.NODE_ENV !== 'development'
const amplitudeInstance = amplitude.getInstance()
let isInitialized = false

export const initialize = () => {
  if (isInitialized) return

  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
    TRACKING_OPTIONS,
  })

  // amplitude.setTrackingOptions(TRACKING_OPTIONS)

  isInitialized = true
}

export const identify = (id, options) => {
  initialize()

  if (!canUseAmplitude) return

  if (id) {
    amplitudeInstance.setUserId(id)

    if (options) {
      amplitudeInstance.setUserProperties(options)
    }
  } else {
    amplitudeInstance.clearUserProperties()
  }
}

export const track = (event, options) => {
  initialize()
  if (!canUseAmplitude) return

  if (options) {
    amplitudeInstance.logEventWithProperties(event, options)
  } else {
    amplitudeInstance.logEvent(event)
  }
}

const analytics = {
  events,
  initialize,
  identify,
  track,
}

export default analytics
