import { IconButton, Icon } from '@aider/ui'

const FormGroup = ({
  title,
  description,
  supervised,
  onDelete,
  children,
  ...props
}) => (
  <div
    className="flex flex-col mb-4 p-3 border border-gray-300 rounded-xl"
    {...props}
  >
    {title || description || onDelete ? (
      <div className="flex items-start justify-between">
        <div className="flex flex-col mb-3">
          {title ? (
            <span className="font-medium">
              {title}{' '}
              {supervised && (
                <span className="ml-1 text-xs py-0.5 px-1 rounded tracking-wide bg-gray-300 text-gray-600 uppercase">
                  Överförmyndarspärr
                </span>
              )}{' '}
            </span>
          ) : null}
          {description ? (
            <span className="text-gray-700 text-sm">{description}</span>
          ) : null}
        </div>
        {typeof onDelete === 'function' && (
          <IconButton
            onClick={onDelete}
            icon={<Icon name="trash" className="w-4 h-4" />}
          />
        )}
      </div>
    ) : null}
    <div className="flex flex-row space-x-4">{children}</div>
  </div>
)

export default FormGroup
