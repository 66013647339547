import { Switch, Route, Redirect } from 'react-router-dom'

import Journal from './Journal'
import JournalLedger from './JournalLedger'

const JournalLayout = () => (
    <Switch>
      <Route exact path="/huvudman/dagbok/inlagg" component={Journal} />
      <Route exact path="/huvudman/dagbok/oversikt" component={JournalLedger} />
      <Route render={() => <Redirect to="/huvudman/dagbok/inlagg" />} />
    </Switch>
)

  export default JournalLayout
