import { Datepicker } from '@aider/ui'

const YearMonthDatePicker = ({ date, onChangeDate, onChangeMode, mode }) => (
    <div className="flex flex-col items-center bg-gray-200">
      <span className="relative z-0 inline-flex w-auto my-2 rounded-md shadow-sm">
        <button
          type="button"
          onClick={() => onChangeMode('year')}
          className={`relative inline-flex items-center px-4 py-1 text-sm font-medium border rounded-l-md hover:bg-gray-50 focus:z-10 focus:outline-none ${
            mode === 'year'
              ? ' text-white bg-blue-500 border-blue-600'
              : '  text-gray-700 bg-white border-gray-300'
          }`}
        >
          År
        </button>
        <button
          type="button"
          onClick={() => onChangeMode('month')}
          className={`relative inline-flex items-center px-4 py-1 text-sm font-medium border rounded-r-md hover:bg-gray-50 focus:z-10 focus:outline-none ${
            mode === 'year'
              ? ' text-gray-700 bg-white border-gray-300'
              : ' text-white bg-blue-500 border-blue-600'
          }`}
        >
          Månader
        </button>
      </span>
      {mode === 'year' && (
        <Datepicker
          selected={date}
          startDate={date}
          onChange={d => onChangeDate(d)}
          showYearPicker
          inline
        />
      )}
      {mode === 'month' && (
        <Datepicker
          selected={date}
          startDate={date}
          onChange={d => onChangeDate(d)}
          showMonthYearPicker
          showFullMonthYearPicker
          inline
        />
      )}
    </div>
  )

export default YearMonthDatePicker
