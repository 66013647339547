import { useEffect } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { createBrowserHistory } from 'history'
import { gql, useApolloClient, useReactiveVar } from '@apollo/client'
import { createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'
import { AnimatePresence } from 'framer-motion'
import { Toaster } from 'react-hot-toast'
import { Icon } from '@aider/ui'

import AppLayout from '@features/AppLayout'
import DownloadReport from '@features/reports/DownloadReport'
import DownloadPersonalDataExport from '@features/reports/DownloadPersonalDataExport'
import PrincipalLayout from '@features/PrincipalLayout'
import TicketsLayout from '@features/tickets/TicketsLayout'
import Login from '@features/login/Login'
import Register from '@features/login/Register'
import LoginLayout from '@features/login/LoginLayout'
import Settings from '@features/settings/Settings'
import Principal from '@features/settings/Principal'

import { identify } from '@utils/analytics'
import { intercom } from '@utils/intercom'
import { isLoggedInVar } from '@/cache'

const GlobalStyle = createGlobalStyle`
  body,
  html {
    ${tw`absolute inset-0 overflow-hidden print:overflow-visible print:static`}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .wrapper {
    max-width:1440px;
    ${tw`px-6`}
  }
  .overview-line-detail ul {
    list-style:disc;
  }
  .overview-line-detail h1 {
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: -0.025em;
  }
`

const USER_DETAILS = gql`
  query userDetails {
    me {
      id
      name
      email
    }
  }
`

export const history = createBrowserHistory()

const App = () => {
  const client = useApolloClient()
  const isLoggedIn = useReactiveVar(isLoggedInVar)

  useEffect(() => {
    ;(async () => {
      if (isLoggedIn) {
        const { data: { me: { id, name, email } = {} } = {}, error } =
          await client.query({ query: USER_DETAILS })

        if (error) return

        // Identify user in Amplitude
        identify(id)

        // Update user in Intercom
        intercom('update', {
          name,
          email,
          user_id: id,
        })
      }
    })()
  }, [client, isLoggedIn])

  return (
    <Router history={history}>
      <GlobalStyle />
      {isLoggedIn ? (
        <Route
          render={({ location }) => (
            <AppLayout>
              <AnimatePresence exitBeforeEnter initial={false}>
                <Switch
                  location={location}
                  key={location.pathname.split('/')[1]}
                >
                  <Route path="/huvudman" component={PrincipalLayout} />
                  <Route path="/meddelanden" component={TicketsLayout} />
                  <Route
                    path="/installningar/huvudman/:principalId"
                    component={Principal}
                  />
                  <Route path="/installningar" component={Settings} />
                  <Route path="/export/:exportId" component={DownloadReport} />
                  <Route
                    path="/user-export/:personalDataExportId"
                    component={DownloadPersonalDataExport}
                  />
                  <Route
                    render={() => {
                      const { redirect } = queryString.parse(location.search)
                      return <Redirect to={redirect || '/huvudman'} />
                    }}
                  />
                </Switch>
              </AnimatePresence>
            </AppLayout>
          )}
        />
      ) : (
        <Route
          render={({ location }) => (
            <LoginLayout>
              <AnimatePresence exitBeforeEnter initial={false}>
                <Switch location={location} key={location.pathname}>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/skapa-konto" component={Register} />
                  <Route
                    render={() => <Redirect to={`/login${location.search}`} />}
                  />
                </Switch>
              </AnimatePresence>
            </LoginLayout>
          )}
        />
      )}
      <Toaster
        position="bottom-left"
        toastOptions={{
          // Define default options
          className: 'font-sans font-medium shadow-lg',
          style: {
            padding: '0.75rem',
            display: 'flex',
            alignItems: 'center',
            border: '1px solid rgba(0,0,0,0.075)',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
          },
          duration: 5000,
          // Default options for specific types
          success: {
            duration: 3000,
            icon: (
              <Icon
                name="checkbox-circle"
                className="w-4 h-4 text-blue-500 fill-current"
              />
            ),
          },
          error: {
            duration: 5000,
            icon: (
              <Icon name="alert" className="w-4 h-4 text-red fill-current" />
            ),
          },
        }}
      />
    </Router>
  )
}

export default App
