import PropTypes from 'prop-types'

import { formatDistanceToNow, format } from '@utils/date'

const DateCell = ({ date, type, ...props }) => (
  <span {...props}>
    {type === 'distance'
      ? formatDistanceToNow(date)
      : format(date, 'yyyy-MM-dd')}
  </span>
)

DateCell.defaultProps = {
  type: 'date',
}

DateCell.propTypes = {
  date: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['date', 'distance']),
}

export default DateCell
