import React, { useCallback, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useApolloClient, useQuery, useMutation, gql } from '@apollo/client'
import { toast } from 'react-hot-toast'
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion'
import {
  Icon,
  Button,
  Input,
  TextArea,
  Checkbox,
  Select,
  useModal,
} from '@aider/ui'
import find from 'lodash.find'
import { LoadingContainer, GooglePlacesSelect } from '@components/'
import CreateContact from './CreateContact'
import DeleteContact from './DeleteContact'

const validationSchema = Yup.object().shape({
  name: Yup.string().when('skipRoaring', {
    is: true,
    then: Yup.string().required('Obligatoriskt fält.'),
  }),
  address: Yup.string().when('skipRoaring', {
    is: true,
    then: Yup.string()
      .matches(
        /(.*)/,
        'Fyll i en adress',
      )
      .nullable(),
  }),
  email: Yup.string().email('Fyll i en giltig e-postadress'),
  phone: Yup.string('Fyll i ett giltigt telefonnummer'),
  visitingAddress: Yup.string().matches(
    /(.*)/,
    'Fyll i en fullständig adress med gatunummer',
  ),
  skipRoaring: Yup.boolean(),
  role: Yup.object().shape({
    value: Yup.string().nullable(),
    label: Yup.string().nullable(),
  }),
  note: Yup.string(),
})

const GET_USER = gql`
  query getUser {
    me {
      id
    }
  }
`

const PRINCIPAL_BY_ID = gql`
  query principalById($principalId: ID!) {
    principal(id: $principalId) {
      id
      name
      decryptedPin
      address
      zipcode
      city
      createdManually    
      userByCurrentAuth {
        id
        pivot {
          visitingAddress
          fullAddress
          email
          phone
          name
          note
          role
          active
          organization {
            id
            name
          }
        }
      }
      contacts {
        id
        name
        relation
        phone
        email
        note
      }
    }
  }
`

const UPDATE_PRINCIPAL = gql`
  mutation (
    $userId: ID!
    $principalId: ID!
    $name: String
    $fullAddress: String
    $phone: String!
    $email: String!
    $visitingAddress: String!
    $role: String
    $note: String
    $active: Boolean
    $organizationId: ID!
  ) {
    updateUser(
      id: $userId
      principals: {
        syncWithoutDetaching: [
          {
            id: $principalId
            name: $name
            fullAddress: $fullAddress
            email: $email
            phone: $phone
            visitingAddress: $visitingAddress
            note: $note
            role: $role
            active: $active
            organizationId: $organizationId
          }
        ]
      }
    ) {
      id
    }
  }
`

const ALL_ORGANIZATIONS = gql`
  query allOrganizations {
    organizations(
      municipalityCodeLargerThan: "0008" # Larger than 0008, removes any test muncipalities from the list
      orderBy: { column: NAME, order: ASC }
    ) {
      id
      name
      municipalityCode
    }
  }
`

const Principal = () => {
  const client = useApolloClient()
  const { principalId } = useParams()
  const [initialValues, setInitialValues] = useState({
    name: '',
    pin: '',
    address: '',
    visitingAddress: '',
    email: '',
    phone: '',
    note: '',
    role: { value: '', label: '' },
    skipRoaring: false,
  })
  const [editContactId, setEditContactId] = useState(null)
  const roleOptions = [
    { label: 'God man', value: 'god-man' },
    { label: 'Förvaltare', value: 'forvaltare' },
    { label: 'Förmyndare', value: 'formyndare' },
    { label: 'God man (EKB)', value: 'god-man-ekb' },
  ]

  // Get all organizations
  const {
    data: { organizations = [] } = {},
    loading: loadingOrganizations,
  } = useQuery(ALL_ORGANIZATIONS)

  const organizationOptions = organizations.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  // Get current authenticated user
  const { loading: loadingUser, data: { me: { id: userId } = {} } = {} } =
    useQuery(GET_USER)


  const handleOnCompleted = useCallback(
    ({
      principal: {
        name: principalName,
        decryptedPin,
        address,
        city,
        zipcode,
        createdManually: skipRoaring,
        userByCurrentAuth: {
          pivot: {
            fullAddress,
            name: pivotName,
            visitingAddress: vAddress,
            email,
            phone,
            note,
            role,
            active,
            organization
          },
        },
      } = {},
    }) => {
      setInitialValues({
        name: pivotName || principalName,
        pin: decryptedPin,
        address: fullAddress || `${address}, ${zipcode} ${city}`,
        email: email ?? '',
        phone: phone ?? '',
        visitingAddress: vAddress ?? '',
        note: note ?? '',
        role: {
          value: role ?? '',
          label: find(roleOptions, { value: role })?.label ?? '',
        },
        skipRoaring,
        active: active ?? false,
        organization: find(organizationOptions, { value: organization.id }),
      })
    },
    [],
  )

  const {
    loading: loadingPrincipal,
    data: {
      principal: {
        createdManually: principalCreatedManually,
        userByCurrentAuth: { pivot: { visitingAddress } = {} } = {},
        contacts = [],
      } = {},
    } = {},
  } = useQuery(PRINCIPAL_BY_ID, {
    fetchPolicy: 'network-only',
    skip: !principalId,
    variables: {
      principalId,
    },
    onCompleted: handleOnCompleted,
  })

  // Sort principals by name asc
  const contactsSorted = [...contacts].sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  const [updatePrincipal, { loading: updatingPrincipal }] = useMutation(
    UPDATE_PRINCIPAL,
    {
      onCompleted: useCallback(() => {
        toast.success('Huvudmannen sparades')
        client.resetStore()
      }, []),
      onError: useCallback(() => {
        toast.error('Huvudmannen kunde inte sparas')
      }, []),
    },
  )

  // Create Form
  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    isValid,
    setFieldTouched,
  } = useFormik({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: async ({
      name,
      address,
      email,
      phone,
      role,
      note,
      visitingAddress: vAddress,
      active
    }) => {
      // TODO: also submit name and address if created manually
      // We only submit the ones that is not disabled
      // Submit to API
      updatePrincipal({
        variables: {
          userId,
          principalId,
          ...(true && { name }),
          ...(true && { fullAddress: address }),
          email,
          phone,
          visitingAddress: vAddress,
          role: role.value,
          note,
          active,
          organizationId: values.organization?.value,
        },
      })
    },
  })

  // Create principal modal
  const {
    openModal: openCreatePrincipalModal,
    closeModal: closeCreatePrincipalModal,
    isOpen: isCreatePrincipalOpen,
    Modal: CreatePrincipalModal,
  } = useModal()

  // Delete contact modal
  const {
    openModal: openDeleteContactModal,
    closeModal: closeDeleteContactModal,
    isOpen: deleteContactOpen,
    Modal: DeleteContactModal,
  } = useModal({
    onClose: useCallback(() => {
      setEditContactId(null)
    }, []),
  })

  return (
    <LoadingContainer loading={loadingPrincipal || loadingUser || loadingOrganizations}>
      <div className="mx-auto pt-16 w-full lg:w-10/12 xl:w-8/12">
        <section className="mb-20">
          <form onSubmit={handleSubmit}>
            <header className="sticky z-10 top-0 flex items-center justify-start mb-4 py-4 bg-white space-x-6">
              <div className="flex items-center space-x-4 space-between w-10/12">
                <Link
                  to="/installningar"
                  className="text-blue-500 text-2xl font-medium hover:opacity-50 transition-opacity"
                >
                  ...
                </Link>
                <Icon
                  name="chevron-right"
                  className="flex-shrink-0 w-2.5 h-2.5 text-black fill-current"
                />
                <div className="text-2xl font-medium truncate">
                  Redigera huvudman
                </div>
              </div>
              <div>
                <Button
                  title="Spara"
                  type="submit"
                  disabled={!isValid || !dirty || isSubmitting}
                  isLoading={updatingPrincipal}
                  variant="primary"
                />
              </div>
            </header>

            <div className="flex text-gray-800">
              <div className="w-6/12 pr-10">
                <Input
                  id="name"
                  name="name"
                  type="text"
                  label="Namn"
                  placeholder="Namn"
                  tooltip={
                    principalCreatedManually
                      ? ''
                      : 'Hämtas från folkbokföringsregistret'
                  }
                  value={values.name}
                  error={touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="w-6/12 pr-10">
                <Input
                  id="pin"
                  name="pin"
                  type="text"
                  label="Personnummer"
                  placeholder="Personnummer"
                  tooltip="Det går inte att ändra personnummer"
                  value={values.pin}
                  error={touched.pin && errors.pin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                />
              </div>
            </div>

            <div className="flex text-gray-800 mt-6">
              <div className="w-6/12 pr-10">
                {false ? (
                  <GooglePlacesSelect
                    label="Folkbokföringsadress"
                    id="address"
                    name="address"
                    handleChange={handleChange}
                    defaultValue={values.address ?? ''}
                    error={errors.address}
                    touched={touched.address}
                    setFieldTouched={setFieldTouched}
                  />
                ) : (
                  <Input
                    id="address"
                    name="address"
                    type="text"
                    label="Folkbokföringsadress"
                    placeholder="Folkbokföringsadress"
                    tooltip="Hämtas från folkbokföringsregistret"
                    value={values.address}
                    error={touched.address && errors.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
              </div>
              <div className="w-6/12 pr-10">
                  <Input
                    id="visitingAddress"
                    name="visitingAddress"
                    type="text"
                    label="Vistelseadress"
                    placeholder="Vistelseadress"
                    value={values.visitingAddress}
                    error={touched.visitingAddress && errors.visitingAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
              </div>
            </div>

            <div className="flex text-gray-800 mt-6">
              <div className="w-6/12 pr-10">
                <Input
                  id="email"
                  name="email"
                  type="email"
                  label="E-postadress"
                  value={values.email}
                  error={touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="w-6/12 pr-10">
                <Input
                  id="phone"
                  name="phone"
                  type="tel"
                  label="Telefonnummer"
                  value={values.phone}
                  error={touched.phone && errors.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="flex text-gray-800 mt-6">
              <div className="w-6/12 pr-10">
                <Select
                  label="Min roll"
                  placeholder="Välj roll"
                  name="role"
                  id="role"
                  type="text"
                  options={roleOptions}
                  value={values.role}
                  onChange={handleChange}
                />
              </div>
              <div className="w-6/12 pr-10">
                <TextArea
                  label="Anteckningar"
                  name="note"
                  id="note"
                  rows="4"
                  value={values.note}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex text-gray-800 mt-6">
              <div className="w-6/12 pr-10">
              <Checkbox
                id="active"
                name="active"
                onCheckedChange={handleChange}
                onBlur={handleBlur}
                label="Aktiv"
                checked={values.active}
             />
             <p className="mt-2 text-sm text-gray-500">Aktivt uppdrag</p>
              </div>
              <div className="w-6/12 pr-10">
              <Select
                  id="organization"
                  name="organization"
                  value={values.organization}
                  error={errors.organization?.value}
                  label="Kommun"
                  placeholder="Välj eller sök efter kommun"
                  options={organizationOptions}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  // Increase the z-index to make sure the dropdown is visible, otherwise the Kontakter-Lägg till row will be on top
                  className="z-50"
                />
              </div>
            </div>
            <Checkbox
              className="hidden"
              id="skipRoaring"
              name="skipRoaring"
              onCheckedChange={handleChange}
              onBlur={handleBlur}
              label="Skip roaring"
              checked={values.skipRoaring}
            />
          </form>
        </section>
        <section className="mb-20">
          {/* Principals */}
          <div className="sticky z-1 top-0 flex items-center justify-between py-4 bg-white">
            <div className="text-2xl font-medium">Kontakter</div>
            <button
              type="button"
              className="text-blue-600 font-medium focus:outline-none hover:opacity-75 transition-opacity"
              onClick={() => {
                setEditContactId(null)
                openCreatePrincipalModal()
              }}
            >
              Lägg till
            </button>
          </div>
          <AnimateSharedLayout>
            <motion.ul layout>
              <AnimatePresence initial={false}>
                {contactsSorted.map(
                  ({ id, name: contactName, relation, email, phone }) => (
                    <motion.li
                      initial={{ opacity: 0, x: 50 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 50 }}
                      layout
                      key={id}
                    >
                      <div className="flex items-center justify-between py-4 border-t border-gray-300">
                        <div>
                          <div className="font-medium leading-tight">
                            {contactName}
                          </div>
                          <div className="text-gray-800 leading-tight">
                            {relation}
                          </div>
                          {phone && (
                            <div className="text-blue-500 leading-tight">
                              <a href={`tel:${phone}`}>{phone}</a>
                            </div>
                          )}
                          {email && (
                            <div className="text-blue-500 leading-tight">
                              <a href={`mailto:${email}`}>{email}</a>
                            </div>
                          )}
                        </div>
                        <div className="space-x-4">
                          <button
                            type="button"
                            className="text-blue-500 hover:opacity-75 transition-opacity font-medium"
                            onClick={() => {
                              setEditContactId(id)
                              openCreatePrincipalModal()
                            }}
                          >
                            Redigera
                          </button>
                          <button
                            type="button"
                            className="text-red hover:opacity-75 transition-opacity font-medium"
                            onClick={async () => {
                              setEditContactId(id)
                              openDeleteContactModal()
                            }}
                          >
                            Radera
                          </button>
                        </div>
                      </div>
                    </motion.li>
                  ),
                )}
              </AnimatePresence>
            </motion.ul>
          </AnimateSharedLayout>
        </section>
      </div>

      <CreatePrincipalModal visible={isCreatePrincipalOpen}>
        <CreateContact
          contactId={editContactId}
          onSuccess={closeCreatePrincipalModal}
          onCancel={closeCreatePrincipalModal}
        />
      </CreatePrincipalModal>
      <DeleteContactModal visible={deleteContactOpen}>
        <DeleteContact
          contactId={editContactId}
          onSuccess={() => {
            closeDeleteContactModal()
          }}
          onError={() => toast.error('Kunde inte ta bort kontakten')}
          onCancel={closeDeleteContactModal}
        />
      </DeleteContactModal>
    </LoadingContainer>
  )
}

export default Principal
