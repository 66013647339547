import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Checkbox } from '@aider/ui'

const validationSchema = Yup.object().shape({
  timeJournal: Yup.bool().when("drivingJournal", {
    is: (value) => !value,
    then: Yup.bool().oneOf([true], "At least one needs to be checked")
  }),
  drivingJournal: Yup.bool().when("timeJournal", {
    is: (value) => !value,
    then: Yup.bool().oneOf([true], "At least one needs to be checked")
  })
},
  [
    "timeJournal", "drivingJournal",
  ]
);

const PrintJournal = ({ onSuccess }) => {

  const {
    values,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues: { timeJournal: true, drivingJournal: true },
    onSubmit: ({ timeJournal, drivingJournal }) => {
      onSuccess({ timeReport: timeJournal, drivingReport: drivingJournal })
    }
  })

  return (
    <div
      className="p-6 bg-white rounded-lg overflow-hidden print:hidden"
      style={{ width: 480 }}
    >
      <header className="mb-2 text-black text-lg font-medium">
        Skriv ut eller spara dagbok och/eller körjournal
      </header>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="mb-4 text-gray-800">
          Välj vad du vill skriva ut eller spara, genom att markera eller avmarkera i rutorna nedan
        </div>
        <div className="mb-4">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="timeJournal"
              checked={values.timeJournal}
              onCheckedChange={handleChange}
              name="timeJournal"
            />
            <label className="text-base font-medium" htmlFor="timeJournal">
              Dagbok
            </label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="drivingJournal"
              checked={values.drivingJournal}
              onCheckedChange={handleChange}
              name="drivingJournal"
            />
            <label className="text-base font-medium" htmlFor="drivingJournal">
              Körjournal
            </label>
          </div>
        </div>

        <Button
          title="Skriv ut"
          type="submit"
          variant="primary"
          disabled={!isValid}
        />
      </form>
    </div>
  )
}

PrintJournal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default PrintJournal
