const canUseIntercom =
  typeof window === 'object' && typeof window.Intercom === 'function'

/**
 * Wrapper around Intercom that makes sure window and Intercom are defined
 * @param  {...any} args Anything accepted by Intercom :-)
 */
export const intercom = (...args) => {
  if (canUseIntercom) {
    window.Intercom(...args)
  }
}
