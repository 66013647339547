import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation, gql } from '@apollo/client'
import { Button, Checkbox } from '@aider/ui'
import { toast } from 'react-hot-toast'

const validationSchema = Yup.object().shape({
  transactionIds: Yup.array()
    .of(Yup.string())
    .required('Obligatoriskt fält.'),
  confirmation: Yup.bool().oneOf([true], 'Godkänn för att fortsätta.'),
})

const DELETE_TRANSACTIONS = gql`
  mutation deleteTransactions($transactionIds: [ID!]!) {
    deleteTransactions(ids: $transactionIds) {
      id
    }
  }
`

const DeleteTransactions = ({ transactionIds, onSuccess, onCancel }) => {
  const [deleteTransactions] = useMutation(DELETE_TRANSACTIONS, {
    onCompleted: useCallback(() => {
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }, [onSuccess]),
    onError: useCallback(() => {
      toast.error('Kunde inte ta bort transaktionen')
    }, []),
    update: (cache, { data: { deleteTransactions: deleted } = {} } = {}) => {
      deleted.forEach(item => {
        cache.evict({ id: cache.identify(item) })
      })
    },
  })

  const {
    values,
    dirty,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
  } = useFormik({
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues: { confirmation: false, transactionIds },
    onSubmit: ({ transactionIds: ids }) =>
      deleteTransactions({ variables: { transactionIds: ids } }),
  })

  return (
    <div
      className="p-6 bg-white rounded-lg overflow-hidden"
      style={{ width: 480 }}
    >
      <header className="mb-2 text-black text-lg font-medium">
        Radera {transactionIds.length}{' '}
        {transactionIds.length > 1 ? 'transaktioner' : 'transaktion'}
      </header>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="mb-4 text-gray-800">
          När du raderar transaktioner riskerar du att din redovisning blir
          inkomplett. Vi kommer inte att kunna återställa borttagna
          transaktioner.
        </div>
        <div className="mb-4">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="confirmation"
              checked={values.confirmation}
              onCheckedChange={handleChange}
              name="confirmation"
            />
            <label className="text-base font-medium" htmlFor="confirmation">
              Jag förstår
            </label>
          </div>
        </div>
        <div className="flex justify-between">
          <Button
            title="Avbryt"
            type="button"
            variant="tertiary"
            onClick={() => typeof onCancel === 'function' && onCancel()}
          />
          <Button
            title="Radera permanent"
            type="submit"
            variant="destructive"
            disabled={!isValid || !dirty || isSubmitting}
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </div>
  )
}

DeleteTransactions.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default DeleteTransactions
