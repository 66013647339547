import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { Button, Datepicker, DatepickerInput, Input } from '@aider/ui'
import { format, startOfYear, endOfYear } from 'date-fns'

import { activePrincipalIdVar } from '@/cache'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Ange ett giltigt namn på rapporten')
    // We need to check that the name is not too long,
    // because the encrypted name is limited to 255 characters (varchar(255) in the database)
    // Otherwise we can save the full encrypted payload in the database
    .test('max-length', 'Namnet får inte innehålla fler än 18 tecken', value =>
      value ? value.length <= 18 : true,
    ),
  // Make this nullable since it's not possible to select a endDate without a startDate
  startDate: Yup.date().required().nullable(),
  endDate: Yup.date().required().typeError('Välj ett giltigt datumspann'),
})

const CREATE_REPORT = gql`
  mutation createReport(
    $principalId: ID!
    $name: String!
    $from: Date!
    $to: Date!
  ) {
    createReport(
      input: { principalId: $principalId, name: $name, from: $from, to: $to }
    ) {
      id
      name
      from
      to
    }
  }
`

const UPDATE_REPORT = gql`
  mutation updateReport($reportId: ID!, $name: String, $from: Date, $to: Date) {
    updateReport(input: { id: $reportId, name: $name, from: $from, to: $to }) {
      id
      name
      from
      to
    }
  }
`

const CreateReport = ({ onSuccess, onError, onCancel, mode, report }) => {
  const principalId = useReactiveVar(activePrincipalIdVar)

  const [createReport] = useMutation(CREATE_REPORT, {
    awaitRefetchQueries: true,
    refetchQueries: ['reports'],
    onCompleted: useCallback(
      ({ createReport: { id: reportId } = {} }) => {
        if (typeof onSuccess === 'function') {
          onSuccess(reportId)
        }
      },
      [onSuccess],
    ),
    onError: useCallback(
      error => {
        if (typeof onError === 'function') {
          onError(error)
        }
      },
      [onError],
    ),
  })

  const [updateReport] = useMutation(UPDATE_REPORT, {
    awaitRefetchQueries: true,
    refetchQueries: ['reports'],
    onCompleted: useCallback(
      ({ createReport: { id: reportId } = {} }) => {
        if (typeof onSuccess === 'function') {
          onSuccess(reportId)
        }
      },
      [onSuccess],
    ),
    onError: useCallback(
      error => {
        if (typeof onError === 'function') {
          onError(error)
        }
      },
      [onError],
    ),
  })

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useFormik({
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      name: mode === 'create' ? '' : report.name,
      startDate:
        mode === 'create' ? startOfYear(new Date()) : new Date(report.from),
      endDate: mode === 'create' ? endOfYear(new Date()) : new Date(report.to),
    },
    onSubmit: ({ name, startDate, endDate }) => {
      if (mode === 'create') {
        createReport({
          variables: {
            principalId,
            name,
            from: format(startDate, 'yyyy-MM-dd'),
            to: format(endDate, 'yyyy-MM-dd'),
          },
        })
      } else if (mode === 'edit' && report.id !== null) {
        updateReport({
          variables: {
            reportId: report.id,
            name,
            from: format(startDate, 'yyyy-MM-dd'),
            to: format(endDate, 'yyyy-MM-dd'),
          },
        })
      }
    },
  })

  const handleDateChange = dates => {
    const [startDate, endDate] = dates
    // Don't validate on changes to startDate
    setFieldValue('startDate', startDate, false)
    setFieldValue('endDate', endDate)
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-1 flex-col justify-between p-6 bg-white rounded-lg overflow-hidden"
      style={{ width: 480 }}
    >
      <div>
        <header className="mb-6 text-black text-lg font-medium">
          {mode === 'create' ? 'Ny' : 'Uppdatera'} rapport
        </header>
        <div className="mb-4">
          <Input
            id="name"
            name="name"
            type="text"
            label="Rapportens namn"
            placeholder="Namnge din rapport"
            value={values.name}
            error={touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className="flex justify-end text-gray-500 mt-0.5 text-sm font-semibold">
            {values.name.length}/18
          </div>
        </div>
        <div className="mb-4">
          <Datepicker
            id="date"
            name="date"
            onChange={handleDateChange}
            startDate={values.startDate}
            endDate={values.endDate}
            selected={values.startDate}
            shouldCloseOnSelect={false}
            selectsRange
            customInput={
              <DatepickerInput
                id="date-input"
                name="date-input"
                selectsRange
                label="Period"
                onBlur={handleBlur}
                startDate={values.startDate}
                endDate={values.endDate}
                error={errors.endDate}
              />
            }
          />
        </div>
      </div>
      <div className="flex justify-between">
        <Button
          type="button"
          title="Avbryt"
          variant="tertiary"
          onClick={() => typeof onCancel === 'function' && onCancel()}
        />
        <Button
          type="submit"
          title={mode === 'create' ? 'Skapa rapport' : 'Spara'}
          variant="primary"
          disabled={!isValid || isSubmitting}
          isLoading={isSubmitting}
        />
      </div>
    </form>
  )
}

CreateReport.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['create', 'edit']),
  report: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
  }),
}

CreateReport.defaultProps = {
  mode: 'create', // 'create' or 'edit'
  report: null, // Id of the report to edit
}

export default CreateReport
