import { gql } from '@apollo/client'
import { toast } from 'react-hot-toast'

import { client } from '@/apollo-client'

const USER_SUBSCRIPTION = gql`
  query userSubscription {
    me {
      id
      sub {
        active
      }
    }
  }
`

const STRIPE_CUSTOMER_PORTAL_URL = gql`
  query stripeCustomerPortalUrl {
    stripeCustomerPortal {
      url
    }
  }
`

const STRIPE_CHECKOUT_SESSION = gql`
  query stripeCheckoutSession($period: String!) {
    stripeCheckout(period: $period) {
      sessionId
    }
  }
`

const canUseStripe =
  typeof window === 'object' && typeof window.Stripe === 'function'

/**
 * Get and go to users unique Stripe Customer Portal URL
 * @return {null}
 */
export const goToStripeCustomerPortal = async () => {
  try {
    if (!canUseStripe) throw new Error('Stripe is not available')

    const { data: { me: { sub } = {} } = {} } = await client.query({
      query: USER_SUBSCRIPTION,
    })

    // If user has (or ever had) a subscription
    if (!sub) {
      const {
        data: { stripeCheckout: { sessionId } = {} } = {},
      } = await client.query({
        query: STRIPE_CHECKOUT_SESSION,
        variables: { period: 'year' },
      })

      return window.stripe.redirectToCheckout({ sessionId })
    }

    const {
      data: { stripeCustomerPortal: { url } = {} } = {},
    } = await client.query({ query: STRIPE_CUSTOMER_PORTAL_URL })

    if (typeof url !== 'string') throw new Error('No Stripe URL')

    window.location.href = url
    return true
  } catch (error) {
    toast.error('Betaltjänsten svarar inte. Försök igen om en liten stund.')
  }

  return false
}
