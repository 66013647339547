import { useEffect, useState, useCallback, Fragment } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { Button, useModal, Icon } from '@aider/ui'
import { motion, AnimatePresence } from 'framer-motion'
import { toast } from 'react-hot-toast'

import { EmptyState, LoadingContainer } from '@components/'
import { useActiveOrganization, useEventEmitter, events } from '@hooks/'
import EmptyTickets from '@assets/images/empty_tickets.png'

import TicketListItem from './TicketListItem'
import CreateTicket from './CreateTicket'

export const TICKETS = gql`
  query tickets($after: String) {
    me {
      id
      tickets(
        after: $after
        first: 50
        orderBy: { column: UPDATED_AT, order: DESC }
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            title
            read
            open
            updatedAt
            createdAt
            organization {
              id
              name
            }
            latestMessage {
              id
              message
              updatedAt
              user {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

const Tickets = () => {
  const [calledOnce, setCalledOnce] = useState(false)
  const eventEmitter = useEventEmitter()
  const { openModal, closeModal, isOpen, Modal } = useModal()
  const history = useHistory()
  const { hasActiveOrganization, loading: loadingOrganizations } =
    useActiveOrganization()

  const {
    data: {
      me: {
        tickets: {
          edges: tickets = [],
          pageInfo: { endCursor, hasNextPage } = {},
        } = {},
      } = {},
    } = {},
    loading: loadingTickets,
    startPolling,
    stopPolling,
    fetchMore,
  } = useQuery(TICKETS, {
    fetchPolicy: 'network-only',
    onCompleted: useCallback(() => {
      if (!calledOnce) {
        setCalledOnce(true)
      }
    }, [calledOnce]),
    onError: useCallback(() => {
      if (!calledOnce) {
        setCalledOnce(true)
      }
      toast.error('Vi kunde inte hämta meddelanden')
    }, [calledOnce]),
  })

  // Start and stop polling when leaving page
  useEffect(() => {
    startPolling(5000)
    return () => stopPolling()
  }, [startPolling, stopPolling])

  useEffect(() => {
    const unsubscribe = eventEmitter.on(events.CONTENT_SCROLL_BOTTOM, () => {
      if (!hasNextPage || loadingTickets) return
      fetchMore({ variables: { after: endCursor } })
    })

    return () => unsubscribe()
  }, [eventEmitter, fetchMore, loadingTickets, hasNextPage, endCursor])

  const handleCreateTicketSuccess = useCallback(
    ticketId => {
      closeModal()
      history.push(`/meddelanden/${ticketId}`)
    },
    [closeModal, history],
  )

  return (
    <LoadingContainer
      loading={!calledOnce && (loadingTickets || loadingOrganizations)}
    >
      <div className="mx-auto pb-20 pt-16 w-full lg:w-10/12 xl:w-8/12">
        {!hasActiveOrganization ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <EmptyState
              icon={
                <img
                  className="mb-2 w-32 h-auto"
                  src={EmptyTickets}
                  alt="meddelanden"
                />
              }
              title="Meddelanden är inte tillgängligt"
              description="Ingen av dina huvudmäns kommuner är anslutna till Aiders meddelande-funktion."
              action={
                <a
                  className="flex items-center text-black text-lg font-medium space-x-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://intercom.help/aider/sv/collections/2717899-hjalp-for-stallforetradare"
                >
                  <span>Läs mer</span>
                  <Icon
                    name="arrow-top-right"
                    className="w-3.5 h-3.5 fill-current"
                  />
                </a>
              }
            />
          </div>
        ) : (
          <Fragment>
            <header className="sticky z-10 top-0 flex items-center justify-between py-4 bg-white">
              <div className="text-2xl font-medium">Meddelanden</div>
              <div className="flex items-center space-x-4">
                <Button title="Nytt meddelande" onClick={openModal} />
              </div>
            </header>
            <AnimatePresence exitBeforeEnter initial={false}>
              {Array.isArray(tickets) && tickets.length ? (
                <motion.div
                  key="tickets"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {tickets.map(
                    ({
                      node: {
                        id,
                        title,
                        updatedAt,
                        latestMessage,
                        organization,
                        read,
                      } = {},
                    }) => (
                      <TicketListItem
                        key={id}
                        title={title}
                        latestMessage={latestMessage?.message}
                        latestSender={
                          latestMessage?.user ? 'Du' : organization?.name
                        }
                        updatedAt={updatedAt}
                        organizationName={organization?.name}
                        read={read}
                        to={`/meddelanden/${id}`}
                      />
                    ),
                  )}
                </motion.div>
              ) : (
                <motion.div
                  key="empty-state"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="absolute inset-0 flex items-center justify-center"
                >
                  <EmptyState
                    icon={
                      <img
                        className="mb-2 w-24 h-auto"
                        src={EmptyTickets}
                        alt="meddelanden"
                      />
                    }
                    title="Vi hittade inga meddelanden"
                    description="Prova att ändra filtreringen eller skapa ett nytt meddelande. Nya meddelanden hamnar automatiskt här."
                    action={
                      <Button title="Nytt meddelande" onClick={openModal} />
                    }
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </Fragment>
        )}
      </div>
      {/* Modals */}
      <Modal visible={isOpen} className="h.md:h-5/6 overflow-y-auto">
        <CreateTicket
          onCancel={closeModal}
          onSuccess={handleCreateTicketSuccess}
        />
      </Modal>
    </LoadingContainer>
  )
}

export default Tickets
