import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Button, Input, TextArea } from '@aider/ui'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-hot-toast'

const validationSchema = Yup.object().shape({
  relation: Yup.string().required('Obligatoriskt fält.'),
  name: Yup.string().required('Obligatoriskt fält.'),
  phone: Yup.string(),
  email: Yup.string().email('Fyll i en giltig e-postadress'),
  note: Yup.string(),
})

const CONTACT_DETAILS = gql`
  query contactDetails($id: ID!) {
    contact(id: $id) {
      id
      principal {
        id
      }
      name
      relation
      phone
      email
      note
    }
  }
`

const UPDATE_OR_CREATE_CONTACT = gql`
  mutation updateOrCreateContact(
    $id: ID
    $principalId: ID!
    $relation: String!
    $name: String!
    $phone: String
    $email: String
    $note: String
  ) {
    upsertContact(
      id: $id
      principalId: $principalId
      relation: $relation
      name: $name
      phone: $phone
      email: $email
      note: $note
    ) {
      id
      name
      relation
      phone
      email
      note
    }
  }
`

const CreateContact = ({ contactId, onCancel, onSuccess }) => {
  const { principalId } = useParams()
  const [initialValues, setInitialValues] = useState({
    relation: '',
    name: '',
    phone: '',
    email: '',
    note: '',
  })
  const { loading } = useQuery(CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
    skip: !contactId,
    onCompleted: useCallback(
      ({ contact: { relation, name, phone, email, note } = {} }) => {
        // Update initial form state
        setInitialValues({
          relation,
          name,
          phone,
          email,
          note,
        })
      },
      [],
    ),
    onError: useCallback(() => {
      toast.error('Kunde inte uppdatera kontoinformationen')
    }, []),
  })

  const [updateOrCreateContact, { loadingUpdateContact }] = useMutation(
    UPDATE_OR_CREATE_CONTACT,
    {
      awaitRefetchQueries: true,
      refetchQueries: ['principalById'],
      onCompleted: useCallback(() => {
        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      }, [onSuccess, contactId]),
      onError: useCallback(() => {
        toast.error('Kunde inte uppdatera kontoinformationen')
      }, []),
    },
  )

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    isValid,
  } = useFormik({
    validateOnMount: true,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: async ({
      relation: updatedRelation,
      name: updatedName,
      phone: updatedPhone,
      email: updatedEmail,
      note: updatedNote,
    }) => {
      updateOrCreateContact({
        variables: {
          id: contactId,
          principalId,
          relation: updatedRelation,
          name: updatedName,
          phone: updatedPhone,
          email: updatedEmail,
          note: updatedNote,
        },
      })
    },
  })

  return (
    <div className="p-6 w-96 bg-white rounded-lg">
      <header className="mb-6 text-black text-lg font-medium">
        {contactId ? 'Uppdatera' : 'Skapa'} kontakt
      </header>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <Input
            id="relation"
            name="relation"
            label="Relation"
            placeholder="Relation till huvudman"
            type="text"
            value={values.relation}
            error={touched.relation && errors.relation}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="mb-4">
          <Input
            id="name"
            name="name"
            placeholder="För- och efternamn"
            label="Namn"
            type="text"
            value={values.name}
            error={touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="mb-4">
          <Input
            id="phone"
            name="phone"
            label="Telefon"
            type="text"
            value={values.phone}
            error={touched.phone && errors.phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="mb-4">
          <Input
            id="email"
            name="email"
            label="E-post"
            type="text"
            value={values.email}
            error={touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="mb-4">
          <TextArea
            id="note"
            name="note"
            label="Notering"
            type="text"
            value={values.note}
            error={touched.note && errors.note}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="flex justify-between pt-6">
          <Button
            variant="tertiary"
            type="button"
            title="Avbryt"
            onClick={onCancel}
          />
          <Button
            title="Spara"
            type="submit"
            isLoading={isSubmitting || loadingUpdateContact}
            disabled={isSubmitting || !isValid || loadingUpdateContact}
            variant="primary"
          />
        </div>
      </form>
    </div>
  )
}

CreateContact.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CreateContact
