import { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'

const USER_SUBSCRIPTION = gql`
  query userSubscription {
    me {
      id
      subscribed
      onTrial
      trialEndsAt
    }
  }
`

/**
 * Check if a user is on premium or trial
 * @return {Object}
 */
const useOnPremiumOrTrial = () => {
  const [onPremium, setOnPremium] = useState(undefined)
  const [_onTrial, setOnTrial] = useState(undefined)
  const [trialHasEnded, setTrialHasEnded] = useState(undefined)

  const {
    data: { me: { subscribed, onTrial, trialEndsAt } = {} } = {},
    loading,
    error,
  } = useQuery(USER_SUBSCRIPTION)

  useEffect(() => {
    if (typeof subscribed !== 'undefined' || typeof onTrial !== 'undefined') {
      setOnPremium(Boolean(subscribed || onTrial))
      setOnTrial(onTrial && !subscribed)
      setTrialHasEnded(!onTrial && typeof trialEndsAt === 'string')
    }
  }, [subscribed, onTrial, trialEndsAt])

  return {
    onPremium,
    trialHasEnded,
    onTrial: _onTrial,
    trialEndsAt,
    loading,
    error,
  }
}

export default useOnPremiumOrTrial
