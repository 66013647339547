import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { format } from 'date-fns'
import * as Yup from 'yup'
import { useMutation, gql, useReactiveVar } from '@apollo/client'
import {
  Button,
  Input,
  FINANCIAL_DEFAULT_PROPS,
  Datepicker,
  DatepickerInput,
  Icon,
} from '@aider/ui'
import { toast } from 'react-hot-toast'

import { activePrincipalIdVar } from '@/cache'

const validationSchema = Yup.object().shape({
  description: Yup.string().required('Obligatoriskt fält.'),
  amount: Yup.number().required('Fyll i ett giltigt värde'),
  date: Yup.date().required('Obligatoriskt fält'),
})

const CREATE_TRANSACTION = gql`
  mutation createTransaction(
    $principalId: ID!
    $amount: Float!
    $description: String!
    $date: Date!
  ) {
    createTransaction(
      input: {
        principalId: $principalId
        amount: $amount
        description: $description
        date: $date
      }
    ) {
      id
      description
      date
      amount
      expense
    }
  }
`

const CreateTransaction = ({ onSuccess, onCancel }) => {
  const activePrincipalId = useReactiveVar(activePrincipalIdVar)

  const [createTransaction] = useMutation(CREATE_TRANSACTION, {
    awaitRefetchQueries: true,
    refetchQueries: ['transactions'],
    onCompleted: useCallback(() => {
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }, [onSuccess]),
    onError: useCallback(() => {
      toast.error('Kunde inte skapa transaktionen')
    }, []),
  })

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useFormik({
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues: { description: '', amount: '', date: null },
    onSubmit: ({ date, description, amount }) =>
      createTransaction({
        variables: {
          principalId: activePrincipalId,
          date: format(date, 'yyyy-MM-dd'),
          description,
          amount,
        },
      }),
  })

  return (
    <div
      className="p-6 bg-white rounded-lg overflow-hidden"
      style={{ width: 480 }}
    >
      <header className="mb-6 text-black text-lg font-medium">
        Ny transaktion
      </header>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="mb-4">
          <Input
            id="description"
            name="description"
            type="text"
            label="Beskrivning"
            placeholder="Beskrivning"
            value={values.description}
            error={touched.description && errors.description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="mb-4">
          <Input
            {...FINANCIAL_DEFAULT_PROPS}
            id="amount"
            name="amount"
            label="Nettobelopp"
            placeholder="2 500,00"
            value={values.amount}
            error={touched.amount && errors.amount}
            onChange={({ floatValue }) => {
              setFieldValue('amount', floatValue)
            }}
            onBlur={handleBlur}
          />
          <div className="flex items-center space-x-1 pt-2">
            <Icon name="info" className="h-3 w-3 opacity-50" />
            <span className="text-gray-700 text-sm">
              Ange ett negativt belopp för att skapa en utgift
            </span>
          </div>
        </div>
        <div className="mb-6">
          <Datepicker
            id="date"
            name="date"
            label="Transaktionsdatum"
            selected={values.date}
            onChange={value => setFieldValue('date', value)}
            customInput={
              <DatepickerInput
                id="date"
                label="Välj datum"
                startDate={values.date}
              />
            }
          />
        </div>
        <div className="flex justify-between">
          <Button
            title="Avbryt"
            type="button"
            variant="tertiary"
            onClick={() => typeof onCancel === 'function' && onCancel()}
          />
          <Button
            title="Spara"
            type="submit"
            variant="primary"
            disabled={!isValid || !dirty || isSubmitting}
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </div>
  )
}

CreateTransaction.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CreateTransaction
