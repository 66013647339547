import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery, gql } from '@apollo/client'
import { toast } from 'react-hot-toast'
import { Select } from '@aider/ui'
import { track, events } from '@utils/analytics'

import FreeForm from './forms/FreeForm'
import RegistrationOfInterest from './forms/RegistrationOfInterest'

const TICKET_TYPES = gql`
  query ticketTypes {
    ticketTypes {
      id
      title
      name
    }
  }
`

const CREATE_TICKET = gql`
  mutation createTicket(
    $title: String!
    $message: String
    $organizationId: ID!
    $ticketTypeId: ID!
    $formData: JSON
  ) {
    createTicket(
      input: {
        title: $title
        organizationId: $organizationId
        ticketTypeId: $ticketTypeId
        message: { create: { message: $message, formData: $formData } }
      }
    ) {
      id
    }
  }
`

const CreateTicket = ({ onSuccess, onCancel }) => {
  const [selectedTicketType, setSelectedTicketType] = useState({
    id: '1',
    value: 'free-form',
    label: 'Fritext',
  })
  const { loading: loadingTicketTypes, data: { ticketTypes = [] } = {} } =
    useQuery(TICKET_TYPES)

  const [createTicket, { loading: creatingTicket }] = useMutation(
    CREATE_TICKET,
    {
      awaitRefetchQueries: true,
      refetchQueries: ['tickets'],
      onCompleted: useCallback(
        ({ createTicket: { id } = {} }) => {
          track(events.USER_CREATED_TICKET)
          onSuccess(id)
        },
        [onSuccess],
      ),
      onError: useCallback(() => {
        toast.error('Meddelandet kunde inte skickas.')
      }, []),
    },
  )

  const onSubmit = async ({ title, message, organization, formData }) => {
    console.log('onsubmit formData', formData)

    createTicket({
      variables: {
        title,
        ...(message && { message }),
        ticketTypeId: selectedTicketType.id,
        formData: JSON.stringify(formData),
        organizationId: organization.value,
      },
    })
  }

  return (
    <div
      className="p-6 bg-white rounded-lg max-h-screen overflow-hidden"
      style={{ width: 800 }}
    >
      <header className="mb-6 text-black text-lg font-medium">
        Nytt meddelande
      </header>
      <div className="mb-3">
        <Select
          id="ticketType"
          name="ticketType"
          label="Meddelandetyp"
          isLoading={loadingTicketTypes}
          onChange={e => setSelectedTicketType(e.currentTarget.value)}
          value={selectedTicketType}
          options={ticketTypes.map(({ id, name, title }) => ({
            id,
            value: name,
            label: title,
          }))}
        />
      </div>
      {/* Different forms depending on selected ticketType */}
      <div>
        {(() => {
          switch (selectedTicketType.value) {
            case 'free-form':
              return (
                <FreeForm
                  onCancel={onCancel}
                  submitForm={onSubmit}
                  creatingTicket={creatingTicket}
                />
              )
            case 'registration-of-interest':
              return (
                <RegistrationOfInterest
                  onCancel={onCancel}
                  submitForm={onSubmit}
                  creatingTicket={creatingTicket}
                />
              )
            default:
              return null
          }
        })()}
      </div>
    </div>
  )
}

CreateTicket.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CreateTicket
