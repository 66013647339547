import { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, gql, useApolloClient } from '@apollo/client'
import { Button, Checkbox } from '@aider/ui'
import { useFormik } from 'formik'
import { toast } from 'react-hot-toast'

const EXPORT_REPORT = gql`
  mutation ($reportId: ID!, $pagesToInclude: [PagesToInclude]) {
    exportReport(id: $reportId, pagesToInclude: $pagesToInclude) {
      id
      name
      from
      to
    }
  }
`

const USER_EMAIL = gql`
  query userEmail {
    me {
      id
      email
    }
  }
`

const ExportReport = ({ reportId, onSuccess }) => {
  const { data: { me: { email } = {} } = {} } = useQuery(USER_EMAIL)
  const client = useApolloClient()
  const [exportingReport, setExportingReport] = useState(false)
  const [exported, setExported] = useState(false)

  const { values, handleChange, handleSubmit, isValid } = useFormik({
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues: {
      frontpage: true,
      economicSummary: true,
      assetsDebt: true,
      incomesExpenses: true,
      verifications: false,
      ledger: false,
    },
    onSubmit: async formData => {
      const pagesToInclude = [
        ...(formData.frontpage ? ['FRONT_PAGE'] : []),
        ...(formData.economicSummary ? ['ECONOMIC_SUMMARY'] : []),
        ...(formData.assetsDebt ? ['ASSETS_DEBTS'] : []),
        ...(formData.incomesExpenses ? ['INCOMES_EXPENSES'] : []),
        ...(formData.verifications ? ['VERIFICATIONS'] : []),
        ...(formData.ledger ? ['LEDGER'] : []),
      ]

      try {
        setExportingReport(true)
        // Export report
        await client.mutate({
          mutation: EXPORT_REPORT,
          variables: { reportId, pagesToInclude },
        })
      } catch (error) {
        toast.error('Kunde exportera rapporten')
        setExportingReport(false)
        return
      }
      setExported(true)
      setExportingReport(false)
    },
  })

  if (!exported) {
    return (
      <div
        className="flex flex-1 flex-col justify-between p-6 bg-white rounded-lg overflow-hidden"
        style={{ width: 420 }}
      >
        <header className="mb-4 text-center text-black text-lg font-medium">
          Exportera rapport
        </header>
        <div className="mb-6 text-center">
          Välj vilka delar du vill ha med i din rapport nedan. Du får sedan en
          länk till din rapport på din mail{' '}
          <span className="font-medium">{email}</span>.
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col">
          <div className="mb-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="frontpage"
                name="frontpage"
                checked={values.frontpage}
                onCheckedChange={handleChange}
              />
              <label className="text-base font-medium" htmlFor="frontpage">
                Framsida
              </label>
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="economicSummary"
                name="economicSummary"
                checked={values.economicSummary}
                onCheckedChange={handleChange}
              />
              <label
                className="text-base font-medium"
                htmlFor="economicSummary"
              >
                Ekonomisk översikt
              </label>
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="assetsDebt"
                name="assetsDebt"
                checked={values.assetsDebt}
                onCheckedChange={handleChange}
              />
              <label className="text-base font-medium" htmlFor="assetsDebt">
                Tillgångar och skulder
              </label>
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="incomesExpenses"
                name="incomesExpenses"
                checked={values.incomesExpenses}
                onCheckedChange={handleChange}
              />
              <label
                className="text-base font-medium"
                htmlFor="incomesExpenses"
              >
                Inkomster & Utgifter
              </label>
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="verifications"
                name="verifications"
                checked={values.verifications}
                onCheckedChange={handleChange}
              />
              <label className="text-base font-medium" htmlFor="verifications">
                Verifikationslista (inkl. verifikatmapp)
              </label>
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="ledger"
                name="ledger"
                checked={values.ledger}
                onCheckedChange={handleChange}
              />
              <label className="text-base font-medium" htmlFor="ledger">
                Kategorispecifikation
              </label>
            </div>
          </div>
          <Button
            type="submit"
            title="Exportera"
            variant="primary"
            disabled={!isValid || exportingReport}
          />
        </form>
      </div>
    )
  }

  return (
    <div
      className="flex flex-1 flex-col justify-between p-6 bg-white rounded-lg overflow-hidden"
      style={{ width: 420 }}
    >
      <header className="mb-4 text-center text-black text-lg font-medium">
        Din rapport är på väg!
      </header>
      <div className="mb-6 text-center">
        Vi sammanställer din rapport. Det kan ta upp till 10 minuter. Så fort
        rapporten är klar mailar vi den till dig på{' '}
        <span className="font-medium">{email}</span>.
      </div>
      <Button
        type="button"
        title="Stäng"
        variant="primary"
        onClick={() => typeof onSuccess === 'function' && onSuccess()}
      />
    </div>
  )
}

ExportReport.propTypes = {
  reportId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default ExportReport
