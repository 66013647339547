import { useCallback, useMemo, useState, useEffect } from 'react'
import toast from 'react-hot-toast'

import { Dropdown, Button, FilterButton, Datepicker, useModal } from '@aider/ui'
import { format } from 'date-fns'

import { useQuery, gql, useReactiveVar } from '@apollo/client'
import { LoadingContainer } from '@components/'
import { formatMinutes, dateToYearRange } from '@utils/date'
import { getMessageAsHtml } from '@utils/draft-helpers'
import { activePrincipalIdVar } from '@/cache'
import HorizontalChart from './components/HorizontalChart'
import DrivingJournalItem from './components/DrivingJournalItem'
import TimeJournalItem from './components/TimeJournalItem'
import ReportIdentityHeader from './components/ReportIdentityHeader'
import PrintJournal from './components/PrintJournal'

// MARK: - GraphQL Queries
const DRIVING_JOURNAL_QUERY = gql`
  query drivingJournalQuery($principalId: ID!, $dateRange: DateRange) {
    principal(id: $principalId) {
      id
      name
      journalEntries(
        orderBy: [
          { column: DATE, order: ASC }
          { column: UPDATED_AT, order: DESC }
        ]
        # date: { from: "2022-01-01", to: "2022-12-31" }
        date: $dateRange
      ) {
        edges {
          node {
            id
            date
            description
            drivingLogEntry {
              id
              kilometers
              fromAddress
              toAddress
            }
          }
        }
      }
    }
  }
`

const TIME_JOURNAL_QUERY = gql`
  query timeJournalQuery($principalId: ID!, $dateRange: DateRange!) {
    principal(id: $principalId) {
      statistics(
        type: JOURNAL_ENTRIES_BY_CATEGORY
        # date: { from: "2022-01-01", to: "2022-12-31" }
        date: $dateRange
      ) {
        totalValue
        unit
        result {
          id
          name
          value
          journalEntries {
            id
            description
            minutes
            date
          }
        }
      }
    }
  }
`

const USER_DETAILS = gql`
  query userDetails {
    me {
      id
      name
      email
      decryptedPin
      address
      zipcode
      city
      principals {
        id
        name
        address
        zipcode
        city
        decryptedPin
      }
    }
  }
`

const JournalLedger = () => {
  // MARK: - State and attributes
  const [date, setDate] = useState(new Date())
  const dateRange = useMemo(() => dateToYearRange(date), [date])

  /** Holds the driving journal entries formatted for rendering */
  let drivingJournalEntries = []

  /** Holds the time journal entries formatted for rendering */
  let timeJournalEntries = []

  /** Holds the computed value of the total duration of all fetched journal entries */
  let totalJournalEntriesDuration = 0

  /** Access the active principal id from the cache */
  const principalId = useReactiveVar(activePrincipalIdVar)

  const {
    openModal: openPrintJournalModal,
    closeModal: closePrintJournalModal,
    isOpen: printJournalOpen,
    Modal: PrintJournalModal,
  } = useModal()
  const [selectedPrintSections, setSelectedPrintSections] = useState([])
  const [printEnabled, setPrintEnable] = useState(false)

  /** The principal and trustee data, used when printing the report */
  const {
    data: {
      me: { name, email, decryptedPin, address, zipcode, city, principals = [] } = {},
    } = {},
  } = useQuery(USER_DETAILS)
  const currentPrincipal = principals.find(
    principal => principal.id === principalId,
  )

  // MARK: - Requests

  const {
    data: {
      principal: {
        journalEntries: { edges: drivingJournalData = [] } = {},
      } = {},
    } = {},
  } = useQuery(
    DRIVING_JOURNAL_QUERY,
    {
      skip: !principalId,
      variables: { principalId, dateRange },
      fetchPolicy: 'network-only',
      onError: useCallback(() => {
        toast.error('Vi kunde inte hämta körjournalen')
      }, []),
    },
    [],
  )

  const {
    data: {
      principal: { statistics: { result: timeJournalData = [] } = {} } = {},
    } = {},
  } = useQuery(
    TIME_JOURNAL_QUERY,
    {
      skip: !principalId,
      variables: { principalId, dateRange },
      fetchPolicy: 'network-only',
      onError: useCallback(() => {
        toast.error('Vi kunde inte hämta dagboken')
      }, []),
    },
    [],
  )

  // MARK: - Methods

  /**
   * Computes the total driven distance
   * @returns The total driven distance based on the fetched driving journal entries
   */
  function getTotalDrivingDistance() {
    let res = 0
    drivingJournalEntries.forEach(item => {
      res += parseInt(item.distance, 10)
    })

    return res
  }

  /**
   * Computes the total duration of all fetched journal entries
   * @returns The total duration of all fetched journal entries
   */
  function getTotalDuration() {
    if (totalJournalEntriesDuration !== 0) {
      return totalJournalEntriesDuration
    }
    let sum = 0
    timeJournalEntries.forEach(entry => {
      sum += entry.duration
    })
    totalJournalEntriesDuration = sum

    return totalJournalEntriesDuration
  }

  /**
   * Prints the overview based on selection
   */
  function printReport(timeReport = true, drivingReport = true) {
    const commonText = document.getElementById("commonText")
    const timeText = document.getElementById("timeText")
    const drivingText = document.getElementById("drivingText")
    const timeJournal = document.getElementById("timeJournal")
    const drivingJournal = document.getElementById("drivingJournal")

    commonText.classList.remove("print:hidden")
    timeText.classList.remove("print:hidden")
    drivingText.classList.remove("print:hidden")
    timeJournal.classList.remove("print:hidden")
    drivingJournal.classList.remove("print:hidden")
    const tempTitle = document.title
    if (timeReport && drivingReport) {
      document.title = 'Dagbok & Körjournal'
    }
    if (!timeReport) {
      document.title = 'Körjournal'
      // hide timeReport section
      commonText.classList.add("print:hidden")
      timeText.classList.add("print:hidden")
      timeJournal.classList.add("print:hidden")
    }
    if (!drivingReport) {
      document.title = 'Dagbok'
      // hide drivingReport section
      commonText.classList.add("print:hidden")
      drivingText.classList.add("print:hidden")
      drivingJournal.classList.add("print:hidden")
    }

    if (typeof window !== 'undefined') {
      window.print()
      document.title = tempTitle
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (printEnabled) {
        printReport(selectedPrintSections.timeReport, selectedPrintSections.drivingReport)
        setPrintEnable(false)
      }
    }, 200) // <-- time for hiding modal

    return () => clearTimeout(timeout)
  }, [selectedPrintSections, printEnabled]) // <-- parameters to listen

  /**
   * Transforms the time journal entries into a format that can be rendered
   * @param {*} arr The time journal data received from the API
   * @returns The time journal entries formatted for rendering
   */
  // TODO: move to appropriative/create a new file in /utils folder
  function prepareTimeJournalEntries(arr) {
    let entries = [...arr]
    entries = entries.map((item, index) => ({
      category: item.name,
      id: index,
      duration: item.value,
      subEntries: item.journalEntries.map(entry => ({
        date: entry.date,
        duration: entry.minutes,
        summary: JSON.parse(entry.description).blocks[0].text,
        description: getMessageAsHtml(entry.description),
      })),
    }))

    return entries
  }

  /**
   * Transforms the driving journal entries into a format that can be rendered
   * @param {*} arr The driving journal data received from the API
   * @returns The driving journal entries formatted for rendering
   */
  // TODO: move to appropriative/create a new file in /utils folder
  function prepareDrivingJournalEntries(arr) {
    let entries = [...arr]
    entries = entries.filter(entry => entry.node.drivingLogEntry !== null)
    entries = entries.map(entry => ({
      id: entry.node.id,
      from: entry.node.drivingLogEntry.fromAddress,
      to: entry.node.drivingLogEntry.toAddress,
      distance: entry.node.drivingLogEntry.kilometers,
      date: entry.node.date,
      summary: JSON.parse(entry.node.description).blocks[0].text,
      description: getMessageAsHtml(entry.node.description),
    }))

    return entries
  }

  // Prepare data for rendering
  drivingJournalEntries = prepareDrivingJournalEntries(drivingJournalData)
  timeJournalEntries = prepareTimeJournalEntries(timeJournalData)

  return (
    <LoadingContainer>
      <div className="w-full mx-auto">
        <header className="sticky top-0 z-10 flex items-center justify-between h-20 bg-white print:h-7">
          <div className="flex items-center space-x-4 print:space-x-2">
            <div className="hidden print:block text-lg font-medium">
              <span id="timeText">Dagbok</span><span id="commonText"> & </span><span id="drivingText">Körjournal</span><span> {format(date, 'yyyy')}</span>
            </div>
          </div>
          <div className="flex items-center flex-1 space-x-4">
            <div className="text-2xl font-medium print:hidden">Sammanfattning & utskrift</div>
            <div className="print:hidden">
              <Dropdown.Root>
                <Dropdown.Trigger
                  title="Period"
                  value={format(date, 'yyyy')}
                  as={FilterButton}
                />
                <Dropdown.Content align="start" sideOffset={6}>
                  <Datepicker
                    selected={date}
                    startDate={date}
                    onChange={d => setDate(d)}
                    showYearPicker
                    inline
                  />
                </Dropdown.Content>
              </Dropdown.Root>
            </div>
          </div>
          <div className="print:hidden">
            <Button
              title="Skriv ut"
              variant="secondary"
              onClick={openPrintJournalModal}
            />
          </div>
        </header>
        <div className="pb-6 md:w-9/12 print:hidden">
          Här skriver du ut dagbok & körjournal på det sätt som du önskar, eller sparar ned den på din dator för att därefter ladda upp den som bilaga i din års- eller sluträkning, i det fall att du har möjlighet att redovisa digital.
        </div>

        <div className="flex-row hidden mt-12 mb-12 print:flex">
          {currentPrincipal && (
            <ReportIdentityHeader
              aiderRole="princial"
              name={currentPrincipal.name}
              pin={currentPrincipal.decryptedPin}
              address={currentPrincipal.address}
              zipcode={currentPrincipal.zipcode}
              city={currentPrincipal.city}
              email={currentPrincipal.email}
            />
          )}

          {name && decryptedPin && address && email && (
            <ReportIdentityHeader
              aiderRole="trustee"
              name={name}
              pin={decryptedPin}
              address={address}
              zipcode={zipcode}
              city={city}
              email={email}
            />
          )}
        </div>

        <div className="print:hidden">
          <HorizontalChart
            data={timeJournalEntries}
            totalDuration={getTotalDuration()}
          />
        </div>

        {/* Dagbok */}
        <div id="timeJournal">
          <div className="flex justify-between mt-16">
            <span className="text-lg font-medium">Dagbok</span>
            <span className="text-lg font-semibold">
              {formatMinutes(getTotalDuration())}
            </span>
          </div>

          {timeJournalEntries.length > 0 ? (
            <div className="divide-y divide-gray-300">
              {timeJournalEntries.map(item => (
                <TimeJournalItem
                  item={item}
                  totalDuration={getTotalDuration()}
                  key={item.id}
                />
              ))}
            </div>
          ) : (
            <div className="mt-2 text-sm font-medium text-gray-500">
              Det finns ingen data kopplat till datumet.
            </div>
          )}
        </div>

        {/* Körjournal */}
        <div id="drivingJournal">
          <div className="flex justify-between mt-16">
            <span className="text-lg font-medium">Körjournal</span>
            <span className="text-lg font-semibold">
              {getTotalDrivingDistance()}&nbsp;km
            </span>
          </div>
          {drivingJournalEntries.length > 0 ? (
            <div className="divide-y divide-gray-300">
              {drivingJournalEntries.map(item => (
                <DrivingJournalItem item={item} key={item.id} />
              ))}
            </div>
          ) : (
            <div className="mt-2 text-sm font-medium text-gray-500">
              Det finns ingen data kopplat till datumet.
            </div>
          )}
        </div>
      </div>
      <PrintJournalModal visible={printJournalOpen}>
        <PrintJournal
          onCancel={closePrintJournalModal}
          onSuccess={(data) => {
            closePrintJournalModal()
            setSelectedPrintSections(data)
            setPrintEnable(true)
          }}
        />
      </PrintJournalModal>
    </LoadingContainer>
  )
}

export default JournalLedger
