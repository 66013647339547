/**
 * Opens a URL in a new tab and focuses the tab
 *
 * @param  {String} url A valid url
 * @return {Object}     null
 */
export const openInNewTab = url => {
  if (typeof window === 'object') {
    const tab = window.open(url, '_blank')
    if (tab !== null) tab.focus()
  }

  return null
}
