import { forwardRef } from 'react'
import { Icon } from '@aider/ui'

const FilterButton = forwardRef(
  ({ label, placeholder, onClear, ...props }, ref) => {
    const hasLabel = !!label

    return (
      <div className="relative flex items-center">
        <button
          type="button"
          {...props}
          ref={ref}
          className={`bg-gray-200 text-sm font-medium px-4 py-2 rounded-full focus:outline-none ${hasLabel &&
            'pr-8 bg-blue-400 text-white'}`}
        >
          <span>{label || placeholder}</span>
        </button>
        {hasLabel && (
          <button
            className="absolute right-0 p-2 mr-2"
            type="button"
            onClick={onClear}
          >
            <Icon
              width={8}
              height={8}
              name="cross"
              className="fill-current text-white"
            />
          </button>
        )}
      </div>
    )
  },
)

export default FilterButton
