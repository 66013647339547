import PropTypes from 'prop-types'
import { Button, Icon } from '@aider/ui'

const ExportReport = ({ onSuccess }) => (
  <div
    className="flex flex-1 flex-col justify-between p-6 bg-white rounded-lg overflow-hidden"
    style={{ width: 420 }}
  >
    <header className="mb-4 text-center text-black text-lg font-medium">
      Låst
    </header>
    <div className="flex mb-6 justify-center">
      <Icon
        name="lock-closed"
        className="fill-current text-blue-500 w-10 h-10"
      />
    </div>
    <div className="mb-6 text-center">
      Din rapport är låst eftersom du skickat in och signerat den. Om du behöver
      komplettera rapporten så kommer den låsas upp och du kommer få ett
      meddelande i Aider.
    </div>
    <Button
      type="button"
      title="Jag förstår"
      variant="primary"
      onClick={() => typeof onSuccess === 'function' && onSuccess()}
    />
  </div>
)

ExportReport.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default ExportReport
