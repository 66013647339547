import { Switch, Route, Redirect } from 'react-router-dom'

import PremiumLayout from '@features/PremiumLayout'
import Reports from './Reports'
import Report from './Report'

const ReportsLayout = () => (
  <PremiumLayout>
    <Switch>
      <Route exact path="/huvudman/rapporter" component={Reports} />
      <Route exact path="/huvudman/rapporter/:reportId" component={Report} />
      <Route render={() => <Redirect to="/huvudman/rapporter" />} />
    </Switch>
  </PremiumLayout>
)

export default ReportsLayout
