import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { from, ApolloClient, Observable } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { toast } from 'react-hot-toast'

import { cache, isLoggedInVar } from '@/cache'

// Link that caches the authtoken and logs user out on 401 (unauthorized) responses
let token
const authLink = setContext(({ headers }) => {
  // if there is a cached token, return it immediately
  if (token) return token

  const bearer = `Bearer ${localStorage.getItem('accessToken')}`

  token = {
    headers: {
      ...headers,
      accept: 'application/json',
      authorization: bearer,
    },
  }

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(`BEARER TOKEN:\n${bearer}`)
  }

  return token
}).concat(
  onError(({ networkError }) => {
    if (networkError && networkError.statusCode === 401) {
      if (token !== null) {
        token = null
        toast('Du har loggats ut automatiskt. Logga in för att fortsätta.')
        // eslint-disable-next-line no-use-before-define
        logout()
      }

      // Return an empty observable for subsequent requests on logout because apollo is shit
      // @see https://github.com/apollographql/apollo-client/issues?q=error+link+%2B+networkErrors
      return Observable.of({ data: {}, error: true })
    }

    return false
  }),
)

// Upload link (replaces HTTP Link)
const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_AIDER_API_URL}/graphql`,
})

// Apollo client
export const client = new ApolloClient({
  link: from([authLink, uploadLink]),
  cache,
})

// Logout action
export const logout = () => {
  // Reset cached token on the client
  token = null

  localStorage.removeItem('accessToken')
  localStorage.removeItem('expiresAt')

  // Set isLoggedIn to false, which will redirect user to '/login'
  isLoggedInVar(false)

  client.stop()
  client.clearStore()
}

// Check if the user is logged in and update apollo state
isLoggedInVar(!!localStorage.getItem('accessToken'))
