import { Fragment, useCallback, useState, useEffect } from 'react'
import { useMutation, gql } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useModal, Icon, Button } from '@aider/ui'

import { useOnPremiumOrTrial } from '@hooks/'
import { goToStripeCustomerPortal } from '@utils/stripe'
import PremiumImage from '@assets/images/accounting-screenshot.png'

const START_TRIAL = gql`
  mutation startTrial {
    startTrial {
      id
      onTrial
      trialEndsAt
    }
  }
`

const PremiumLayout = ({ children }) => {
  const [calledOnce, setCalledOnce] = useState(false)
  const history = useHistory()
  const { onPremium, trialHasEnded, loading } = useOnPremiumOrTrial()
  const { openModal, closeModal, isOpen, Modal } = useModal()

  const [startTrial] = useMutation(START_TRIAL, {
    onCompleted: useCallback(() => {
      closeModal()
    }, [closeModal]),
  })

  useEffect(() => {
    if (onPremium !== undefined && !onPremium && !loading && !calledOnce) {
      setCalledOnce(true)
      openModal()
    }
  }, [onPremium, loading, openModal, calledOnce])

  // Route user back to dagbok if not on premium
  const handleModalClose = useCallback(() => {
    if (!onPremium) {
      history.push('/huvudman/dagbok')
    }
  }, [onPremium, history])

  return (
    <Fragment>
      {onPremium && !loading && children}
      {/* Premium Modal */}
      <Modal visible={isOpen} onClose={handleModalClose}>
        {!trialHasEnded ? (
          <div
            className="bg-white rounded-xl overflow-hidden"
            style={{ width: 480 }}
          >
            <div className="px-16 bg-blue-500 overflow-hidden">
              <img
                className="w-86 h-auto rounded-md shadow-lg transform translate-y-16"
                src={PremiumImage}
                alt="Vår bokföringsfunktion"
              />
            </div>
            <div className="px-6 py-8 text-center">
              <div className="inline-flex items-center justify-center mb-4 px-2.5 py-0.5 bg-blue-500 rounded-md space-x-1">
                <Icon
                  name="diamond"
                  className="w-3 h-3 text-blue-300 fill-current"
                />
                <span className="text-white text-sm font-medium">Premium</span>
              </div>
              <div className="mb-3 text-lg font-medium">
                Du hittade precis en Premium-funktion
              </div>
              <div className="mb-8 text-gray-800 text-base">
                Med Aider Premium är det enkelt att jobba med din ekonomiska
                redovisning. Importera transaktioner direkt från banken och
                bokför löpande under året. Allt sparas digitalt och går att få
                ut som en överskådlig års- eller sluträkning när du vill.
              </div>

              <div className="flex justify-center space-x-3">
                <Button
                  onClick={startTrial}
                  variant="secondary"
                  title="Prova i 30 dagar"
                />
                <Button
                  onClick={goToStripeCustomerPortal}
                  variant="primary"
                  title="Skaffa premium"
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="bg-white rounded-xl overflow-hidden"
            style={{ width: 320 }}
          >
            <div className="px-6 py-8 text-center">
              <div className="mb-3 text-lg font-medium">
                Din provperiod har gått ut
              </div>
              <div className="mb-8 text-gray-800 text-base">
                Uppgradera ditt konto för att fortsätta använda Aider Premium.
              </div>
              <div className="flex justify-center space-x-3">
                <Button
                  onClick={closeModal}
                  variant="secondary"
                  title="Avbryt"
                />
                <Button
                  onClick={goToStripeCustomerPortal}
                  variant="primary"
                  title="Skaffa premium"
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    </Fragment>
  )
}

export default PremiumLayout
