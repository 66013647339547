import PropTypes from 'prop-types'
import { useState, useCallback } from 'react'
import { useApolloClient, useQuery, gql } from '@apollo/client'
import { SelectCreatable } from '@aider/ui'
import { Field } from 'formik'

const GET_TAGS_FOR_VERIFICATION = gql`
  query ($verificationAccountId: ID, $principalId: ID) {
    tags(
      verificationAccountId: $verificationAccountId
      principalId: $principalId
    ) {
      id
      name
    }
  }
`

const CREATE_TAG = gql`
  mutation createTag(
    $principalId: ID!
    $verificationAccountId: ID!
    $name: String!
  ) {
    createTag(
      input: {
        principalId: $principalId
        verificationAccountId: $verificationAccountId
        name: $name
      }
    ) {
      id
      name
    }
  }
`

const Tags = ({
  value,
  field,
  setFieldValue,
  verificationAccountId,
  principalId,
}) => {
  const [tags, setTags] = useState([])
  const client = useApolloClient()
  const {
    loading: loadingTags,
    data,
    refetch,
  } = useQuery(GET_TAGS_FOR_VERIFICATION, {
    variables: {
      principalId,
      verificationAccountId,
    },
    fetchPolicy: 'network-only',
    onCompleted: useCallback(
      ({ tags: myTags = [] }) => {
        setTags(
          myTags.map(item => ({
            value: item.id,
            label: item.name,
          })),
        )
      },
      [tags],
    ),
  })

  const handleCreate = async (name, Va) => {
    // Create tag
    const { data: { createTag } = {} } = await client.mutate({
      mutation: CREATE_TAG,
      variables: {
        principalId,
        verificationAccountId: Va?.value || undefined,
        name,
      },
    })

    // Add created tag to list of tags
    const newTag = {
      label: createTag.name,
      value: createTag.id,
    }

    setTags([newTag, ...tags])

    // Set value to current tag
    setFieldValue(`${field}.tag`, newTag)
  }

  return (
    <Field
      as={SelectCreatable}
      id={`${field}.tag`}
      name={`${field}.tag`}
      value={value.tag}
      isClearable
      options={tags}
      onCreateOption={e => handleCreate(e, value.verificationAccount)}
      formatCreateLabel={inputValue => `Skapa taggen ”${inputValue}”`}
      onChange={test => {
        setFieldValue(`${field}.tag`, test)
      }}
      disabled={value.verificationAccount == null}
      placeholder="Välj eller sök..."
      disabledPlaceholder="Välj kategori först"
    />
  )
}

Tags.propTypes = {
  verificationAccountId: PropTypes.string.isRequired,
  principalId: PropTypes.string.isRequired,
}

export default Tags
