import PropTypes from 'prop-types'

const CurrencyCell = ({ amount, ...props }) => (
  <span {...props}>
    {new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: 'SEK',
    }).format(amount)}
  </span>
)

CurrencyCell.defaultProps = {
  amount: 0,
}

CurrencyCell.propTypes = {
  amount: PropTypes.number,
}

export default CurrencyCell
