import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, RadioGroup } from '@aider/ui'

const validationSchema = Yup.object().shape({
  selected: Yup.string().required('A radio option is required'),
})

const OPTIONS = {
  verified: { label: 'Bokförda', value: true },
  unverified: { label: 'Ej bokförda', value: false },
}

const VerifiedFilter = ({ onFilterSubmit, initialValues }) => {
  const { isValid, values, setFieldValue, handleSubmit } = useFormik({
    initialValues:
      typeof initialValues === 'boolean'
        ? { selected: initialValues ? 'verified' : 'unverified' }
        : {},
    validationSchema,
    onSubmit: ({ selected }) => {
      const value = OPTIONS[selected]
      onFilterSubmit(value)
    },
  })

  return (
    <form className="flex flex-col space-y-3 p-3 w-56" onSubmit={handleSubmit}>
      <RadioGroup.Root
        name="selected"
        className="flex flex-col space-y-2"
        defaultValue={values.selected}
        onValueChange={v => setFieldValue('selected', v)}
      >
        {Object.keys(OPTIONS).map(key => {
          const option = OPTIONS[key]
          return (
            <label
              key={key}
              htmlFor={key}
              className="flex items-center space-x-1"
            >
              <RadioGroup.Item value={key} id={key}>
                <RadioGroup.Indicator />
              </RadioGroup.Item>
              <span>{option.label}</span>
            </label>
          )
        })}
      </RadioGroup.Root>
      <Button disabled={!isValid} type="submit" title="Spara" />
    </form>
  )
}

VerifiedFilter.defaultProps = {
  initialValues: undefined,
}

VerifiedFilter.propTypes = {
  onFilterSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.bool,
}

export default VerifiedFilter
