import { gql, useQuery } from '@apollo/client'

const VERIFICATION_ACCOUNTS = gql`
  query verificationAccounts($accountTypes: [ID!]) {
    verificationAccounts(
      orderBy: { column: SORTING_PRIORITY, order: DESC }
      verificationAccountTypeId: $accountTypes
    ) {
      id
      name
      description
    }
  }
`

export const ACCOUNT_TYPES = {
  expenses: [1, 4],
  incomes: [2, 3, 5],
}

const useVerificationAccounts = (options = {}) => {
  const result = useQuery(VERIFICATION_ACCOUNTS, options)
  return result
}

export default useVerificationAccounts
