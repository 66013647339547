import { useState, useCallback } from 'react'
import { useQuery, gql } from '@apollo/client'
import uniqby from 'lodash.uniqby'

const PRINCIPAL_ORGANIZATIONS = gql`
  query principalOrganizations {
    me {
      id
      principals {
        id
        organization {
          id
          active
          name
        }
      }
    }
  }
`

/**
 * Get the users principal active organizations
 *
 * @return {Object}
 */
const useActiveOrganization = () => {
  const [hasActiveOrganization, setActive] = useState()
  const [activeOrganizations, setOrganizations] = useState([])

  const { loading, error } = useQuery(PRINCIPAL_ORGANIZATIONS, {
    onCompleted: useCallback(
      ({ me: { principals = [] } = {} }) => {
        // Get all unique organizations
        const orgs = principals
          .map(({ organization: o }) => o)
          .filter(o => o.active)
        const unique = uniqby(orgs, 'id')

        setActive(!!unique.length)
        setOrganizations(unique)
      },
      [setActive, setOrganizations],
    ),
  })

  return { hasActiveOrganization, activeOrganizations, loading, error }
}

export default useActiveOrganization
