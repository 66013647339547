import PropTypes from 'prop-types'
import { Loader } from '@aider/ui'
import { motion, AnimatePresence } from 'framer-motion'

const FileInput = ({ title, loading, onChange }) => (
  <motion.div
    key="initial"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    <AnimatePresence exitBeforeEnter>
      {loading ? (
        <motion.div
          key="loading"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.2 }}
        >
          <Loader />
        </motion.div>
      ) : (
        <motion.div
          key="content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <label className="text-blue-600 font-medium focus:outline-none hover:opacity-75 cursor-pointer transition-opacity duration-200 ease-in-out">
            {title}
            <input type="file" className="hidden" onChange={onChange} />
          </label>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
)

FileInput.defaultProps = {
  title: 'Ladda upp',
  loading: false,
}

FileInput.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default FileInput
