import { Switch, Route, Link, Redirect } from 'react-router-dom'
import { Icon } from '@aider/ui'

import { LoadingContainer } from '@components/'

import SelectImportOption from './SelectImportOption'
import SelectImportTransactions from './SelectImportTransactions'

const ImportLayout = () => (
  <LoadingContainer>
    <header className="sticky z-10 top-0 flex items-center py-4 h-20 bg-white space-x-3">
      <Link
        to="/huvudman/redovisning/transaktioner"
        className="text-blue-500 text-2xl font-medium hover:opacity-50 transition-opacity"
      >
        ...
      </Link>
      <Icon
        name="chevron-right"
        className="flex-shrink-0 w-2.5 h-2.5 text-black fill-current"
      />
      <div className="text-2xl font-medium">Importera transaktioner</div>
    </header>
    <Switch>
      <Route
        exact
        path="/huvudman/redovisning/transaktioner/importera"
        component={SelectImportOption}
      />
      <Route
        exact
        path="/huvudman/redovisning/transaktioner/importera/:importId"
        component={SelectImportTransactions}
      />
      <Route
        render={() => (
          <Redirect to="/huvudman/redovisning/transaktioner/importera" />
        )}
      />
    </Switch>
  </LoadingContainer>
)

export default ImportLayout
