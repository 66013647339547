import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import { LoadingContainer } from '@components/'

import Tickets from './Tickets'
import Ticket from './Ticket'

const TicketsLayout = () => {
  const location = useLocation()

  return (
    <LoadingContainer>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location?.pathname}>
          <Route exact path="/meddelanden" component={Tickets} />
          <Route path="/meddelanden/:ticketId" component={Ticket} />
          <Route render={() => <Redirect to="/meddelanden" />} />
        </Switch>
      </AnimatePresence>
    </LoadingContainer>
  )
}

export default TicketsLayout
