import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, TextArea, Input, Select } from '@aider/ui'
import { useActiveOrganization } from '@hooks/'

const validationSchema = Yup.object().shape({
  organization: Yup.object()
    .shape({
      value: Yup.string().required('Välj en kommun i listan'),
      label: Yup.string().required(),
    })
    .required('Obligatoriskt fält.'),
  title: Yup.string().required('Obligatoriskt fält.'),
  message: Yup.string().required('Obligatoriskt fält.'),
})

const FreeForm = ({ onCancel, submitForm, creatingTicket }) => {
  const { activeOrganizations, loading: loadingOrganizations } =
    useActiveOrganization()

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    isValid,
  } = useFormik({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      organization: null,
      title: '',
      message: '',
    },
    onSubmit: formData => {
      submitForm(formData)
    },
  })

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <div className="mb-3">
            <Select
              id="organization"
              name="organization"
              label="Mottagare"
              placeholder="Välj mottagare"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.organization}
              error={errors.organization?.value}
              isLoading={loadingOrganizations}
              options={activeOrganizations.map(({ name, id }) => ({
                value: id,
                label: name,
              }))}
            />
          </div>
          <div className="mb-3">
            <Input
              id="title"
              name="title"
              type="text"
              label="Rubrik"
              placeholder="Rubrik"
              value={values.title}
              error={touched.title && errors.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="mb-3">
            <TextArea
              id="message"
              name="message"
              rows={5}
              label="Meddelande"
              placeholder="Meddelande"
              value={values.message}
              error={touched.message && errors.message}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <Button
            variant="secondary"
            type="button"
            title="Avbryt"
            onClick={() => typeof onCancel === 'function' && onCancel()}
          />
          <Button
            title="Skicka"
            type="submit"
            disabled={!isValid || !dirty}
            isLoading={creatingTicket || isSubmitting}
            variant="primary"
          />
        </div>
      </form>
    </div>
  )
}

export default FreeForm
