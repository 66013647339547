import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Datepicker } from '@aider/ui'
import { format } from 'date-fns'

const validationSchema = Yup.object().shape({
  // Make this nullable since it's not possible to select a endDate without a startDate
  from: Yup.date()
    .required()
    .nullable(),
  to: Yup.date()
    .required()
    .typeError('Välj ett giltigt datumspann'),
})

const DateFilter = ({ onFilterSubmit, initialValues }) => {
  const { isValid, values, setFieldValue, handleSubmit } = useFormik({
    initialValues: initialValues
      ? { from: new Date(initialValues.from), to: new Date(initialValues.to) }
      : { from: new Date(), to: null },
    validationSchema,
    onSubmit: ({ from, to }) => {
      const formattedFrom = format(from, 'yyyy-MM-dd')
      const formattedTo = format(to, 'yyyy-MM-dd')
      onFilterSubmit({
        value: { from: formattedFrom, to: formattedTo },
        label: `${formattedFrom} - ${formattedTo}`,
      })
    },
  })

  const handleDateChange = dates => {
    const [startDate, endDate] = dates
    setFieldValue('from', startDate, false)
    setFieldValue('to', endDate)
  }

  return (
    <form className="flex flex-col space-y-3" onSubmit={handleSubmit}>
      <Datepicker
        id="date"
        name="date"
        inline
        onChange={handleDateChange}
        startDate={values.from}
        endDate={values.to}
        selected={values.from}
        shouldCloseOnSelect={false}
        selectsRange
      />
      <div className="flex w-full px-3 pb-3">
        <Button
          disabled={!isValid}
          type="submit"
          title="Spara"
          className="w-full"
        />
      </div>
    </form>
  )
}

DateFilter.defaultProps = {
  initialValues: undefined,
}

DateFilter.propTypes = {
  onFilterSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
}

export default DateFilter
