import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useQuery, useMutation, gql } from '@apollo/client'
import { Button, TextArea } from '@aider/ui'
import { toast } from 'react-hot-toast'

import { track, events } from '@utils/analytics'

const validationSchema = Yup.object().shape({
  message: Yup.string().required('Obligatoriskt fält.'),
})

const READ_TICKET = gql`
  mutation readTicket($ticketId: ID!) {
    readTicket(id: $ticketId) {
      id
      updatedAt
    }
  }
`

const TICKET_OPEN = gql`
  query ticketOpen($ticketId: ID!) {
    ticket(id: $ticketId) {
      id
      open
    }
  }
`

const TICKET_MESSAGE_FRAGMENT = gql`
  fragment ticketMessage on TicketMessage {
    id
    message
    createdAt
    user {
      id
      name
    }
  }
`

const SEND_MESSAGE = gql`
  mutation sendMessage($ticketId: ID!, $message: String!) {
    createTicketMessage(ticketId: $ticketId, message: $message) {
      ...ticketMessage
    }
  }
  ${TICKET_MESSAGE_FRAGMENT}
`

const TOGGLE_OPEN = gql`
  mutation toggleOpen($ticketId: ID!, $open: Boolean) {
    upsertTicket(id: $ticketId, open: $open) {
      id
      open
    }
  }
`

const CreateTicketMessage = ({ ticketId, onSuccess, onCancel }) => {
  const { data: { ticket: { open } = {} } = {} } = useQuery(TICKET_OPEN, {
    variables: { ticketId },
  })

  const [readTicket] = useMutation(READ_TICKET, {
    onError: useCallback(() => {
      toast.error('Kunde inte uppdatera tråden')
    }, []),
    update(cache, { data: { readTicket: updatedTicket } = {} }) {
      cache.modify({
        id: cache.identify(updatedTicket),
        fields: {
          updatedAt() {
            return updatedTicket.updatedAt
          },
        },
      })
    },
  })

  const [toggleOpen] = useMutation(TOGGLE_OPEN, {
    onCompleted: useCallback(() => {
      track(events.USER_OPENED_TICKET)
    }, []),
    onError: useCallback(() => {
      toast.error('Kunde inte uppdatera tråden')
    }, []),
    update(cache, { data: { upsertTicket } = {} }) {
      cache.modify({
        id: cache.identify(upsertTicket),
        fields: {
          open() {
            return upsertTicket.open
          },
        },
      })
    },
  })

  const [sendMessage] = useMutation(SEND_MESSAGE, {
    awaitRefetchQueries: true,
    refetchQueries: ['ticket'],
    onCompleted: useCallback(async () => {
      track(events.USER_SENT_TICKET_MESSAGE)
      if (!open) {
        await toggleOpen({ variables: { ticketId, open: true } })
      }

      await readTicket({ variables: { ticketId } })

      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }, [onSuccess, open, readTicket, ticketId, toggleOpen]),
    onError: useCallback(err => {
      console.log(err)
      toast.error('Kunde inte skicka meddelandet')
    }, []),
  })

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    isValid,
  } = useFormik({
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: { message: '' },
    onSubmit: async ({ message }) => {
      sendMessage({ variables: { ticketId, message } })
    },
  })

  return (
    <div className="p-6 bg-white rounded-lg" style={{ width: 480 }}>
      <header className="mb-6 text-black text-lg font-medium">
        Skriv svar
      </header>
      <form onSubmit={handleSubmit}>
        <TextArea
          id="message"
          name="message"
          type="text"
          label="Meddelande"
          rows={5}
          placeholder="Skriv ditt meddelande här"
          value={values.message}
          error={touched.message && errors.message}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="flex justify-between pt-6">
          <Button
            variant="secondary"
            type="button"
            title="Avbryt"
            onClick={() => typeof onCancel === 'function' && onCancel()}
          />
          <Button
            title="Skicka"
            type="submit"
            disabled={!isValid || !dirty || isSubmitting}
            isLoading={isSubmitting}
            variant="primary"
          />
        </div>
      </form>
    </div>
  )
}

CreateTicketMessage.defaultProps = {
  ticketId: '',
}

CreateTicketMessage.propTypes = {
  ticketId: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CreateTicketMessage
