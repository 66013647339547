import PropTypes from 'prop-types'

import { DateCell } from '@components/'

const TicketMessage = ({ id, message, createdBy, isSender, createdAt }) => (
  <div className={`flex ${isSender && 'justify-end'} `}>
    <div className="max-w-lg">
      <div
        className={`pr-5 pl-5 pt-4 pb-4 rounded-tl-xl rounded-tr-xl ${
          isSender
            ? 'bg-blue-500 rounded-bl-xl text-white'
            : 'bg-gray-300 rounded-br-xl text-black'
        }`}
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <div className="flex flex-row items-center justify-between py-2">
        <span className="flex items-center px-2 text-sm font-medium space-x-1">
          <div>{createdBy}</div>
        </span>
        <div className="flex-0 flex px-2 text-gray-800 text-sm">
          <DateCell type="distance" date={createdAt} />
        </div>
      </div>
    </div>
  </div>
)

TicketMessage.propTypes = {
  message: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  isSender: PropTypes.bool.isRequired,
}

export default TicketMessage
