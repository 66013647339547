import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Input, FINANCIAL_DEFAULT_PROPS } from '@aider/ui'

const validationSchema = Yup.object().shape({
  from: Yup.number().required('Fyll i ett giltigt värde'),
  to: Yup.number().required('Fyll i ett giltigt värde'),
})

const NumberFormat = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
})

const AmountFilter = ({ initialValues, onFilterSubmit }) => {
  const {
    isValid,
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: initialValues || {},
    validationSchema,
    onSubmit: ({ from, to }) => {
      onFilterSubmit({
        value: { from, to },
        label: `${NumberFormat.format(from)} - ${NumberFormat.format(to)}`,
      })
    },
  })

  return (
    <form className="flex flex-col space-y-3 p-3 w-64" onSubmit={handleSubmit}>
      <Input
        {...FINANCIAL_DEFAULT_PROPS}
        id="from"
        name="from"
        label="Belopp från"
        placeholder="-5 000 kr"
        value={values.from}
        error={touched.from && errors.from}
        onChange={({ floatValue }) => {
          setFieldValue('from', floatValue)
        }}
        onBlur={handleBlur}
      />
      <Input
        {...FINANCIAL_DEFAULT_PROPS}
        id="to"
        name="to"
        label="Belopp till"
        placeholder="5 000 kr"
        value={values.to}
        error={touched.to && errors.to}
        onChange={({ floatValue }) => {
          setFieldValue('to', floatValue)
        }}
        onBlur={handleBlur}
      />
      <Button disabled={!isValid} type="submit" title="Spara" />
    </form>
  )
}

AmountFilter.defaultProps = {
  initialValues: undefined,
}

AmountFilter.propTypes = {
  onFilterSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
}

export default AmountFilter
