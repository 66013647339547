import { matchPath } from 'react-router'
import { gql, useQuery } from '@apollo/client'
import { useLocation, Link } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { Logo, Icon, Avatar, Dropdown } from '@aider/ui'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'

import { Pressable } from '@components/'
import {
  useOnPremiumOrTrial,
  EventEmitterContext,
  useEventEmitterInstance,
  events,
} from '@hooks/'
import { formatDistanceToNow } from '@utils/date'
import { intercom } from '@utils/intercom'
import { goToStripeCustomerPortal } from '@utils/stripe'
import { logout } from '@/apollo-client'

const USER_DETAILS = gql`
  query userDetails {
    me {
      id
      name
      email
      ticketsUnreadCount
    }
  }
`

const AppLayout = ({ children }) => {
  const { onTrial, trialEndsAt } = useOnPremiumOrTrial()
  const eventEmitter = useEventEmitterInstance()

  const scrollRef = useBottomScrollListener(
    () => {
      eventEmitter.emit(events.CONTENT_SCROLL_BOTTOM)
    },
    { offset: 0, debounce: 100, triggerOnNoScroll: true },
  )

  const {
    data: { me: { name, email, ticketsUnreadCount: unreadCount } = {} } = {},
  } = useQuery(USER_DETAILS)

  return (
    <EventEmitterContext.Provider value={eventEmitter}>
      <div className="flex flex-col">
        {/* Header */}
        <header className="z-10 print:hidden bg-blue-800">
          <AnimatePresence>
            {onTrial && (
              <motion.div
                className="overflow-hidden"
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { height: 'auto' },
                  collapsed: { height: 0 },
                }}
                transition={{ duration: 0.25 }}
              >
                <div className="flex items-center justify-center px-6 h-8 bg-blue-600 space-x-2">
                  <div className="text-white text-sm font-medium opacity-75 truncate">
                    <span className="mr-1">
                      Din provperiod av Aider Premium löper ut
                    </span>
                    <span>{formatDistanceToNow(trialEndsAt)}</span>
                  </div>
                  <button
                    type="button"
                    onClick={goToStripeCustomerPortal}
                    className="flex items-center text-white whitespace-nowrap text-sm font-medium focus:outline-none hover:opacity-50 space-x-1 transition-opacity"
                  >
                    <span>Påbörja prenumeration</span>
                    <Icon
                      name="arrow-top-right"
                      className="w-2.5 h-2.5 text-white fill-current"
                    />
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="wrapper flex items-center justify-between mx-auto h-16 text-white">
            <Link to="/huvudman/dagbok">
              <Logo variant="badge" className="w-10" />
            </Link>
            <div className="flex space-x-8">
              <NavLink to="/huvudman">Uppdrag</NavLink>
              <NavLink to="/meddelanden" unreadCount={unreadCount}>
                Meddelanden
              </NavLink>
              <NavLink to="/installningar">Inställningar</NavLink>
              <NavLink onClick={() => intercom('showNewMessage')}>
                Support
              </NavLink>
              <Dropdown.Root>
                <Dropdown.Trigger className="flex items-center focus:outline-none space-x-2">
                  <Avatar name={name} />
                  <Icon
                    name="chevron-down"
                    className="w-2.5 h-2.5 text-white fill-current"
                  />
                </Dropdown.Trigger>
                <Dropdown.Content sideOffset={-6}>
                  <Dropdown.Group>
                    <div className="py-2">
                      <Dropdown.Label>Inloggad som</Dropdown.Label>
                      <div className="px-2 text-sm font-medium">{name}</div>
                      <div className="px-2 text-gray-800 text-sm">{email}</div>
                    </div>
                  </Dropdown.Group>
                  <Dropdown.Separator />
                  <Dropdown.Group>
                    <Dropdown.Item onSelect={() => logout()}>
                      Logga ut
                    </Dropdown.Item>
                  </Dropdown.Group>
                </Dropdown.Content>
              </Dropdown.Root>
            </div>
          </div>
        </header>
        {/* Main Container */}
        <div
          className="print:h-auto print:overflow-visible overflow-y-auto"
          style={{
            height: `calc(100vh - ${onTrial ? '6rem' : '4rem'})`,
            scrollBehavior: 'smooth',
          }}
          ref={scrollRef}
        >
          <div className="wrapper flex mx-auto w-full print:h-auto h-full">
            {children}
          </div>
        </div>
      </div>
    </EventEmitterContext.Provider>
  )
}

const NavLink = ({ children, to, icon, unreadCount, ...props }) => {
  const { pathname } = useLocation()
  const active = matchPath(pathname, to)

  return (
    <Pressable
      className="group relative flex items-center h-16 text-base font-medium focus:outline-none"
      to={to}
      {...props}
    >
      <div className="relative">
        <div
          className={`${
            active ? 'opacity-1' : 'opacity-60'
          } transition-opacity`}
        >
          {children}
        </div>
        <AnimatePresence>
          {unreadCount > 0 && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="absolute -top-1 -right-2 bg-red px-1 text-white text-xs rounded-full"
            >
              {unreadCount}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div
        className={`h-1 rounded-t absolute bottom-0 inset-x-0 transition-colors duration-100 ${
          active
            ? 'bg-blue-500 group-hover:bg-blue-500'
            : 'bg-transparent group-hover:bg-blue-700'
        }`}
      />
    </Pressable>
  )
}

export default AppLayout
