import PropTypes from 'prop-types'

const EmptyState = ({ icon, title, description, action }) => (
  <div className="flex flex-col items-center justify-center max-w-xs">
    {icon}
    <div className="mt-4 text-center">
      <div className="mb-2 text-lg font-medium">{title}</div>
      <div className="mb-4 text-gray-800 text-base">{description}</div>
      <div className="flex justify-center space-x-2">{action && action}</div>
    </div>
  </div>
)

EmptyState.defaultProps = {
  icon: null,
  description: '',
  action: null,
}

EmptyState.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  action: PropTypes.node,
}

export default EmptyState
