import React, { useState } from 'react'
import { Icon } from '@aider/ui'
import { formatMinutes } from '@utils/date'
import { slugify } from '@utils/draft-helpers'
import TimeJournalLineDetail from './TimeJournalLineDetail'

const TimeJournalItem = props => {
  const [isOpen, setIsOpen] = useState(false)

  /**
   * Handles the click on a chevron to fold/unfold
   * the sub-entries.
   */
  function toggleOpenDetails() {
    setIsOpen(!isOpen)
  }

  return (
    <div className="py-4">
      <div className="flex justify-between font-medium print:text-sm pt-1">
        <div className="flex items-center">
          <Icon
            name="chevron-down"
            onClick={toggleOpenDetails}
            className={`w-3 h-3 fill-current transition-transform duration-250 ease-in-out cursor-pointer transform print:hidden ${
              isOpen ? 'rotate-180' : 'rotate-0'
            }`}
          />
          <span
            onClick={toggleOpenDetails}
            className="pl-2 cursor-pointer print:pl-0"
          >
            {props.item.category}{' '}
            <span className="pl-2 text-gray-400">
              {props.item.subEntries.length}
            </span>
          </span>
        </div>
        <div className="flex items-baseline space-x-6">
          <span className="">
            {((props.item.duration / props.totalDuration) * 100).toFixed(0)}
            &nbsp;%
          </span>
          <span className="">{formatMinutes(props.item.duration)}</span>
        </div>
      </div>
      {/* We show/hide with CSS to easily show everything on print */}
      <div className={`print:block details-block ${isOpen ? 'block' : 'hidden'}`}>
        {props.item.subEntries.map((data) => (
          <TimeJournalLineDetail
            data={data}
            key={slugify(`${data.date}-${data.duration}-${data.description}`)}
          />
        ))}
      </div>
    </div>
  )
}

export default TimeJournalItem
