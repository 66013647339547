import NumberFormat from 'react-number-format'
import { FINANCIAL_DEFAULT_PROPS } from '@aider/ui'

// Omit type from props, this is not an input
const { type, ...propsWithoutType } = FINANCIAL_DEFAULT_PROPS

const BalanceCell = ({ value }) => (
  <NumberFormat
    {...propsWithoutType}
    className={`rounded-md font-medium px-2 py-0.5 transition truncate max-w-xs ${
      value === 0 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
    }`}
    value={value}
    displayType="text"
  />
)

export default BalanceCell
