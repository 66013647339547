import HorizontalChartItem from './HorizontalChartItem'

const HorizontalChart = props => {
  // The background colors that can be used, in the correct order, by each chart item.
  const bgColors = [
    'bg-blue-800',
    'bg-blue-700',
    'bg-blue-600',
    'bg-blue-500',
    'bg-blue-400',
    'bg-blue-300',
  ]

  // The text colors that can be used, in the correct order, by each chart item.
  const textColors = [
    'text-blue-800',
    'text-blue-700',
    'text-blue-600',
    'text-blue-500',
    'text-blue-400',
    'text-blue-300',
  ]

  return (
    <div className="flex space-x-0.5 mt-8">
      {props.data.map((item, idx) => (
        <HorizontalChartItem
          item={item}
          bgColor={bgColors[idx]}
          textColor={textColors[idx]}
          totalDuration={props.totalDuration}
          key={item.id}
        />
      ))}
    </div>
  )
}

export default HorizontalChart
