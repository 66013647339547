import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Input } from '@aider/ui'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Fyll i ett giltigt kontonamn'),
  accountNumber: Yup.string().required('Fyll i ett giltigt kontonummer'),
})

const AddAccount = ({ onSuccess, onCancel }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    isValid,
  } = useFormik({
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      name: '',
      accountNumber: '',
    },
    onSubmit: submittedValues => {
      if (typeof onSuccess === 'function') {
        onSuccess(submittedValues)
      }
    },
  })

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-1 flex-col justify-between p-6 bg-white rounded-lg overflow-hidden"
      style={{ width: 480 }}
    >
      <div>
        <header className="mb-6 text-black text-lg font-medium">
          Nytt konto
        </header>
        <div className="mb-4">
          <Input
            id="name"
            name="name"
            type="text"
            label="Namn"
            placeholder=""
            value={values.name}
            error={touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="mb-4">
          <Input
            id="accountNumber"
            name="accountNumber"
            type="text"
            label="Kontonummer"
            placeholder=""
            value={values.accountNumber}
            error={touched.accountNumber && errors.accountNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <Button
          type="button"
          title="Avbryt"
          variant="tertiary"
          onClick={() => typeof onCancel === 'function' && onCancel()}
        />
        <Button
          type="submit"
          title="Lägg till konto"
          variant="primary"
          disabled={!isValid || isSubmitting}
          isLoading={isSubmitting}
        />
      </div>
    </form>
  )
}

AddAccount.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default AddAccount
