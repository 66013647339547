import PropTypes from 'prop-types'
import { Icon } from '@aider/ui'

const Attachment = ({ fileType, fileName }) => {
  const iconName = fileType === 'pdf' ? 'pdf' : 'image'

  return (
    <div className="flex items-center space-x-2">
      <Icon name={iconName} className="w-5 h-5" />
      <div className="max-w-sm text-black font-medium truncate">{fileName}</div>
      <div className="text-gray-700 text-sm uppercase">{fileType}</div>
    </div>
  )
}

Attachment.defaultProps = {
  fileName: 'Filnamn saknas',
}

Attachment.propTypes = {
  fileType: PropTypes.string.isRequired,
  fileName: PropTypes.string,
}

export default Attachment
