import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { Button, Datepicker, DatepickerInput } from '@aider/ui'
import { format } from 'date-fns'

import { activePrincipalIdVar } from '@/cache'

const validationSchema = Yup.object().shape({
  // Make this nullable since it's not possible to select a endDate without a startDate
  startDate: Yup.date()
    .required()
    .nullable(),
  endDate: Yup.date()
    .required()
    .typeError('Välj ett giltigt datumspann'),
})

const CREATE_IMPORT = gql`
  mutation createImport(
    $principalId: ID!
    $importMethod: ImportMethod!
    $from: Date!
    $to: Date!
  ) {
    createImport(
      input: {
        principalId: $principalId
        importMethod: $importMethod
        from: $from
        to: $to
      }
    ) {
      id
      status
      importMethod
    }
  }
`

const CreateImport = ({ onSuccess, onCancel, onError }) => {
  const principalId = useReactiveVar(activePrincipalIdVar)

  const [createImport] = useMutation(CREATE_IMPORT, {
    onCompleted: useCallback(
      ({ createImport: { id: importId } = {} }) => {
        if (typeof onSuccess === 'function') {
          onSuccess(importId)
        }
      },
      [onSuccess],
    ),
    onError: useCallback(
      error => {
        onError(error)
      },
      [onError],
    ),
  })

  const {
    values,
    errors,
    handleSubmit,
    handleBlur,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useFormik({
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: { startDate: null, endDate: null },
    onSubmit: ({ startDate, endDate }) =>
      createImport({
        variables: {
          principalId,
          importMethod: 'TINK',
          from: format(startDate, 'yyyy-MM-dd'),
          to: format(endDate, 'yyyy-MM-dd'),
        },
      }),
  })

  const handleDateChange = dates => {
    const [startDate, endDate] = dates
    // Don't validate on changes to startDate
    setFieldValue('startDate', startDate, false)
    setFieldValue('endDate', endDate)
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-1 flex-col justify-between p-6"
    >
      <div>
        <header className="mb-6 text-black text-lg font-medium">
          Välj period
        </header>
        <div className="mb-4">
          <Datepicker
            id="date"
            name="date"
            onChange={handleDateChange}
            startDate={values.startDate}
            endDate={values.endDate}
            selected={values.startDate}
            shouldCloseOnSelect={false}
            selectsRange
            customInput={
              <DatepickerInput
                id="date-input"
                name="date-input"
                selectsRange
                label="Datum"
                onBlur={handleBlur}
                startDate={values.startDate}
                endDate={values.endDate}
                error={errors.endDate}
              />
            }
          />
        </div>
        <div className="p-3 border border-gray-300 rounded-lg">
          <span className="block mb-1 text-black font-medium">Information</span>
          <span className="text-gray-700">
            Inhämtning av transaktioner via Tink är begränsade till max ett år
            bakåt i tiden.
          </span>
        </div>
      </div>
      <div className="flex justify-between">
        <Button
          type="button"
          title="Avbryt"
          variant="tertiary"
          onClick={onCancel}
        />
        <Button
          type="submit"
          title="Nästa"
          variant="primary"
          disabled={!isValid || isSubmitting}
          isLoading={isSubmitting}
        />
      </div>
    </form>
  )
}

CreateImport.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default CreateImport
