import { formatMinutes } from '@utils/date'

const TimeJournalLineDetail = props => (
  <div className="flex justify-between pt-2 pl-6 font-medium text-gray-600 print:text-xs print:pl-0">
    <span className='flex-1 overview-line-detail' dangerouslySetInnerHTML={{__html: props.data.description}}/>
    <div className="flex items-baseline flex-shrink-0 ml-4 space-x-6">
      <span>{props.data.date}</span>
      <span>{formatMinutes(props.data.duration)}</span>
    </div>
  </div>
)

export default TimeJournalLineDetail
