import PropTypes from 'prop-types'
import { IconButton, Icon, Dropdown } from '@aider/ui'

import { format } from '@utils/date'

const JournalEntryCard = ({
  description,
  category,
  minutes,
  kilometers,
  date,
  onDelete,
  onEdit,
}) => (
  <div className="flex flex-col justify-between p-4 w-full bg-white border border-gray-300 rounded-lg">
    <span className="break-words text-lg font-medium">{description}</span>
    <div className="flex items-center mt-4 pt-4 border-t border-gray-300">
      <div
        className="flex flex-col items-center justify-center py-2 w-full bg-blue-500 rounded-md"
        style={{ flex: '0 0 35px' }}
      >
        <span className="flex mb-1 text-white text-xs leading-none opacity-75">
          {format(date, 'MMM')}
        </span>
        <span className="text-white text-sm font-medium leading-none">
          {format(date, 'dd')}
        </span>
      </div>
      <div className="mx-2 w-full overflow-hidden">
        <div className="text-sm font-medium truncate">{category}</div>
        <div className="text-gray-800 text-sm font-medium truncate">
          {minutes} {kilometers && `• ${kilometers}km`}
        </div>
      </div>
      <Dropdown.Root>
        <Dropdown.Trigger
          as={IconButton}
          icon={<Icon name="options" className="w-4 h-4" />}
        />
        <Dropdown.Content>
          <Dropdown.Item onSelect={onEdit}>Redigera</Dropdown.Item>
          <Dropdown.Item onSelect={onDelete}>Ta bort</Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Root>
    </div>
  </div>
)

JournalEntryCard.defaultProps = {
  onDelete: null,
  onEdit: null,
}

JournalEntryCard.propTypes = {
  description: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  minutes: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

export default JournalEntryCard
