import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Checkbox } from '@aider/ui'

import useVerificationAccounts, {
  ACCOUNT_TYPES,
} from '@hooks/useVerificationAccounts'

const validationSchema = Yup.object().shape({
  verificationAccounts: Yup.array()
    .test(
      'Check if atleast one checkbox is checked',
      'Välj en kategori i listan',
      (_, { parent }) =>
        parent && Object.values(parent).filter(item => item).length >= 1,
    )
    .of(
      Yup.object()
        .shape({
          value: Yup.boolean().required('Välj en kategori i listan'),
        })
        .required()
        .typeError('Välj en kategori i listan'),
    ),
})

const VerificationAccountFilter = ({ onFilterSubmit, initialValues }) => {
  const { isValid, values, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues
      ? initialValues.reduce((acc, item) => {
          acc[item] = true
          return acc
        }, {})
      : {},
    validationSchema,
    onSubmit: submittedValues => {
      // Only return verificationAccountIds where value is true (checked)
      const keys = Object.keys(submittedValues)
      const verificationAccountIds = keys.reduce((acc, key) => {
        const value = submittedValues[key]
        if (value) acc.push(key)
        return acc
      }, [])

      onFilterSubmit({
        value: verificationAccountIds,
        label: `${verificationAccountIds.length} kategorier valda`,
      })
    },
  })

  const {
    data: { verificationAccounts: incomeAccounts = [] } = {},
    loading: loadingIncomeAccounts,
  } = useVerificationAccounts({
    variables: { accountTypes: ACCOUNT_TYPES.incomes },
  })

  const {
    data: { verificationAccounts: expenseAccounts = [] } = {},
    loading: loadingExpenseAccounts,
  } = useVerificationAccounts({
    variables: { accountTypes: ACCOUNT_TYPES.expenses },
  })

  if (loadingIncomeAccounts || loadingExpenseAccounts) {
    return null
  }

  const renderCheckbox = ({ id, name }) => (
    <div key={id} className="flex items-center space-x-2">
      <div className="flex">
        <Checkbox
          id={id}
          checked={values[id]}
          onCheckedChange={handleChange}
          name={id}
        />
      </div>
      <label className="flex flex-1 text-sm font-medium" htmlFor={id}>
        {name}
      </label>
    </div>
  )

  return (
    <form className="flex flex-col space-y-3 p-3 w-72" onSubmit={handleSubmit}>
      <div className="flex flex-col space-y-3 max-h-72 overflow-y-auto">
        <span className="font-medium">Inkomstkategorier</span>
        {incomeAccounts.map(renderCheckbox)}
        <span className="font-medium">Utgiftskategorier</span>
        {expenseAccounts.map(renderCheckbox)}
      </div>
      <Button disabled={!isValid} type="submit" title="Spara" />
    </form>
  )
}

VerificationAccountFilter.defaultProps = {
  initialValues: undefined,
}

VerificationAccountFilter.propTypes = {
  onFilterSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.arrayOf(PropTypes.string),
}

export default VerificationAccountFilter
