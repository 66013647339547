import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ReactGA from 'react-ga4'
import { ApolloProvider } from '@apollo/client'

import { intercom } from '@utils/intercom'
import App from '@/App'
import { client } from '@/apollo-client'
import '@/index.css'

import 'react-datepicker/dist/react-datepicker.css'

// Init Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 0.2,
})

// Boot Intercom
intercom('boot', {
  app_id: process.env.REACT_APP_INTERCOM_APP_ID,
})

ReactGA.initialize('G-444RHHWGLB')

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
